<script setup>
import { form, formV$, resetForm } from "~/validations/talkToUsFormRules";
import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";

const emit = defineEmits(["close"]);
const closeDialog = () => emit("close");

const { userInfo } = storeToRefs(useAuthStore());

const { sendPostRequest } = useSendRequest();

const apis = new APIS();
const isSubmitted = ref(false);
const loading = ref(false);

async function onSubmitted(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    return;
  }

  loading.value = true;

  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    email: form.email,
    name: form.name,
    message: form.message,
    subject: "Talk to Us",
  };

  const url = `${apis.BASE_URL + apis.MAIL_US}`;

  const response = await sendPostRequest(url, headers, data);

  if (response?.status === 200) {
    notifySuccess(response.data.data?.message);

    resetForm();
    closeDialog();
  } else {
    const message = response.data?.message || "An unknown error occurred.";
    notifyError({
      title: message,
    });
  }

  loading.value = false;
}

onMounted(() => (form.email = userInfo.value?.email));
</script>

<template>
  <Dialog
    header="Talk to Us"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    id="talk-to-us-dialog"
  >
    <div class="grid grid-cols-1 gap-1">
      <h3 class="text-center text-sm mb-10">
        Got questions, feedback, or just want to say hello? We're here to chat and happy
        to help in any way we can.
      </h3>
      <CustomFieldText
        :Field="formV$.name"
        :isSubmitted="isSubmitted"
        :label="'Full Name'"
        :inputId="'name-input-field'"
        :placeholder="'Enter your full name'"
      />
      <CustomFieldText
        :Field="formV$.email"
        :isSubmitted="isSubmitted"
        :label="'Email'"
        :inputId="'email-input-field'"
        :placeholder="'Enter your email'"
      />
      <CustomFieldTextarea
        :Field="formV$.message"
        :isSubmitted="isSubmitted"
        :label="'Message'"
        :inputId="'message-input-field'"
        :placeholder="'We\'d love to hear from you - tell us what\'s on your mind.'"
      />
    </div>
    <template #footer>
      <Button
        class="!bg-primary !border-primary w-full"
        @click="onSubmitted(formV$.$invalid)"
        :label="loading ? 'Sending...' : 'Send Message'"
        :loading="loading"
      />
    </template>
  </Dialog>
</template>

<style lang="postcss">
#talk-to-us-dialog {
  @apply w-[80%] md:!w-[40%] lg:!w-[30%];
}
</style>
