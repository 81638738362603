export default {
  base: () => import("/opt/buildhome/repo/layouts/base.vue").then(m => m.default || m),
  collection: () => import("/opt/buildhome/repo/layouts/collection.vue").then(m => m.default || m),
  custom: () => import("/opt/buildhome/repo/layouts/custom.vue").then(m => m.default || m),
  dashboard: () => import("/opt/buildhome/repo/layouts/dashboard.vue").then(m => m.default || m),
  default: () => import("/opt/buildhome/repo/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/opt/buildhome/repo/layouts/empty.vue").then(m => m.default || m),
  "get-started": () => import("/opt/buildhome/repo/layouts/get-started.vue").then(m => m.default || m),
  overview: () => import("/opt/buildhome/repo/layouts/overview.vue").then(m => m.default || m),
  setting: () => import("/opt/buildhome/repo/layouts/setting.vue").then(m => m.default || m)
}