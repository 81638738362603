<script setup>
defineProps({
  link: {
    type: String,
    required: true,
  },
  linkTitle: {
    type: String,
    default: true,
  },
  linkDescription: {
    type: String,
    default: true,
  },
  linkImage: {
    type: String,
    default: false,
  },
  openInNewTab: {
    type: Boolean,
    default: false,
  },
  imageAltText: {
    type: String,
    default: "thumbnail",
  },
  customClass: {
    type: String,
    default: "",
  },
});
const truncateText = (text, maxLength) => {
  return text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;
};
</script>
<template>
  <NuxtLink :to="link" :key="link" :target="openInNewTab ? '_blank' : '_self'">
    <div class="mt-8 flex justify-center cursor-pointer" :class="customClass">
      <div>
        <NuxtImg
          v-if="linkImage"
          :src="linkImage"
          :alt="imageAltText"
          class="h-52 rounded-xl overflow-hidden mx-auto"
        />
        <h3 class="font-semibold mt-3">{{ linkTitle }}</h3>
        <p class="text-gray-500 text-sm">{{ truncateText(linkDescription, 200) }}</p>
      </div>
    </div>
  </NuxtLink>
</template>

<style></style>
