<script setup>
import { capitalizeWords } from "~/utils/string.js";
import { form } from "~/validations/linkFormRules";
import moment from "moment";

const close = inject("close-link-form-dialog");
const linkId = inject("link-form-link-id");
const linkType = inject("link-form-link-type");
const isDuplicate = inject("link-form-is-duplicate");
</script>

<template>
  <div class="h-[8%] flex items-center justify-between border-b">
    <div class="flex justify-between items-center w-full">
      <h2 class="font-bold text-xl">
        {{ linkId && !isDuplicate ? "Update" : "Create" }}
        {{ linkType === "smartlnks" ? "SmartLnk" : "SmartDomain" }}
      </h2>
      <span v-if="form.updatedOn" class="text-xs font-normal">Last updated:
        <span class="font-medium">{{ moment(form.updatedOn).fromNow() }}</span></span>
    </div>

    <Button @click="close" icon="pi pi-times" class="p-button-rounded p-button-text !text-black" />
  </div>
</template>

<style></style>
