<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted } = storeToRefs(useLinkFormStore());
</script>
<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- Clicks Limit Field -->
    <CustomFieldNumber
      :Field="formV$.clicksLimit"
      :isSubmitted="isSubmitted"
      :label="'Clicks Limit'"
      :placeholder="'Enter Limit'"
      :inputId="'clicks-limit-input-field'"
    />

    <!-- Clicks Limit Mode Field -->
    <CustomFieldClicksLimitMode
      :clicksLimitMode="formV$.clicksLimitMode"
      :isSubmitted="isSubmitted"
    />
  </div>
</template>

<style></style>
