<script setup>
const props = defineProps({
  pixels: {
    type: Object,
  },
  PixelsForm: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },

  responseError: {
    type: Object,
  },
});
function addPixelField() {
  if (props.PixelsForm?.length <= 4)
    props.PixelsForm.push({
      pixelId: null,
      pixelType: null,
    });
}

function removePixelField() {
  if (props.PixelsForm?.length > 0) {
    props.PixelsForm.pop();
  }
}

const pixelOptions = ref([
  { name: "Facebook", value: "fb" },
  { name: "Google Tag Manager", value: "gtm" },
  { name: "Google Analytics", value: "ga" },
  { name: "Google Ads", value: "gads" },
  { name: "LinkedIn", value: "li" },
  { name: "Twitter", value: "tw" },
  { name: "AdRoll", value: "ar" },
  { name: "Quora", value: "qr" },
  { name: "Pinterest", value: "pin" },
  { name: "Snapchat", value: "sc" },
  { name: "Reddit", value: "rd" },
  { name: "TikTok", value: "tt" },
]);

function removeDuplicateDictionaries(dictionaryArray) {
  // Create an empty array to store the unique dictionaries
  let uniqueDictionaries = [];

  dictionaryArray.forEach((item) => {
    if (uniqueDictionaries[item.pixelType]) {
      removePixelField();
    } else {
      uniqueDictionaries[item.pixelType] = item;
    }
  });
}

function onDropdownValueChange(event) {
  removeDuplicateDictionaries(props.PixelsForm);
}
</script>
<template>
  <div class="field px-1" v-for="(input, index) in PixelsForm" :key="index">
    <label
      for="tracking-code-input-field"
      class="block mb-2 text-sm font-medium"
      :class="{
        'text-red-700 dark:text-red-500':
          pixels.$each.$response.$errors[index].pixelId?.length ||
          pixels.$each.$response.$errors[index].pixelType?.length,
      }"
    >
      Tracking Pixel {{ index + 1 }}
    </label>

    <div class="flex justify-between">
      <span class="p-input-icon-right w-[70%] mr-2">
        <i
          v-if="pixels.$each.$response.$errors[index].pixelId?.length"
          class="pi pi-exclamation-triangle !text-red-500"
        />
        <input
          v-model.trim="input.pixelId"
          type="text"
          :id="'tracking-code-input-field-' + index"
          class="block p-3 bg-gray-50 border sm:text-md rounded-lg dark:bg-gray-700 w-full"
          :class="{
            'border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500':
              pixels.$each.$response.$errors[index].pixelId?.length,
          }"
          placeholder="Enter pixel Id"
          :aria-describedby="'tracking-code-input-field-' + index"
        />
      </span>

      <Dropdown
        :id="'pixel-choices-dropdown-' + index"
        class="p-inputgroup-addon pixel-choices-dropdown !p-0 w-[30%]"
        :class="{
          '!border-red-500  !placeholder-red-700 dark:!placeholder-red-500 dark:!visibleborder-red-500 focus:!border-red-500':
            pixels.$each.$response.$errors[index].pixelType?.length,
        }"
        :inputClass="
          pixels.$each.$response.$errors[index].pixelType?.length
            ? '!text-red-900 dark:!text-red-500 focus:!ring-red-500 focus:!border-red-500'
            : ''
        "
        v-model="input.pixelType"
        :options="pixelOptions"
        optionLabel="name"
        optionValue="value"
        placeholder="Select a Pixel Type"
        @change="onDropdownValueChange($event)"
      />
    </div>
    <div
      v-if="pixels.$each.$response.$errors[index].pixelId?.length"
      class="input-errors"
      v-for="error of pixels.$each.$response.$errors[index].pixelId"
      :key="error.$uid"
    >
      <p id="pixel-id-input-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error.$message }}.
      </p>
    </div>
    <div
      v-else-if="pixels.$each.$response.$errors[index].pixelType?.length"
      class="input-errors"
      v-for="(error, indx) of pixels.$each.$response.$errors[index].pixelType"
      :key="indx"
    >
      <p id="pixel-id-input-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error.$message }}.
      </p>
    </div>
    <br />
  </div>

  <div class="flex">
    <Button
      v-if="PixelsForm?.length <= 4"
      @click="addPixelField()"
      label="Add Pixel"
      icon="pi pi-plus"
      class="p-button-sm !mr-2 !p-2"
    />
    <Button
      v-if="PixelsForm?.length > 0"
      @click="removePixelField()"
      label="Remove pixel"
      icon="pi pi-minus"
      class="p-button-sm !p-2 !bg-white !border-white !text-black"
      id="remove-pixel-btn"
    />
  </div>
  <br />
</template>

<style lang="postcss">
.pixel-choices-dropdown.p-dropdown:not(.p-disabled).p-focus {
  @apply !border-[#d4d4d8] shadow-none;
}
#remove-pixel-btn {
  @apply !bg-white !border-white;
}
</style>
