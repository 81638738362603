import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";

export const collectionApiService = () => {
  if (process.server) return;

  // Initialize your variables and functions here
  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const header = storeToRefs(useAuthStore()).header;

  const {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
  } = useSendRequest();

  const getConfig = (params = {}) => ({
    headers: header.value,
    responseType: "json",
    params: params,
  });

  const fetchCollections = async () => {
    try {
      const url = `${BASE_URL + apis.COLLECTIONS_API}`;
      const response = await sendGetRequest(url, getConfig());
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  const createCollection = async (data) => {
    const url = `${BASE_URL + apis.COLLECTIONS_API}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      console.log("==> error: ", error);
      throw new Error(
        error.response?.data?.message || "Failed to create collection"
      );
    }
  };

  const updateCollection = async (data) => {
    const url = `${BASE_URL + apis.COLLECTIONS_API}`;
    try {
      const response = await sendPatchRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to update collection"
      );
    }
  };

  const transferLinksToCollection = async (data) => {
    try {
      const url = `${apis.BASE_URL + apis.TRANSFER_LINK}`;
      const response = await sendPatchRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  const pinOrUnpinCollection = async (data) => {
    const url = `${BASE_URL + apis.PIN_OR_UNPIN_COLLECTION}`;
    try {
      const response = await sendPatchRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to pin/unpin collection"
      );
    }
  };

  const deleteCollection = async (data) => {
    const url = `${BASE_URL + apis.COLLECTIONS_API}`;
    try {
      const response = await sendDeleteRequest(url, {
        ...getConfig(),
        data: data,
      });
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to delete collection"
      );
    }
  };

  return {
    fetchCollections,
    createCollection,
    updateCollection,
    deleteCollection,
    pinOrUnpinCollection,
    transferLinksToCollection,
  };
};
