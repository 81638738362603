<script setup>
import { storeToRefs } from "pinia";
import { form, formV$ } from "~/validations/collectionFormRules.js";
import { useCollectionFormStore } from "~/store/useCollectionFormStore.js";

const collectionFormStore = useCollectionFormStore();
const { isSubmitted, responseError } = storeToRefs(collectionFormStore);
const { clearResponseError } = collectionFormStore;
</script>
<template>
  <form @submit.prevent="" class="p-fluid">
    <CustomFieldText
      :Field="formV$.name"
      :isSubmitted="isSubmitted"
      :responseError="responseError"
      :label="'Collection Name'"
      :inputId="'collection-name-input-field'"
      :placeholder="'Enter a collection name'"
      :showResponseError="true"
      @clearResponseError="clearResponseError"
    />
    <CustomFieldTextarea
      :Field="formV$.description"
      :isSubmitted="isSubmitted"
      :label="'Description (Optional)'"
      :inputId="'collection-description-input-field'"
      :placeholder="'Enter a brief description on the collection.'"
    />
    <CustomFieldTeam
      v-if="false"
      :isSubmitted="isSubmitted"
      :teamEmails="selectedCollection?.team_emails"
      :action="collectionAction"
      :isPremium="selectedCollection?.is_premium"
    />
  </form>
</template>

<style></style>
