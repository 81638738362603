import { notify } from "notiwind";

export const notifySuccess = (message) => {
  notify(
    {
      group: "top",
      title: message,
      text: "",
      severity: "success",
    },
    5000
  );
};

export const notifyError = ({
  title = "Failed to process your request.",
  text = "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
  timer = 7000,
} = {}) => {
  notify(
    {
      group: "top",
      title: title,
      text: text,
      severity: "error",
    },
    timer
  );
};

export const notifyWarning = ({ title, text = "" } = {}) => {
  notify(
    {
      group: "top",
      title: title,
      text: text,
      severity: "warning",
    },
    7000
  );
};
