<script setup>
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore.js";
import { resetForm } from "~/validations/homeLinkFormRules";

const emit = defineEmits(["close"]);

const { resetShowForAllFields } = useHomeLinkFormStore();

provide("close-custom-link-options-dialog", () => emit("close"));

onBeforeUnmount(() => {
  resetForm();
  resetShowForAllFields();
});
</script>

<template>
  <Dialog
    header="Custom Alias/SmartDomain"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="false"
    :maximizable="true"
    id="custom-link-options-dialog"
    class="remove-scroll"
  >
    <div class="gap-10 select-none">
      <DialogCustomLinkOptionsTabs />
    </div>

    <template #footer>
      <DialogCustomLinkOptionsFooter />
    </template>
  </Dialog>
</template>

<style lang="postcss">
#custom-link-options-dialog {
  -ms-overflow-style: none !important; /* IE and Edge, */
  scrollbar-width: none !important; /* Firefox, */
  @apply w-10/12 md:!w-7/12 lg:!w-5/12;
}
#custom-link-options-dialog::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  -ms-overflow-style: none !important; /* IE and Edge, */
  scrollbar-width: none !important; /* Firefox, */
}
</style>
