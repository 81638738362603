<template>
  <div class="field">
    <label for="collection-name-input-field" class="block mb-2 text-sm font-medium"
      >Team</label
    >
    <div>
      <AvatarGroup>
        <Avatar
          aria-label="You"
          v-tooltip.top="{
            value: 'You',
            class: 'team-tooltip',
          }"
          label="Y"
          shape="circle"
        />
        <template
          v-if="action === 'edit'"
          v-for="(member, index) in teamEmails"
          :key="member"
        >
          <Avatar
            v-if="member != userInfo.user.email"
            :aria-label="member"
            :key="index"
            v-tooltip.top="{
              value: member,
              class: 'team-tooltip',
            }"
            :label="member[0]"
            shape="circle"
          />
        </template>
        <Avatar
          v-if="!isPremium"
          @click="onInviteMoreMember()"
          class="cursor-pointer"
          icon="pi pi-plus"
          shape="circle"
          style="color: #ffffff; background: rgb(37 99 235)"
          v-tooltip.top="{
            value: 'invite more',
            class: 'team-tooltip',
          }"
        />
      </AvatarGroup>
    </div>
    <!-- form error -->
    <div v-if="error" class="team-errors" :key="error">
      <p id="collection-team-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error }}.
      </p>
    </div>
    <br />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

const props = defineProps({
  isSubmitted: {
    type: Boolean,
    default: false,
  },
  teamEmails: {
    type: Array,
    default: [],
  },
  action: {
    type: String,
    required: true,
  },
  isPremium: {
    type: Boolean,
    default: false,
  },
});

const error = ref();

// ============================== Auth Store ==============================
const authStore = useAuthStore();
const { userInfo } = storeToRefs(authStore);

// ============================== On Invite More member click ==============================
const onInviteMoreMember = () => {
  console.log("==> onInviteMoreMember");
};
</script>

<style></style>
