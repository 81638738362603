<script setup>
import {
  DialogLinkFormTabsAddWarningsPermissions,
  DialogLinkFormTabsBasic,
  DialogLinkFormTabsClicksLimit,
  DialogLinkFormTabsCustomMetaData,
  DialogLinkFormTabsDeviceTargets,
  DialogLinkFormTabsBrowserTargets,
  DialogLinkFormTabsGeoTargets,
  LazyDialogLinkFormTabsScheduleLink,
  DialogLinkFormTabsSetPassword,
  DialogLinkFormTabsTrackingPixels,
  DialogLinkFormTabsUtmBuilder,
} from "#components";
import { storeToRefs } from "pinia";
import { useLinkFormPanelMenuStore } from "~/store/useLinkFormPanelMenuStore";

const { activeItem } = storeToRefs(useLinkFormPanelMenuStore());

const componentsMap = {
  basic: DialogLinkFormTabsBasic,
  "schedule-link": LazyDialogLinkFormTabsScheduleLink,
  "clicks-limit": DialogLinkFormTabsClicksLimit,
  "set-password": DialogLinkFormTabsSetPassword,
  "add-warnings-permissions": DialogLinkFormTabsAddWarningsPermissions,
  "device-targets": DialogLinkFormTabsDeviceTargets,
  "browser-targets": DialogLinkFormTabsBrowserTargets,
  "geo-targets": DialogLinkFormTabsGeoTargets,
  "tracking-pixels": DialogLinkFormTabsTrackingPixels,
  "utm-builder": DialogLinkFormTabsUtmBuilder,
  "custom-meta-data": DialogLinkFormTabsCustomMetaData,
};
</script>
<template>
  <div v-auto-animate class="h-[82%] overflow-y-scroll remove-scroll">
    <component :is="componentsMap[activeItem]" />
  </div>
</template>
<style></style>
~/store/useLinkFormPanelMenuStore