<script setup>
import { storeToRefs } from "pinia";
import { form, formV$ } from "~/validations/youtubeAutomationFormRules.js";
import { useYoutubeAutomationFormStore } from "~/store/useYoutubeAutomationFormStore.js";

const store = useYoutubeAutomationFormStore();
const { isSubmitted, responseError } = storeToRefs(store);
const { clearResponseError } = store;
</script>
<template>
  <form @submit.prevent="" class="p-fluid my-10">
    <CustomFieldText
      :Field="formV$.smartlnk"
      :isSubmitted="isSubmitted"
      :responseError="responseError"
      :label="'YouTube Permanent SmartLnk'"
      :inputId="'youtube-permanent-smartlnk-input-field'"
      :placeholder="'Enter YouTube Permanent SmartLnk'"
      :showResponseError="true"
      @clearResponseError="clearResponseError"
    />
  </form>
</template>

<style></style>
