import { load } from "cheerio";
import { storeToRefs } from "pinia";
import { collectionApiService } from "~/services/collectionApiService";
import { useCollectionFormStore } from "~/store/useCollectionFormStore";
import { createCollectionFormRequestBody } from "~/utils/collectionFormUtils";
import { form } from "~/validations/collectionFormRules";

const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  IM_USED: 226,
  BAD_REQUEST: 400,
};

export const useCollectionFormApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const { createCollection, updateCollection, deleteCollection } =
    collectionApiService();

  const closeDialog = inject("close-collection-form-dialog");

  const collectionFormStore = useCollectionFormStore();
  const { responseError, isSubmitted } = storeToRefs(collectionFormStore);
  const { clearResponseError } = collectionFormStore;

  const handleApiResponse = (response) => {
    if (!response) {
      notifyError();
      return;
    }

    const message = response.data.data?.message || "An unknown error occurred.";

    switch (response?.status) {
      case STATUS_CODES.OK:
      case STATUS_CODES.CREATED:
        notifySuccess(message);
        closeDialog({ refresh: true });
        break;
      case STATUS_CODES.NOT_ACCEPTABLE:
      case STATUS_CODES.IM_USED:
        responseError.value = response.data?.message || "An unknown error occurred.";
        break;
      default:
        notifyError({ title: message });
        closeDialog();
        break;
    }
  };

  const submitForm = async (isFormValid, collectionId) => {
    isSubmitted.value = true;

    if (isFormValid) {
      return;
    }

    loading.value = true;
    clearResponseError();

    const requestBody = createCollectionFormRequestBody(form, collectionId);

    try {
      const response = collectionId
        ? await updateCollection(requestBody)
        : await createCollection(requestBody);

      handleApiResponse(response);
    } catch (err) {
      error.value = err;
      notifyError(err);
    } finally {
      loading.value = false;
    }
  };

  return {
    data,
    error,
    loading,
    submitForm,
  };
};
