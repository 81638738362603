<script setup>
const props = defineProps({
  tagsField: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
});
const separatorExp = ref(",");
</script>
<template>
  <div class="field">
    <div>
      <label for="Tags-input-field" class="block mb-2 text-sm font-medium">Tags</label>

      <Chips
        class="w-full"
        id="tags-input-field"
        v-model="tagsField.$model"
        :separator="separatorExp"
        :max="9"
        :allowDuplicate="false"
        placeholder="Enter tags"
      />
    </div>
  </div>
  <br />
</template>

<style scoped></style>
<style>
#tags-autocomplete-field > ul {
  @apply w-full bg-gray-50 border rounded-lg;
}
.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
  @apply shadow-none border-[#d4d4d8];
}
#tags-input-field > ul {
  @apply w-full bg-gray-50 border rounded-lg;
}
.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
  @apply shadow-none border-[#d4d4d8];
}
</style>
