<script setup></script>

<template>
  <Dialog
    header="SmartLnks Cookie Policy"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    :maximizable="true"
    id="cookie-privacy-policy-dialog"
  >
    <div>
      <ContentCookiePrivacyPolicy/>
    </div>
  </Dialog>
</template>

<style lang="postcss">
#cookie-privacy-policy-dialog {
  @apply w-[80%] md:!w-[50%];
}
</style>
