import {
  maxLength,
  required,
  url,
  minLength,
  minValue,
  numeric,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// Common Validators
const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));
const validUrl = helpers.withMessage(
  "The entered value is not a valid URL address",
  url
);

const defaultFormValues = () => ({
  updatedOn: "",

  id: 0,
  domain: "smartlnks.com",
  title: "",
  description: "",
  tags: [],
});

export const form = reactive(defaultFormValues());

export const formRules = {
  domain: {
    required: requiredMsg("Domain is required"),
  },

  title: {
    minLength: minLengthMsg("Title must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Title must be 30 characters or less", 30),
  },
  description: {
    minLength: minLengthMsg(
      "Description must be at least 7 characters long",
      7
    ),
    maxLength: maxLengthMsg("Description must be 500 characters or less", 500),
  },
  tags: {},
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
