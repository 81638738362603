<script setup></script>

<template>
  <div>
    <DialogLinkFormSidebarPanelMenu/>
    <DialogLinkFormSidebarAds />
  </div>
</template>

<style></style>
