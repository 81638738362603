import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "6b48486e-7d87-40f3-89ae-dfe628a1796a";

  (function () {
    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();

  window.$crisp.push(["safe", true]);
});
