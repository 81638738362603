<script setup></script>

<template>
  <Sidebar
    :visible="true"
    @update:visible="$emit('close')"
    header="My SmartLnks/Domains"
    position="bottom"
    class="h-[70vh] bg-gray-100 unauth-history-sidebar"
  >
    <SidebarUnauthHistoryBody />
  </Sidebar>
</template>

<style lang="postcss">
.unauth-history-sidebar .p-sidebar-close {
  @apply !bg-gray-200;
}
</style>
