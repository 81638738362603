<script setup>
import { form, resetForm } from "~/validations/homeLinkFormRules";
import { showCustomLinkOptionsDialog } from "~/utils/dialogActions";

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const onSubmit = () => {
  form.destinationURL = props.url;

  // if (youtubeRegex.test(form.destinationURL)) {
  //   const slYouTubeURL = form.destinationURL
  //     .replace("youtu.be", "slyoutu.be")
  //     .replace("youtube.com", "slyoutube.com");
  //   showLinkCreatedDialog({
  //     link: slYouTubeURL,
  //     didYouKnow:
  //       'You can unlock the ancient secret of opening YouTube links in the app by simply adding "sl" before the URL. So, youtu.be/videoId becomes slyoutu.be/videoId. It\'s almost like rocket science... but not really. 😉',
  //     showMoreOptions: false,
  //   });
  //   resetForm();
  //   return;
  // }

  showCustomLinkOptionsDialog({ destinationURL: props.url });
  emit("close");
};
</script>

<template>
  <Dialog
    header=""
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    position="bottomright"
    :modal="false"
    id="quick-smartenit-clipboard-dialog"
  >
    <div>
      <h3 class="text-base font-semibold">
        👋 Hey, we don't mean to brag, but we're pretty good at detecting URLs. And we're
        even better at smartening them up.
        <br /><br />
        🔍 We've detected the following URL in your clipboard: <br />
        <span class="text-blue-600 break-all">{{ url }}</span>

        <br /><br />

        🚀 Click below to see what we mean.
      </h3>
    </div>
    <template #footer>
      <Button
        @click="onSubmit()"
        id="smarten-it-btn-clipboard-dialog"
        class="!w-full"
        label="Smarten It"
      />
    </template>
  </Dialog>
</template>

<style lang="postcss">
#quick-smartenit-clipboard-dialog {
  @apply w-5/12 md:!w-6/12 lg:!w-4/12 !shadow-2xl rounded-[1.2rem];
}
#quick-smartenit-clipboard-dialog .p-dialog-header {
  @apply justify-end pb-0 rounded-tl-[1.2rem] rounded-tr-[1.2rem];
}

#quick-smartenit-clipboard-dialog .p-dialog-footer {
  @apply !rounded-bl-[1.2rem] !rounded-br-[1.2rem];
}
#quick-smartenit-clipboard-dialog .p-dialog-footer > button:first-child {
  @apply !bg-primary !rounded-full;
}
</style>
