import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

export const linksApiService = () => {
  if (process.server) return;

  const {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
  } = useSendRequest();
  const { header } = storeToRefs(useAuthStore());

  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const ENDPOINTS = {
    SMARTLNKS: apis.GET_COLLECTION_SMARTLNKS,
    SMARTDOMAINS: apis.GET_COLLECTION_SMARTDOMAINS,
    SMARTPIXELS: apis.GET_COLLECTION_PIXELS,
    RESET_CLICKS: apis.RESET_LINK_STATS,
    PIN_OR_UNPIN_LINK: apis.PIN_OR_UNPIN_LINK,
    CRUD: apis.LINKS_CRUD_API,
    CREATE_SMARTLNK: apis.CREATE_SMARTLNK, // for home link form
    CREATE_BULK_LINKS: apis.LINKS_BULK_CREATE,
  };

  const getConfig = (params = {}) => ({
    headers: header.value,
    responseType: "json",
    params: params,
  });

  const fetchLinks = async (linkTypeKey, params = {}) => {
    const url = `${BASE_URL + ENDPOINTS[linkTypeKey] || ENDPOINTS.SMARTLNKS}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  const fetchLinkData = async (params) => {
    const url = `${BASE_URL + ENDPOINTS.CRUD}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  const createLink = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CRUD}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to create link");
    }
  };

  const createBulkLinks = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CREATE_BULK_LINKS}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to create link");
    }
  };

  // for home link form
  const createBasicLink = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CREATE_SMARTLNK}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to create basic link"
      );
    }
  };

  const updateLink = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CRUD}`;
    try {
      const response = await sendPatchRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to update link");
    }
  };

  const pinOrUnpinLink = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.PIN_OR_UNPIN_LINK}`;
    try {
      const response = await sendPatchRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to pin/unpin link"
      );
    }
  };

  const resetLinkClicks = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.RESET_CLICKS}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to reset link clicks"
      );
    }
  };

  const deleteLinks = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CRUD}`;
    try {
      const response = await sendDeleteRequest(url, {
        ...getConfig(),
        data: data,
      });
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to delete link");
    }
  };

  return {
    fetchLinks,
    fetchLinkData,
    pinOrUnpinLink,
    resetLinkClicks,
    createLink,
    createBasicLink,
    createBulkLinks,
    updateLink,
    deleteLinks,
  };
};
