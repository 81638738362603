import { defineStore } from "pinia";

export const useHomeLinkFormStore = defineStore("HomeLinkForm", () => {
  if (process.server) return;

  const responseError = ref(null);
  const isSubmitted = ref(false);
  const isCoolDownOn = ref(true);
  const clearResponseError = () => (responseError.value = null);

  const customFieldButtons = ref([
    { key: "title", label: "Set Title", show: false },
    { key: "schedule-it", label: "Schedule It", show: false },
    { key: "set-password", label: "Set Password", show: false },
  ]);

  const setShowForField = (key, show) => {
    const field = customFieldButtons.value.find((f) => f.key === key);
    if (field) {
      field.show = show;
    }
  };

  const resetShowForAllFields = () => {
    customFieldButtons.value.forEach((field) => {
      field.show = false;
    });
  };

  const reset = () => {
    clearResponseError();
    isSubmitted.value = false;
  };

  return {
    isSubmitted,
    responseError,
    isCoolDownOn,
    customFieldButtons,
    setShowForField,
    resetShowForAllFields,
    reset,
    clearResponseError,
  };
});
