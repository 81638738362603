<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted } = storeToRefs(useLinkFormStore());
</script>

<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- Password Field -->
    <CustomFieldPassword
      :Field="formV$.password"
      :isSubmitted="isSubmitted"
      :label="'Password'"
      :inputId="'password-input-field'"
      :placeholder="'Enter Password'"
    />
  </div>
</template>

<style></style>
