<script setup>
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { extractUrlObjects } from "~/utils/urls.js";
import { useBulkLinkCreationFormStore } from "~/store/useBulkLinkCreationFormStore.js";

const { updateImportedUrls } = useBulkLinkCreationFormStore();

const form = reactive({
  text: "",
});

const formRules = {
  text: {
    required: helpers.withMessage("Please paste imported URLs", required),
    // maxLength: helpers.withMessage(
    //   "Input must be 500 characters or less",
    //   maxLength(200)
    // ),
  },
};

const formV$ = useVuelidate(formRules, form);

watch(form, () => {
  if (!formV$.value.$invalid) {
    updateImportedUrls(extractUrlObjects(form.text));
  }
});
</script>

<template>
  <div class="my-6">
    <Textarea
      v-model.trim="formV$.text.$model"
      :autoResize="false"
      rows="10"
      class="block w-full p-3 !bg-gray-50 !border sm:!text-md !rounded-lg dark:!bg-gray-700 focus:border-[#d4d4d8]"
      :class="{
        '!border-red-500 !text-red-900 !placeholder-red-700 focus:!ring-red-500 focus:!border-red-500 dark:!text-red-500 dark:!placeholder-red-500 dark:!border-red-500':
          formV$.text.$invalid,
      }"
      id="import-links-form-textarea"
      placeholder="Enter or Paste your links here (one per line). We’re ready to turn them into SmartLnks superstars! 🌟 and We promise not to peek. 😉"
      aria-describedby="import-links-form-textarea"
      cols="30"
    />
  </div>
</template>

<style></style>
