<script setup>
import Cookies from "js-cookie";
import { showCookiePrivacyPolicyDialog } from "~/utils/dialogActions";

const checkCookieConsent = () => {
  return Cookies.get("smartlnks-cookie-consent") || false;
};

const cookieConsent = ref(checkCookieConsent());

const handleCookieConsent = () => {
  Cookies.set("smartlnks-cookie-consent", 1, { expires: 7 });
  cookieConsent.value = checkCookieConsent();
};
</script>

<template>
  <transition name="fade">
    <section
      v-if="!cookieConsent"
      class="fixed max-w-md p-4 md:mx-auto mx-4 bg-white border border-gray-200 dark:bg-gray-800 md:left-4 bottom-4 dark:border-gray-700 rounded-2xl z-50"
    >
      <h2 class="font-semibold text-gray-800 dark:text-white">🍪 Cookie Notice</h2>

      <p class="mt-4 text-sm text-gray-600 dark:text-gray-300">
        Welcome to Smartlnks! Like every superhero tool, we use cookies to enhance your
        experience. They make our site smarter for your link needs. Just click 'Got it' to
        continue your seamless journey with us. Want the details? Check out our Privacy
        Policy. No capes required, just cookies! 🍪
        <button
          @click="showCookiePrivacyPolicyDialog()"
          class="text-primary font-semibold hover:underline"
        >
          Read cookies policies</button
        >.
      </p>

      <div class="flex items-center justify-end mt-4 gap-x-4 shrink-0">
        <!-- <button
          class="text-xs text-gray-800 underline transition-colors duration-300 dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          Manage your preferences
        </button> -->

        <Button
          class="text-xs !bg-gray-900 !border-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white !px-8 !py-2.5 duration-300 transition-colors focus:outline-none"
          @click="handleCookieConsent()"
          label="Go it"
        />
      </div>
    </section>
  </transition>
</template>

<style></style>
