<template>
  <div>
    <transition name="fade">
      <SidebarUnauthHistory
        v-if="sidebars.unauthHistory.show"
        @close="handleSidebarClose('unauthHistory')"
      />
    </transition>
    <transition name="fade">
      <LazyCollectionLinkAnalyticsSidebar
        v-if="sidebars.analyticsSidebar.show"
        :linkId="sidebars.analyticsSidebar.linkId"
        @close="handleSidebarClose('analyticsSidebar')"
      />
    </transition>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSidebarsStore } from "~/store/useSidebarsStore";

const store = useSidebarsStore();
const { sidebars } = storeToRefs(store);
const { handleSidebarClose } = store;
</script>

<style>
/* CSS for the fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
