<script setup>
import { useLinkForm } from "~/composables/link-form/useLinkForm";
import { form, resetForm } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

const props = defineProps({
  collectionId: {
    type: Number,
    required: true,
  },
  linkId: {
    type: Number,
    required: false,
  },
  scheduledData: {
    type: String,
    required: false,
  },
  linkType: {
    type: String,
    required: true,
  },
  isDuplicate: {
    type: Boolean,
    required: false,
  }
});

const { collectionId, linkId, scheduledData, linkType, isDuplicate } = props;

const emit = defineEmits(["close"]);

// Use store
const { reset } = useLinkFormStore();

// Use link form composable
const { loading: utilsLoading, error } = useLinkForm(props);

// Provide values to child components
const closeDialog = (payload = {}) => emit("close", payload);

provide("close-link-form-dialog", closeDialog);
provide("link-form-link-id", linkId);
provide("link-form-link-type", linkType);
provide("link-form-is-duplicate", isDuplicate);
provide("collection-id", collectionId); // doing it so that i can open this from any page like calendar

onUnmounted(async () => resetForm(), reset());

onMounted(() => {
  if (scheduledData) form.dateTimeRange = [scheduledData.start, scheduledData.end];
});
</script>

<template>
  <Dialog
    :visible="true"
    :showHeader="false"
    :draggable="false"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    @update:visible="$emit('close')"
    id="link-form-dialog"
    class="select-none"
  >
    <!-- loader -->
    <div v-if="utilsLoading" class="loading-overlay rounded-xl">
      <LoadersRingLoader />
    </div>
    <div class="grid-layout grid-auto-rows">
      <div class="sidebar-content">
        <DialogLinkFormSidebar />
      </div>
      <div class="main-content remove-scroll">
        <DialogLinkFormMainContent />
      </div>
    </div>
  </Dialog>
</template>

<style lang="postcss">
#link-form-dialog {
  @apply w-9/12 md:w-9/12 lg:w-7/12;
}

#link-form-dialog .p-dialog-content {
  @apply rounded-xl p-0 border overflow-hidden;
}

#link-form-dialog .grid-layout {
  display: grid;
  grid-template-columns: 2fr 4fr;
  height: 100%;
  overflow: hidden;
}

#link-form-dialog .sidebar-content {
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-right: 1px solid #f9f9f9;
}

#link-form-dialog .main-content {
  padding: 1.25rem;
  overflow-y: scroll;
}
</style>
