export class APIS {
  ROOT_URL: string = "https://smartlnks.com/";
  BASE_URL: string = "https://api.smartlnks.co/v1/";
  LOCAL_HOST_URL: string = "http://127.0.0.1:8000/v1/";
  // BASE_URL: string = "http://127.0.0.1:8000/"

  // Auth
  // AUTH_API: string = "account/create/"
  CREATE_ACC_API: string = "accounts/registration/";
  LOGIN_ACC_API: string = "account/login/";
  GOOGLE_LOGIN_API: string = "google-login/";
  SIGNOUT_ACC_API: string = "accounts/signout/";
  PASSWORD_RESET_API: string = "account/password_reset/";
  PASSWORD_RESET_CHANGE_PASSWORD_API: string = "account/password_reset/confirm/";
  RESEND_VERIFICATION_EMAIL_API: string = "accounts/registration/resend-email/";

  CONNECTED_APPS: string = "connected-apps/";

  // connected apps
  TWITTER_REQUEST_TOKEN: string = "twitter/request-token";
  TWITTER_EXCHANGE_TOKEN: string = "twitter/exchange-token";

  // automation
  YOUTUBE_AUTOMATION: string = "youtube-automation/";

  // Accounts
  USER_PROFILE_API: string = "account/";

  CHANGE_PASSWORD: string = "account/change-password/";

  NOTIFICATION_API: string = "account/notifications/";

  // home
  CREATE_SMARTLNK: string = "link/create/";

  // analytics apis
  BASIC_ANALYTICS_DATA: string = "analytics/basic/";
  CLICKS_ANALYTICS_DATA: string = "analytics/clicks/";
  LOCATIONS_ANALYTICS_DATA: string = "analytics/locations/";
  TRAFFIC_SOURCES_ANALYTICS_DATA: string = "analytics/traffic-sources/";

  // unauth analytics data
  UNAUTH_BASIC_ANALYTICS_DATA: string = "analytics/unauth-basic/";
  UNAUTH_CLICKS_ANALYTICS_DATA: string = "analytics/unauth-clicks/";
  UNAUTH_LOCATIONS_ANALYTICS_DATA: string = "analytics/unauth-locations/";
  UNAUTH_TRAFFIC_SOURCES_ANALYTICS_DATA: string = "analytics/unauth-traffic-sources/";

  // collections crud apis
  COLLECTIONS_API: string = "collections/";

  // pin collection
  PIN_OR_UNPIN_COLLECTION: string = "collections/pin-toggle/";

  // TODO: work on shared links later
  // collections shared links
  COLLECTIONS_SHARED_LINKS: string = "collection/shared-links/";

  // collections shared settings
  COLLECTIONS_SHARED_SETTINGS: string = "collection/shared-settings/";

  // update share collection list
  UPDATE_SHARE_COLLECTION_LIST: string = "collection/update-share-collection/";

  // update collections shared settings
  COLLECTIONS_SHARED_SETTINGS_UPDATE: string = "collection/update-shared-settings/";

  // update collections shared url
  COLLECTIONS_SHARED_URL_UPDATE: string = "collection/update-share-collection-url/";

  // get collection share data
  GET_COLLECTION_SHARE_DATA: string = "collection/share-data/";

  // collections - smart lnks/domains apis
  GET_COLLECTION_SMARTLNKS = "collection/smartlnks/";

  GET_COLLECTION_SMARTDOMAINS = "collection/smartdomains/";

  GET_COLLECTION_PIXELS = "collection/pixels/";

  GET_ALL_LINKS = "get-all-links/";

  TRANSFER_LINK = "link/transfer/";

  SEARCH_LINK = "link/search/";

  PIN_OR_UNPIN_LINK: string = "link/pin-toggle/";

  // Smartlnks CRUD APIS
  LINKS_CRUD_API: string = "links/crud/";

  LINKS_BULK_CREATE: string = "links/bulk-create/";

  // Craete Pixel API
  CREATE_PIXEL_API: string = "smartpixel/create/";

  // Helper APIS
  USER_COLLECTION_PREFIXES: string = "collections/prefixes/";

  USER_COLLECTION_TAGS: string = "collections/tags/";

  USER_COLLECTION_TEAMS: string = "collections/user-teams/";

  USER_VERIFIED_DOMAINS: string = "user/domains/";

  // check availability

  CHECK_SMARTILNK_AVAILABILITY = "smartlink/availability/";

  CHECK_SMARTDOMAIN_AVAILABILITY = "smartdomain/availability/";

  // Upload QR Code
  UPLOAD_QR_LOGO = "upload/qr-logo/";

  // get URL Meta Data
  GET_URL_META_DATA = "URLMetaData/";

  // get YouTube Channel Detail
  GET_YT_CHANNEL_DETAILS = "get-yt-channel-details/";

  // Get scheduled lnks
  SCHEDULED_LINKS = "links/scheduled/";

  // Get Activity Logs
  ACTIVITY_LOGS = "activity-logs/";

  // Mail Us
  MAIL_US: string = "user/feedback-email/";

  // Preview Smartlnk
  PREVIEW_SMARTLNK: string = "link/preview/";

  // Scan Smartlnk
  SCAN_SMARTLNK: string = "link/scan/";

  // Public Smartlnk Insights
  PUBLIC_LINK_INSIGHTS: string = "link/public-insights/";

  // Fetch Blogs
  BLOGS: string = "blogs/";

  // Reset Link Stats
  RESET_LINK_STATS: string = "link/reset-stats/";

  // Custom Domain
  USER_DOMAINS: string = "user/all-domains/";

  DELETE_CUSTOM_DOMAIN: string = "domain/delete/";

  REMOVE_DOMAIN_AS_DEFAULT: string = "domain/unset-default/";

  SET_DOMAIN_AS_DEFAULT: string = "domain/set-default/";

  ADD_DOMAIN: string = "domains/add/";

  VERIFY_DOMAIN: string = "domains/verify-txt/";

  VERIFY_DNS: string = "domains/verify-dns/";

  OBTAIN_SSL_CERTIFICATE: string = "domains/obtain-ssl/";

  UPDATE_CUSTOM_DOMAIN_PAGE_URL: string = "domains/update-page-url/";

  // Smart YT Link
  GET_RECOMMENDED_SMART_YT_LINKS: string = "get-smart-yt-links/";
  SEARCH_SMART_YT_LINK: string = "search-smart-yt-link/";
  REQUEST_SMART_YT_LINK: string = "request-smart-yt-link/";
  CLAIM_SMART_YT_LINK: string = "claim-smart-yt-link/";
}
