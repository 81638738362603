<script setup>
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

defineProps({
  item: {
    required: true,
    type: Object,
  },
  activeItem: {
    required: true,
    type: String,
  },
});

const { responseError } = storeToRefs(useLinkFormStore());
</script>

<template>
  <button
    class="flex items-center !border-none h-12 !p-[1.7rem] !mt-3 cursor-pointer !w-full px-10 !rounded-lg !justify-between !font-medium !pl-6"
    :class="{
      '!bg-white shadow ': activeItem === item.key,
    }"
  >
    <div class="flex items-center">
      <span
        :class="{
          '!text-black font-bold': activeItem == item.key,
        }"
        class="select-none text-gray-500 text-left"
        >{{ item.label }}</span
      >
    </div>
    <Badge v-if="responseError?.details?.tab_key === item.key" value="1" severity="danger"></Badge>
  </button>
</template>

<style></style>
