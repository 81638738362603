import { storeToRefs } from "pinia";
import { useDialogsStore } from "~/store/useDialogsStore";

let dialogs;

const ensureDialogsInitialized = () => {
  if (!dialogs) {
    dialogs = storeToRefs(useDialogsStore()).dialogs;
  }
};

export const showLinkPreviewDialog = ({ link = null } = {}) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.linkPreview, { link, show: true });
};

export const showScanLinkDialog = () => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.scanLink, { show: true });
};

export const showCustomQRDialog = ({ link }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.customQrCode, { link, show: true });
};

export const showShareDialog = ({ link }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.shareLink, { link, show: true });
};

export const showMoveLinksDialog = ({ selectedLinks, callback }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.moveLinks, {
    show: true,
    selectedLinks,
    callback,
  });
};

export const showPrivacyPolicyDialog = () => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.privacyPolicy, { show: true });
};

export const showCookiePrivacyPolicyDialog = () => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.cookiePrivacyPolicy, { show: true });
};

export const showTermsOfServiceDialog = () => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.termsOfService, { show: true });
};

export const showLinkFormDialog = ({
  linkType,
  collectionId,
  linkId = null,
  scheduledData = null,
  isDuplicate = false,
  callback,
}) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.linkForm, {
    show: true,
    linkType,
    collectionId,
    linkId,
    scheduledData,
    isDuplicate,
    callback,
  });
};

export const showLinkCreatedDialog = ({
  link,
  dashboardLink,
  collectionId,
  didYouKnow,
  showMoreOptions,
  showConfetti,
}) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.linkCreated, {
    show: true,
    link,
    dashboardLink,
    collectionId,
    didYouKnow,
    showMoreOptions,
    showConfetti,
  });
};

export const showCollectionFormDialog = ({ selectedCollection, callback }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.collectionForm, {
    show: true,
    selectedCollection,
    callback,
  });
};

export const showPixelFormDialog = ({ callback }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.pixelForm, {
    show: true,
    callback,
  });
};

export const showCalendarDialog = ({ selectedPeriod, setSelectedPeriod, callback }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.calendar, {
    show: true,
    selectedPeriod,
    setSelectedPeriod,
    callback,
  });
};

export const showResetPasswordDialog = ({ email = null, callback = null } = {}) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.resetPassword, {
    show: true,
    email,
    callback,
  });
};

export const showTalkToUsDialog = () => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.talkToUs, {
    show: true,
  });
};

export const showCustomLinkOptionsDialog = ({ destinationUrl, callback = null }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.customLinkOptions, {
    show: true,
    destinationUrl,
    callback,
  });
};

export const showClipboardDialog = ({ url }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.clipboard, {
    show: true,
    url,
  });
};

export const showDomainFormDialog = ({ action, data, callback }) => {
  ensureDialogsInitialized();

  Object.assign(dialogs.value.domainForm, {
    show: true,
    action,
    data,
    callback,
  });
};

export const showBulkLinkCreationFormDialog = ({ collectionId, callback }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.bulkLinkCreationForm, {
    show: true,
    collectionId,
    callback,
  });
};

export const showYoutubeAutomationFormDialog = ({ callback }) => {
  ensureDialogsInitialized();
  Object.assign(dialogs.value.youtubeAutomationForm, {
    show: true,
    callback,
  });
};
