<template>
  <div>
    <transition name="fade">
      <DialogLinkPreview
        v-if="dialogs.linkPreview.show"
        :providedUrl="dialogs.linkPreview.link"
        @close="handleDialogClose('linkPreview')"
      />
    </transition>
    <transition name="fade">
      <DialogScanLink
        v-if="dialogs.scanLink.show"
        @close="handleDialogClose('scanLink')"
      />
    </transition>
    <transition name="fade">
      <DialogCustomQrCode
        v-if="dialogs.customQrCode.show"
        :providedUrl="dialogs.customQrCode.link"
        @close="handleDialogClose('customQrCode')"
      />
    </transition>
    <transition name="fade">
      <DialogMoveLink
        v-if="dialogs.moveLinks.show"
        :selectedLinks="dialogs.moveLinks.selectedLinks"
        @close="(payload) => handleDialogClose('moveLinks', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogShareLink
        v-if="dialogs.shareLink.show"
        :link="dialogs.shareLink.link"
        @close="handleDialogClose('shareLink')"
      />
    </transition>
    <transition name="fade">
      <DialogPrivacyPolicy
        v-if="dialogs.privacyPolicy.show"
        @close="handleDialogClose('privacyPolicy')"
      />
    </transition>
    <transition name="fade">
      <DialogCookiePrivacyPolicy
        v-if="dialogs.cookiePrivacyPolicy.show"
        @close="handleDialogClose('cookiePrivacyPolicy')"
      />
    </transition>
    <transition name="fade">
      <DialogTermsOfService
        v-if="dialogs.termsOfService.show"
        @close="handleDialogClose('termsOfService')"
      />
    </transition>
    <transition name="fade">
      <DialogLinkForm
        v-if="dialogs.linkForm.show"
        :linkType="dialogs.linkForm.linkType"
        :collectionId="dialogs.linkForm.collectionId"
        :linkId="dialogs.linkForm.linkId"
        :scheduledData="dialogs.linkForm.scheduledData"
        :isDuplicate="dialogs.linkForm.isDuplicate"
        @close="(payload) => handleDialogClose('linkForm', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogLinkCreated
        v-if="dialogs.linkCreated.show"
        :link="dialogs.linkCreated.link"
        :dashboardLink="dialogs.linkCreated.dashboardLink"
        :collectionId="dialogs.linkCreated.collectionId"
        :didYouKnow="dialogs.linkCreated.didYouKnow"
        :showMoreOptions="dialogs.linkCreated.showMoreOptions"
        :showConfetti="dialogs.linkCreated.showConfetti"
        @close="handleDialogClose('linkCreated')"
      />
    </transition>
    <transition name="fade">
      <DialogPixelForm
        v-if="dialogs.pixelForm.show"
        @close="(payload) => handleDialogClose('pixelForm', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogCollectionForm
        v-if="dialogs.collectionForm.show"
        :selectedCollection="dialogs.collectionForm.selectedCollection"
        @close="(payload) => handleDialogClose('collectionForm', payload)"
      />
    </transition>
    <transition name="fade">
      <LazyDialogCalendar
        v-if="dialogs.calendar.show"
        :selectedPeriod="dialogs.calendar.selectedPeriod"
        :setSelectedPeriod="dialogs.calendar.setSelectedPeriod"
        @close="handleDialogClose('calendar')"
      />
    </transition>
    <transition name="fade">
      <DialogResetPassword
        v-if="dialogs.resetPassword.show"
        :email="dialogs.resetPassword.email"
        @close="(payload) => handleDialogClose('resetPassword', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogTalkToUs
        v-if="dialogs.talkToUs.show"
        @close="handleDialogClose('talkToUs')"
      />
    </transition>
    <transition name="fade">
      <DialogCustomLinkOptions
        v-if="dialogs.customLinkOptions.show"
        :destinationUrl="dialogs.customLinkOptions.destinationUrl"
        @close="(payload) => handleDialogClose('customLinkOptions', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogClipboard
        v-if="dialogs.clipboard.show"
        :url="dialogs.clipboard.url"
        @close="handleDialogClose('clipboard')"
      />
    </transition>
    <transition name="fade">
      <LazyDialogDomainForm
        v-if="dialogs.domainForm.show"
        :action="dialogs.domainForm.action"
        :data="dialogs.domainForm.data"
        @close="(payload) => handleDialogClose('domainForm', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogBulkLinkCreationForm
        v-if="dialogs.bulkLinkCreationForm.show"
        :collectionId="dialogs.bulkLinkCreationForm.collectionId"
        @close="(payload) => handleDialogClose('bulkLinkCreationForm', payload)"
      />
    </transition>
    <transition name="fade">
      <DialogYoutubeAutomationForm
        v-if="dialogs.youtubeAutomationForm.show"
        @close="(payload) => handleDialogClose('youtubeAutomationForm', payload)"
      />
    </transition>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useDialogsStore } from "~/store/useDialogsStore";

const store = useDialogsStore();
const { dialogs } = storeToRefs(store);
const { handleDialogClose } = store;
</script>

<style>
/* CSS for the fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
