export const uploadQRLogo = async (imageURL, imageName) => {
  console.log("==> upload QRLogo");
  const apis = new APIS();
  const { sendPostRequest } = useSendRequest();

  if (!imageURL) {
    throw new Error("Image URL is required");
  }

  const url = `${apis.BASE_URL + apis.UPLOAD_QR_LOGO}`;
  let data = new FormData();
  data.append("url", imageURL);
  data.append("name", imageName);

  const config = {};

  try {
    const response = await sendPostRequest(url, config, data);
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error.response?.data?.message || "API request failed");
  }
};
