import { defineStore } from "pinia";
import { ref } from "vue";

export const useCollectionStore = defineStore("CollectionStore", () => {
  if (process.server) return;

  const collectionList = ref(null);
  const selectedCollection = ref();
  const isSidebarExpanded = ref(true);
  const showOverviewBasicTiles = ref(true);
  const activeOverviewTab = ref("clicks");

  const updateCollectionList = (data) => {
    collectionList.value = data;
  };

  const saveSelectedCollection = (collection) => {
    if (selectedCollection.value?.id !== collection?.id)
      selectedCollection.value = collection;
  };

  const toggleSidebar = () => {
    isSidebarExpanded.value = !isSidebarExpanded.value;
  };

  const toggleOverviewBasicTiles = () => {
    showOverviewBasicTiles.value = !showOverviewBasicTiles.value;
  };

  const updateOverviewActiveTab = (tab) => {
    activeOverviewTab.value = tab;
  };

  return {
    collectionList,
    updateCollectionList,
    selectedCollection,
    saveSelectedCollection,
    toggleSidebar,
    isSidebarExpanded,
    toggleOverviewBasicTiles,
    showOverviewBasicTiles,
    updateOverviewActiveTab,
    activeOverviewTab,
  };
});
