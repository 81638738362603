import { notifySuccess, notifyError } from "~/utils/notifications";
import { useClipboard } from "@vueuse/core";

export const copyTextToClipboard = async ({ url }) => {
  try {
    const { text, copy, copied, isSupported } = useClipboard();

    await copy(url);

    if (copied.value) notifySuccess("Copied to clipboard");
    // 3s
    else
      notifyError({
        title: "Failed to copy to clipboard!",
        text: "",
      });
  } catch (e) {
    notifyError({
      title: "Failed to copy to clipboard!",
      text: "",
    });
  }
};
