<script setup>
import { notifyError, notifySuccess } from "~/utils/notifications";
import { storeToRefs } from "pinia";
import { useConfirm } from "primevue/useconfirm";
import { collectionApiService } from "~/services/collectionApiService";
import { useCollectionStore } from "~/store/useCollectionStore.js";

const { selectedLinks } = defineProps({
  selectedLinks: {
    type: Array,
  },
});

const emit = defineEmits(["close"]);

const useCollection = useCollectionStore();
const { selectedCollection: selectedColl } = storeToRefs(useCollection);

const apiService = collectionApiService();
const collectionList = ref();
const selectedCollection = ref();
const loading = ref(false);
const error = ref();
const confirm = useConfirm();

const fetchCollections = async () => {
  loading.value = true;
  try {
    const response = await apiService.fetchCollections();
    handleFetchCollectionResponse(response);
  } catch (error) {
    handleResponseError(error);
  } finally {
    loading.value = false;
    error.value = null;
  }
};

const handleFetchCollectionResponse = (response) => {
  if (response?.status === 200) {
    collectionList.value = filterCollections(response.data?.data);
  } else {
    handleResponseError(response);
  }
};

function filterCollections(collections) {
  return collections.filter((collection) => {
    return (
      collection.id !== selectedColl.value.id &&
      collection.is_premium === selectedColl.value.is_premium
    );
  });
}

const onCollectionSelect = () => {
  confirm.require({
    message:
      "Are you sure you want to move " +
      selectedLinks.length +
      ' smartlnks to "' +
      selectedCollection.value.name +
      '"?',
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    acceptIcon: "pi pi-check",
    acceptClass: "!px-5 !py-2.5 !text-sm",
    rejectClass: "!px-5 !py-2.5 !text-sm !text-red-500 !bg-white !border-white",
    rejectIcon: "pi pi-times",
    accept: () => {
      handleConfirmSelection();
      confirm.close();
    },
    reject: () => {
      confirm.close();
    },
    onHide: () => {
      confirm.close();
    },
  });
};

const handleConfirmSelection = async () => {
  try {
    loading.value = true;
    const data = {
      collection_id: selectedCollection.value.id,
      smartlnks_id: selectedLinks.map((link) => link.id),
    };

    const response = await apiService.transferLinksToCollection(data);
    if (response?.status === 200) {
      notifySuccess(response.data?.data?.message);
      closeDialog();
    } else handleResponseError(response);
  } catch (error) {
    console.error("==> error: ", error);
    handleResponseError(error);
  } finally {
    loading.value = false;
    error.value = null;
  }
};

const handleResponseError = (responseError) => {
  error.value = responseError;
  const message = responseError?.data?.data?.message || "An unknown error occurred.";
  closeDialog();
  notifyError({
    title: message,
  });
};

const closeDialog = () => {
  emit("close", { refresh: true });
};

onMounted(fetchCollections);
</script>
<template>
  <Dialog
    header="Select Collection"
    :modal="true"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    id="move-to-collection-dialog"
  >
    <div class="pt-6 relative">
      <div v-if="loading" class="loading-overlay">
        <LoadersRingLoader />
      </div>

      <span class="text-sm font-normal text-gray-500 dark:text-gray-400"
        >Select collection to move your selected links.</span
      >
      <Listbox
        id="collection-list-move-to-collection"
        :metaKeySelection="true"
        selectionMode="single"
        dataKey="pk"
        v-model="selectedCollection"
        :options="collectionList"
        @click="onCollectionSelect()"
        :filter="true"
        optionLabel="name"
        :multiple="false"
        :auto-option-focus="false"
        :select-on-focus="true"
        class="w-[100%] h-96 overflow-y-scroll rounded !border-none mt-2"
        filterPlaceholder="Search Collection"
        empty-filter-message="Collection not found"
        empty-message="No collection created yet!"
      >
        <template #option="slotProps">
          <div class="collection-list">
            <div
              class="flex items-center p-3 py-5 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              :class="{
                '!bg-yellow-500 !text-slate-50': slotProps.option.is_premium,
              }"
            >
              <SvgCrown
                v-if="slotProps.option.is_premium"
                :height="20"
                :width="20"
                :additionalClass="['mr-2 !text-white !font-bold']"
              />
              <span
                class="truncate max-w-[100%] font-semibold flex-1 ml-3 whitespace-nowrap"
                >{{ slotProps.option.name }}</span
              >
              <!-- <Button
								id="collection-actions-id"
								type="button"
								class="p-button-rounded p-button-text"
								icon="pi pi-ellipsis-v"
								@click=""
								aria-haspopup="true"
								aria-controls="collection_action_menu"
							/> -->
              <!-- <Menu
								v-model="selectedCollection"
								id="collection_action_menu"
								ref="collectionActionMenu"
								:model="collectionActions"
								:popup="true"
							/> -->
            </div>
          </div>
        </template>
      </Listbox>

      <div class="mt-4">
        <CollectionsUsefulnessPanel :showText="true" />
      </div>
    </div>
  </Dialog>

  <ConfirmDialog></ConfirmDialog>
</template>

<style scoped>
.p-listbox-list-wrapper {
  overflow: hidden !important;
}
</style>
<style lang="postcss">
#collection-list-move-to-collection {
  @apply h-96;
}
#collection-list-move-to-collection li {
  border-radius: 10px !important;
  padding: 0;
  @apply my-4 hover:shadow dark:hover:bg-gray-500;
}
.p-listbox-list-wrapper {
  overflow: hidden !important;
}
#move-to-collection-dialog {
  @apply w-4/12 md:!w-6/12 lg:!w-4/12;
}
#move-to-collection-dialog > .p-dialog-header {
  border-bottom: 1px solid #e4e7eb;
  padding: 1rem;
}
#move-to-collection-dialog > .p-dialog-header > span {
  font-size: 1.2rem;
  font-weight: 600;
}
#collection-list-move-to-collection.p-listbox.p-focus {
  @apply !border-none !shadow-none;
}
#move-to-collection-dialog > .p-dialog-content {
  @apply overflow-hidden;
}
</style>
