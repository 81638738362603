<script setup>
import { storeToRefs } from "pinia";
import { useBulkLinkCreationFormStore } from "~/store/useBulkLinkCreationFormStore.js";

const store = useBulkLinkCreationFormStore();
const { importedUrlsObj, selectedUrls } = storeToRefs(store);
const { updateSelectedUrls } = store;

watch(
  importedUrlsObj,
  () => {
    updateSelectedUrls(importedUrlsObj.value);
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <p class="font-semibold">Selected URLs:</p>
    <Listbox
      v-model="selectedUrls"
      :options="importedUrlsObj"
      :multiple="true"
      :filter="false"
      optionLabel="name"
      emptyMessage="No URL imported yet."
      class="w-full h-96 overflow-auto"
      id="bulk-link-creation-form-url-table"
    />
  </div>
</template>

<style lang="postcss">
#bulk-link-creation-form-url-table > .p-listbox-list-wrapper > ul > li {
  @apply m-2.5 rounded-lg;
}
</style>
