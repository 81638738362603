import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

export const youtubeAutomationApiService = () => {
  if (process.server) return;

  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;

  const header = storeToRefs(useAuthStore()).header;

  const {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
  } = useSendRequest();

  const getConfig = (params = {}) => ({
    headers: header.value,
    responseType: "json",
    params: params,
  });

  const fetchYouTubeAutomations = async () => {
    try {
      const url = `${BASE_URL + apis.YOUTUBE_AUTOMATION}`;
      const response = await sendGetRequest(url, getConfig());
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  const createYouTubeAutomation = async (data) => {
    const url = `${BASE_URL + apis.YOUTUBE_AUTOMATION}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to create youtube automation"
      );
    }
  };

  const removeYouTubeAutomation = async (automation_id) => {
    const url = `${BASE_URL + apis.YOUTUBE_AUTOMATION}/${automation_id}`;
    try {
      const response = await sendDeleteRequest(url, {
        ...getConfig(),
      });
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to remove youtube automation"
      );
    }
  };

  const toggleYouTubeAutomation = async (automation_id) => {
    const url = `${
      BASE_URL + apis.YOUTUBE_AUTOMATION
    }/${automation_id}/toggle-pause/`;
    try {
      const response = await sendPatchRequest(url, {
        ...getConfig(),
      });
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "Failed to toggle youtube automation pause/resume"
      );
    }
  };

  return {
    fetchYouTubeAutomations,
    createYouTubeAutomation,
    removeYouTubeAutomation,
    toggleYouTubeAutomation,
  };
};
