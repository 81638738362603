<script setup></script>
<template>
  <div class="smartlnks-app overflow-hidden relative">
    <VitePwaManifest />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NotificationToast />
      <DialogManager />
      <SidebarManager />
      <CookieConsent />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style></style>
