import axios from "axios";
import { useAuthStore } from "~/store/auth.js";

export const useSendRequest = () => {
  const useAuth = useAuthStore();
  const { clearLocalStorage } = useAuth;

  const sendGetRequest = async (url, config) => {
    try {
      config["timeout"] = 10000; // 10 seconds
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      console.error(error);
      if (error.code === "ECONNABORTED") {
        throw new Error("Request took too long! Please try again.");
      }
      if (error?.response?.status === 401) logoutUser();

      // throw error; // Propagate the error to the calling function
      return error?.response;
    }
  };

  const sendPostRequest = async (url, config, data) => {
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        throw new Error("Request took too long! Please try again.");
      }
      if (error?.response?.status === 401) logoutUser();

      return error?.response;
    }
  };

  const sendPatchRequest = async (url, config, data) => {
    try {
      const response = await axios.patch(url, data, config);
      return response;
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        throw new Error("Request took too long! Please try again.");
      }
      if (error?.response?.status === 401) logoutUser();

      return error?.response;
    }
  };

  const sendDeleteRequest = async (url, data = {}, config) => {
    try {
      const response = await axios.delete(url, data, config);
      return response;
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        throw new Error("Request took too long! Please try again.");
      }
      if (error?.response?.status === 401) logoutUser();

      return error?.response;
    }
  };

  const logoutUser = () => {
    clearLocalStorage();
    navigateTo("/signin");
  };

  return {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
  };
};
