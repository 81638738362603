
import * as twicpicsRuntime$76DNfd0m8i from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/twicpics'
import * as awsS3Runtime$OCM2mOZwYE from '/opt/buildhome/repo/providers/aws-s3.js'
import * as noneRuntime$AlJCu1LSrU from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['twicpics']: { provider: twicpicsRuntime$76DNfd0m8i, defaults: {"baseURL":"https://smartlnks.twic.pics/assets"} },
  ['awsS3']: { provider: awsS3Runtime$OCM2mOZwYE, defaults: undefined },
  ['none']: { provider: noneRuntime$AlJCu1LSrU, defaults: {} }
}
        