<script setup>
import { storeToRefs } from "pinia";
import { useLinksTableUtilityApi } from "~/composables/links-table/useLinksTableUtilityApi.js";
import { useAuthStore } from "~/store/auth.js";
import { linksUtilityApiService } from "~/services/linksUtilityApiService.js";
import { generateRandomString, restrictedAliasChars } from "~/utils/string";
import { notifyError } from "~/utils/notifications";
import { PUBLIC_DOMAINS } from "~/utils/constants";
import { VOnboardingWrapper, useVOnboarding } from "v-onboarding";
import "v-onboarding/dist/style.css";

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
  formV$: {
    type: Object,
    required: true,
  },
  optional: {
    type: Boolean,
    default: true,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
  responseError: {
    type: Object,
  },
  clearResponseError: {
    type: Function,
    required: true,
  },
});

const { form, formV$, optional, clearResponseError } = props;

const collectionId = inject("collection-id");

const { isAuthenticated } = storeToRefs(useAuthStore());
const { fetchDomains, domainsData, loading: utilsLoading } = useLinksTableUtilityApi({
  collectionId,
});
const { checkSmartlnkAvailability } = linksUtilityApiService();

// ===============================================================
// FETCH DOMAINS
// ===============================================================
const computedDomainsOptions = computed(() => {
  let defaultDomains = [];
  if (form.domain) {
    defaultDomains.push({
      label: form.domain,
      value: form.domain,
    });
  }

  const publicDomains = PUBLIC_DOMAINS.map((domain) => ({
    label: domain,
    value: domain,
  }));

  const domainsOptions =
    domainsData.value?.map((domain) => ({
      label: domain.domain_name,
      value: domain.domain_name,
    })) || [];

  // updating to the new domain
  if (domainsOptions.length && !form.domain) form.domain = domainsOptions[0].value;

  // Combine and remove duplicates
  const combinedDomains = [...defaultDomains, ...domainsOptions, ...publicDomains];
  const uniqueDomains = Array.from(new Set(combinedDomains.map(JSON.stringify))).map(
    JSON.parse
  );

  return uniqueDomains;
});

const addDomainInDropdown = (domain) => {
  const index = domainsData.value?.findIndex((d) => d.value === domain.value);
  if (index === -1) {
    domainsData.value.push(domain);
  }
};

const removeDomainFromDropdown = (domain) => {
  const index = domainsData.value?.findIndex((d) => d.value === domain.value);
  if (index !== -1) {
    domainsData.value?.splice(index, 1);
  }
};

// ===============================================================
// CHECK LINK AVAILABILITY
// ===============================================================
const isSmartlnkAvailable = ref();
const isCheckingAvailablity = ref(false);
const checkLinkAvailability = async () => {
  isCheckingAvailablity.value = true;
  if (form.alias?.length >= 3 && form.domain) {
    const params = {
      smartlnk: `${props.form.domain}/${props.form.alias}`,
    };
    const response = await checkSmartlnkAvailability(params);
    if (response?.status === 200) {
      isSmartlnkAvailable.value = response.data?.data?.availability;
    } else {
      notifyError({ title: response.data?.message });
    }
  }
  isCheckingAvailablity.value = false;
};

// ==========================================================================
// ON DROPDOWN VALUE CHANGE
// ==========================================================================
const onDropdownValueChange = () => {
  resetlinkAvailability();
};
// ==========================================================================
// RESET LNK Available
// ==========================================================================
const resetlinkAvailability = () => {
  isSmartlnkAvailable.value = undefined;
  clearResponseError();
};

const onGenerateRandomAlias = () => {
  form.alias = generateRandomString();
  resetlinkAvailability();
};

const preventRestrictedCharacters = (event) => {
  if (
    restrictedAliasChars.includes(event.key) ||
    event.key === "\n" ||
    event.key === "\r"
  ) {
    event.preventDefault();
  }
};

const handleOnPaste = (event) => {
  const pastedText = (event.clipboardData || window?.clipboardData).getData("text");

  const foundRestrictedChars = restrictedAliasChars.filter((char) =>
    pastedText.includes(char)
  );

  if (
    restrictedAliasChars.some((char) => pastedText.includes(char)) ||
    pastedText.includes("\n") ||
    pastedText.includes("\r")
  ) {
    event.preventDefault();
    const formattedChars = foundRestrictedChars
      .map((char) => (char === "\n" ? "\\n" : char === "\r" ? "\\r" : char))
      .join(", ");

    notifyError({
      title: `Your paste contains restricted characters that are not allowed: ${formattedChars}. Please remove them and try again.`,
    });
  }
};

const wrapper = ref(null);
const { start } = useVOnboarding(wrapper);

const steps = [
  {
    attachTo: { element: "#select-custom-domain-dropdown1" },
    content: { title: "You can now select diff domains." },
    options: {
      labels: {
        finishButton: "OK, Got it",
      },
    },
    on: {
      afterStep: function (options) {
        console.log("==> finish step");
      },
    },
  },
];

// ===============================================================
// CYCLES
// ===============================================================
onMounted(() => {
  if (isAuthenticated.value) fetchDomains({ linkType: "smartlnks" });
  if (!form.alias) onGenerateRandomAlias();
});

// watch([utilsLoading], () => {
//   if (!utilsLoading.value) start();
// });
</script>
<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps" id="alias-v-onboarding-wrapper" />
  <div v-if="utilsLoading" class="loading-overlay rounded-lg">
    <LoadersRingLoader />
  </div>

  <div class="field">
    <label
      for="alias-input-field"
      class="block mb-2 text-sm font-medium"
      :class="{
        'text-red-700 dark:text-red-500':
          (formV$.alias.$invalid && isSubmitted) || (responseError && isSubmitted),
      }"
      >Alias {{ optional ? "(Optional)" : null }}</label
    >

    <div class="grid grid-cols-5">
      <Dropdown
        v-model="formV$.domain.$model"
        :options="computedDomainsOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Select a domain"
        class="w-full md:col-span-2 col-span-5 mb-8 md:mb-0 !bg-gray-50 dark:!bg-gray-700 !border-gray-300 !text-gray-900 !text-sm focus:!border-[#d4d4d8] md:!rounded-r-none md:!border-r-0"
        id="select-custom-domain-dropdown1"
        :filter="false"
        @change="onDropdownValueChange()"
      />

      <div class="relative col-span-5 md:col-span-3">
        <span class="p-input-icon-right w-full">
          <i class="flex items-center">
            <i
              v-if="
                (formV$.alias.$invalid && isSubmitted) || (responseError && isSubmitted)
              "
              class="pi pi-exclamation-triangle !text-red-500"
            />
            <i
              @click="onGenerateRandomAlias"
              class="pi pi-refresh cursor-pointer text-primary font-bold ml-3"
            ></i>
          </i>

          <input
            v-model.trim="formV$.alias.$model"
            @keyup="resetlinkAvailability()"
            @keydown="preventRestrictedCharacters"
            @paste="handleOnPaste"
            type="text"
            id="alias-input-field"
            class="block w-full p-3.5 bg-gray-50 border sm:text-md rounded-lg md:rounded-l-none dark:bg-gray-700 border-gray-300 text-gray-900 text-sm pl-[0.8rem]"
            :class="{
              '!border-red-500 !text-red-900 !placeholder-red-700 focus:!ring-red-500 focus:!border-red-500 dark:!text-red-500 dark:!placeholder-red-500 dark:!border-red-500':
                (formV$.alias.$invalid && isSubmitted) || (responseError && isSubmitted),
            }"
            placeholder="Enter your desired URL"
            aria-describedby="alias-input-field"
          />
        </span>
      </div>
    </div>
    <!-- alias error -->
    <div v-if="formV$.alias.$errors.length > 0 && isSubmitted" class="alias-input-errors">
      <div v-for="error of formV$.alias.$errors" :key="error.$uid">
        <p id="	alias-input-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
          <span class="font-medium">Oh, snapp!</span>
          {{ error.$message.replace("This field", "Alias") }}.
        </p>
      </div>
    </div>
    <!-- if domain pending -->
    <p
      v-else-if="
        (formV$.domain.$invalid && isSubmitted) || (formV$.domain.$pending && isSubmitted)
      "
      class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">Oh, snapp!</span>
      {{ formV$.domain.required.$message }}.
    </p>
    <!-- domain errors -->
    <div
      v-else-if="formV$.domain.$errors.length > 0 && isSubmitted"
      class="domain-input-errors"
      v-for="(error, i) of formV$.domain.$errors"
      :key="i"
    >
      <p id="	domain-input-error" class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error.$message.replace("This field", "Domain") }}.
      </p>
    </div>

    <p
      v-if="isSmartlnkAvailable != undefined"
      id="alias-input-error"
      class="block mt-2 text-sm"
      :class="
        isSmartlnkAvailable
          ? 'text-green-600 dark:text-green-500'
          : 'text-red-600 dark:text-red-500'
      "
    >
      <span v-if="isSmartlnkAvailable">
        <span class="font-medium"> Great!</span> Alias Available</span
      >
      <span v-else><span class="font-medium"> Unlucky!</span> Alias Not Available</span>
    </p>
    <div
      class="flex"
      :class="
        formV$.alias.$model?.length &&
        formV$.domain.$model?.length &&
        isSmartlnkAvailable === undefined &&
        formV$.alias.$errors.length === 0 &&
        formV$.domain.$errors.length === 0
          ? 'justify-between'
          : 'justify-end'
      "
    >
      <Button
        v-if="
          form.alias.length &&
          form.domain?.length &&
          isSmartlnkAvailable === undefined &&
          formV$.alias.$errors.length === 0 &&
          formV$.domain.$errors.length === 0
        "
        @click="checkLinkAvailability()"
        class="mt-0 text-black font-medium text-sm cursor-pointer"
        label="check availability"
        id="check-availability-btn"
        text
        :loading="isCheckingAvailablity"
      />
      <!-- <CaseSensitiveField
        v-if="form.alias?.length === 0 && formV$.domain.$errors.length === 0"
        :caseSensitiveFormField="formV$.caseSensitive"
        :isSubmitted="isSubmitted"
      /> -->
    </div>
  </div>
  <br />
</template>

<style lang="postcss">
#select-custom-domain-dropdown1 > button {
  border-radius: 0 !important;
  @apply !border !border-r-0 !border-[#d4d4d8] !text-black;
}

#select-custom-domain-dropdown1 > input {
  @apply border-r-0 lowercase;
}

#select-custom-domain-dropdown1 > .p-inputtext:enabled:focus {
  border-color: rgb(212, 212, 216) !important;
}

#check-availability-btn {
  @apply p-0 text-sm text-black !bg-transparent !border-transparent;
}

#select-custom-domain-dropdown1.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  @apply !border-[#d4d4d8];
}

#alias-v-onboarding-wrapper .v-onboarding-item__header-title,
.v-onboarding-item__actions > button {
  @apply !text-sm;
}
</style>
