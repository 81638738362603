<script setup>
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required, url } from "@vuelidate/validators";
import LinkPreview from "~/components/VueLinkPreview.vue";
import { linksUtilityApiService } from "~/services/linksUtilityApiService.js";
import { removeAllParams } from "~/utils/urls.js";

const { providedUrl } = defineProps({
  providedUrl: {
    type: String,
  },
});

const { previewLink } = linksUtilityApiService();

const form = reactive({
  url: providedUrl,
});

const formRules = {
  url: {
    required: helpers.withMessage("Smartlnk is required", required),
    maxLength: helpers.withMessage(
      "Smartlnk must be 200 characters or less",
      maxLength(200)
    ),
    url: helpers.withMessage("The entered value is not a valid URL address", url),
  },
};

const formV$ = useVuelidate(formRules, form);


const loading = ref(false);
const isSubmitted = ref(false);
const data = ref(null);
const error = ref(null);
const responseErrorMessage = ref();

async function onSubmitted(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    return;
  }

  loading.value = true;
  data.value = null;
  error.value = null;
  responseErrorMessage.value = null;

  try {
    const response = await previewLink({ url: removeAllParams(form.url) });
    handleResponse(response);
  } catch (err) {
    notifyError(err?.message);
  } finally {
    isSubmitted.value = false;
    loading.value = false;
  }
}

const handleResponse = (response) => {
  if (response?.status === 200) {
    data.value = response.data?.data;
    error.value = null;
    responseErrorMessage.value = null;
  } else {
    notifyError(response);
  }
};

const notifyError = (err) => {
  error.value = err;

  responseErrorMessage.value =
    err.data?.details ||
    "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.";
};

onMounted(() => {
  if (providedUrl) {
    form.url = providedUrl;
    onSubmitted();
  }
});
</script>

<template>
  <div>
    <Dialog
      header="Preview SmartLnk/Domain"
      :visible="true"
      @update:visible="$emit('close')"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :dismissableMask="true"
      id="preview-link-dialog"
    >
      <h2 class="text-sm text-gray-600">
        Find out where a Smartlnks/domains will take you before clicking on it.
      </h2>
      <div v-if="!providedUrl" class="mt-10">
        <CustomFieldText
          :Field="formV$.url"
          :isSubmitted="isSubmitted"
          :responseError="responseErrorMessage"
          :label="'Enter Smartlnk/domain'"
          :inputId="'smart-lnk-domain-input-field'"
          :placeholder="'Type or paste a smartlnk/domain'"
          :showResponseError="true"
          :helpText="'Please include \'https://\' or \'http://\' in the link. For example: \'https://smartlnks.com/alias\' or \'http://prefix.smartlnks.com/postfix\'.'"
          @clearResponseError="error = null"
        />
        <br />
        <Button
          label="Preview"
          class="w-full"
          :loading="loading"
          @click="onSubmitted(formV$.$invalid)"
        />
      </div>
      <div v-if="data?.destination_url">
        <!-- <LinkPreview url="https://vuejs.org/">
          <template v-slot:loader>
            loader...
          </template>
        </LinkPreview> -->

        <CardDynamicTwitter
          :key="new Date().getMilliseconds"
          :destinationUrl="data?.destination_url"
          :smartlink="form.url"
          :display-horizontal-line="false"
        />
        <CustomShareLinkRow
          class="mt-4"
          :link="form.url"
          :title="'Check out this Smartlink - Make Your Links Stand Out with Smartlnks.com'"
          :description="'Want to make your links stand out? Check out Smartlnks.com - Create short, memorable and trackable links that will help you drive more traffic and measure the success of your campaigns. Try it now!'"
          :quote="'Revolutionize the way you share links.'"
          :hashtags="'#smartlnks #safelinks #shortlinks #brandedlinks #growth'"
          :showOverflowBar="true"
        />
      </div>
    </Dialog>
  </div>
</template>

<style lang="postcss">
#preview-link-dialog {
  @apply w-[80%] md:!w-[30%];
}
#preview-link-dialog > .p-dialog-header {
  @apply pb-0;
}
</style>
