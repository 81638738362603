export const extractDomain = (url) => {
  // One way
  if (url === "direct") return "direct";

  var urlParse = new URL(url);
  return urlParse.hostname;

  // Another way
  // var domain;
  // //find & remove protocol (http, ftp, etc.) and get domain
  // if (url.indexOf("://") > -1) {
  // 	domain = url.split("/")[2];
  // } else {
  // 	domain = url.split("/")[0];
  // }

  // //find & remove www
  // if (domain.indexOf("www.") > -1) {
  // 	domain = domain.split("www.")[1];
  // }

  // domain = domain.split(":")[0]; //find & remove port number
  // domain = domain.split("?")[0]; //find & remove url params

  // return domain;
};

export const extractDomainFromUrl = (url) => {
  const domain = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
  const parts = domain.split(".");
  if (parts.length > 2) {
    return parts.slice(-2).join(".");
  }
  return domain;
};

export const removeAllParams = (url) => {
  return url.split("?")[0];
};

export const removeCustomParams = (url, params) => {
  let urlObject = new URL(url);
  let searchParams = urlObject.searchParams;

  params.forEach((param) => {
    searchParams.delete(param);
  });

  return urlObject.toString();
};

export const removeUTMParameters = (url) => {
  let urlObject = new URL(url);
  let searchParams = urlObject.searchParams;

  // List of UTM parameters that you want to remove
  let utmParameters = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  utmParameters.forEach((param) => {
    searchParams.delete(param);
  });

  return urlObject.toString();
};

export const extractUniqueUrls = (text) => {
  const urlRegex = /https?:\/\/[^\s]+/g;
  const urls = new Set();

  let match;
  while ((match = urlRegex.exec(text)) !== null) {
    urls.add(match[0]);
  }

  return Array.from(urls);
};

export const extractUrlObjects = (text) => {
  const urlRegex = /https?:\/\/[^\s]+/g;
  const urls = new Set();

  let match;
  while ((match = urlRegex.exec(text)) !== null) {
    urls.add(match[0]);
  }

  return Array.from(urls).map((url) => ({ name: url }));
};

export const isPublicDomainUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;

    return PUBLIC_DOMAINS.includes(hostname);
  } catch (error) {
    console.error("Error parsing URL:", error);
    return false;
  }
};
