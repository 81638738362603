import { joinURL } from "ufo";

export const getImage = (src, { modifiers = {}, baseURL } = {}) => {
  if (!baseURL) {
    baseURL = "https://smartlnks-assets.s3.ap-south-1.amazonaws.com";
  }

  // Add any necessary modifications to the URL here
  const operations = ""; // Modify this based on your requirements

  return {
    url: joinURL(baseURL, src + (operations ? "?" + operations : "")),
  };
};
