
// @ts-nocheck
import locale__opt_buildhome_repo_locales_en_yaml from "../locales/en.yaml";
import locale__opt_buildhome_repo_locales_es_yaml from "../locales/es.yaml";
import locale__opt_buildhome_repo_locales_ur_yaml from "../locales/ur.yaml";
import locale__opt_buildhome_repo_locales_hi_yaml from "../locales/hi.yaml";
import locale__opt_buildhome_repo_locales_ar_yaml from "../locales/ar.yaml";
import locale__opt_buildhome_repo_locales_fr_yaml from "../locales/fr.yaml";
import locale__opt_buildhome_repo_locales_de_yaml from "../locales/de.yaml";
import locale__opt_buildhome_repo_locales_pt_yaml from "../locales/pt.yaml";
import locale__opt_buildhome_repo_locales_sv_yaml from "../locales/sv.yaml";
import locale__opt_buildhome_repo_locales_ja_yaml from "../locales/ja.yaml";
import locale__opt_buildhome_repo_locales_ko_yaml from "../locales/ko.yaml";
import locale__opt_buildhome_repo_locales_zh_yaml from "../locales/zh.yaml";


export const localeCodes =  [
  "en",
  "es",
  "ur",
  "hi",
  "ar",
  "fr",
  "de",
  "pt",
  "sv",
  "ja",
  "ko",
  "zh"
]

export const localeMessages = {
  "en": [{ key: "../locales/en.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_en_yaml), cache: true }],
  "es": [{ key: "../locales/es.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_es_yaml), cache: true }],
  "ur": [{ key: "../locales/ur.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_ur_yaml), cache: true }],
  "hi": [{ key: "../locales/hi.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_hi_yaml), cache: true }],
  "ar": [{ key: "../locales/ar.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_ar_yaml), cache: true }],
  "fr": [{ key: "../locales/fr.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_fr_yaml), cache: true }],
  "de": [{ key: "../locales/de.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_de_yaml), cache: true }],
  "pt": [{ key: "../locales/pt.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_pt_yaml), cache: true }],
  "sv": [{ key: "../locales/sv.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_sv_yaml), cache: true }],
  "ja": [{ key: "../locales/ja.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_ja_yaml), cache: true }],
  "ko": [{ key: "../locales/ko.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_ko_yaml), cache: true }],
  "zh": [{ key: "../locales/zh.yaml", load: () => Promise.resolve(locale__opt_buildhome_repo_locales_zh_yaml), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "isCatchallLocale": true,
      "files": [
        "locales/en.yaml"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "files": [
        "locales/es.yaml"
      ]
    },
    {
      "code": "ur",
      "iso": "ur-PK",
      "files": [
        "locales/ur.yaml"
      ]
    },
    {
      "code": "hi",
      "iso": "hi-IN",
      "files": [
        "locales/hi.yaml"
      ]
    },
    {
      "code": "ar",
      "iso": "ar-SA",
      "files": [
        "locales/ar.yaml"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "files": [
        "locales/fr.yaml"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "files": [
        "locales/de.yaml"
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "files": [
        "locales/pt.yaml"
      ]
    },
    {
      "code": "sv",
      "iso": "sv-SE",
      "files": [
        "locales/sv.yaml"
      ]
    },
    {
      "code": "ja",
      "iso": "ja-JP",
      "files": [
        "locales/ja.yaml"
      ]
    },
    {
      "code": "ko",
      "iso": "ko-KR",
      "files": [
        "locales/ko.yaml"
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "files": [
        "locales/zh.yaml"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_preferred_locale",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": true,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "iso": "en-US",
      "isCatchallLocale": true,
      "files": [
        {
          "path": "locales/en.yaml"
        }
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "files": [
        {
          "path": "locales/es.yaml"
        }
      ]
    },
    {
      "code": "ur",
      "iso": "ur-PK",
      "files": [
        {
          "path": "locales/ur.yaml"
        }
      ]
    },
    {
      "code": "hi",
      "iso": "hi-IN",
      "files": [
        {
          "path": "locales/hi.yaml"
        }
      ]
    },
    {
      "code": "ar",
      "iso": "ar-SA",
      "files": [
        {
          "path": "locales/ar.yaml"
        }
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "files": [
        {
          "path": "locales/fr.yaml"
        }
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "files": [
        {
          "path": "locales/de.yaml"
        }
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "files": [
        {
          "path": "locales/pt.yaml"
        }
      ]
    },
    {
      "code": "sv",
      "iso": "sv-SE",
      "files": [
        {
          "path": "locales/sv.yaml"
        }
      ]
    },
    {
      "code": "ja",
      "iso": "ja-JP",
      "files": [
        {
          "path": "locales/ja.yaml"
        }
      ]
    },
    {
      "code": "ko",
      "iso": "ko-KR",
      "files": [
        {
          "path": "locales/ko.yaml"
        }
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "files": [
        {
          "path": "locales/zh.yaml"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

