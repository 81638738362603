<script setup>
import { form } from "~/validations/homeLinkFormRules";
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore";
import { storeToRefs } from "pinia";

const { isCoolDownOn } = storeToRefs(useHomeLinkFormStore());

const onTabChange = ({ index }) => {
  isCoolDownOn.value = false;
  form.tab = index === 0 ? "smartlnk" : "smartdomain";
};
</script>
<template>
  <div class="my-5">
    <TabView
      id="custom-link-options-tabview"
      v-auto-animate="{ duration: 300 }"
      @tab-change="onTabChange"
    >
      <TabPanel v-auto-animate="{ duration: 300 }" header="SmartLnk">
        <DialogCustomLinkOptionsTabsAlias @mouseover="isCoolDownOn = false" />
      </TabPanel>
      <TabPanel v-auto-animate="{ duration: 300 }" header="SmartDomain">
        <DialogCustomLinkOptionsTabsSubdomain @mouseover="isCoolDownOn = false"
      /></TabPanel>
    </TabView>
  </div>
</template>

<style lang="postcss">
#custom-link-options-tabview > .p-tabview-panels {
  @apply py-0;
}

#custom-link-options-tabview > .p-tabview-nav-container {
  @apply p-2 bg-gray-100 rounded-xl;
}

#custom-link-options-tabview
  > .p-tabview-nav-container
  > .p-tabview-nav-content
  > .p-tabview-nav {
  @apply border-none w-full;
}

#custom-link-options-tabview
  > .p-tabview-nav-container
  > .p-tabview-nav-content
  > .p-tabview-nav
  > .p-tabview-header {
  @apply w-1/2 justify-center bg-gray-100;
}

#custom-link-options-tabview
  > .p-tabview-nav-container
  > .p-tabview-nav-content
  > .p-tabview-nav
  > .p-tabview-header
  > .p-tabview-nav-link {
  @apply justify-center w-full py-4;
}

#custom-link-options-tabview
  > .p-tabview-nav-container
  > .p-tabview-nav-content
  > .p-tabview-nav
  > .p-tabview-header
  > .p-tabview-nav-link {
  @apply bg-gray-100 text-black border-none rounded-lg;
}

#custom-link-options-tabview
  > .p-tabview-nav-container
  > .p-tabview-nav-content
  > .p-tabview-nav
  > .p-tabview-header.p-highlight
  > .p-tabview-nav-link {
  @apply bg-white text-black border-none rounded-lg;
}
</style>
