// import AddToHomescreen from "@owliehq/vue-addtohomescreen";
import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.vueApp.use(AddToHomescreen, {
  //   title: "🚀SmartLnks: One Tap to Rule Them All!",
  //   content: "👉 Why bother searching? Keep SmartLnks handy on your Home Screen! 👈",
  //   iconPath: "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/logo.png",
  // });
});
