<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted } = storeToRefs(useLinkFormStore());
</script>
<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- Title Field -->
    <CustomFieldText
      :Field="formV$.metaTitle"
      :isSubmitted="isSubmitted"
      :label="'Meta Title'"
      :inputId="'meta-title-input-field'"
      :placeholder="'Enter meta title here'"
    />

    <!-- Description Field -->
    <CustomFieldTextarea
      :Field="formV$.metaDescription"
      :isSubmitted="isSubmitted"
      :label="'Meta Description'"
      :inputId="'meta-description-input-field'"
      :placeholder="'Enter meta description here'"
    />

    <!-- Image Field -->
    <CustomFieldText
      :Field="formV$.metaImageURL"
      :isSubmitted="isSubmitted"
      :label="'Meta Image URL'"
      :inputId="'meta-image-url-input-field'"
      :placeholder="'Enter meta image url here'"
    />
  </div>
</template>

<style></style>
