import { defineStore } from "pinia";
import Cookies from "js-cookie";

export const useAuthStore = defineStore("AuthStore", () => {
  if (process.server) return;

  const token = ref(Cookies.get("token"));

  const getToken = () => token.value !== undefined;

  const header = computed(() => ({
    "Content-Type": "application/json",
    Authorization: `Token ${token.value || ""}`,
  }));

  const mediaHeader = computed(() => ({
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${token.value || ""}`,
  }));

  const isAuthenticated = computed(() => getToken());

  const getUserInfo = () => {
    try {
      return JSON.parse(localStorage.getItem("userInfo") || "{}");
    } catch (error) {
      console.error("Error parsing user info:", error);
      return {};
    }
  };

  function saveUserInfo(newUserInfo) {
    localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
    userInfo.value = newUserInfo;
  }

  function saveToken(newToken) {
    Cookies.set("token", newToken, { expires: 7, secure: true }); // 7 day, for hours divide by 24 (eg. 1/24)
    token.value = newToken;
    isAuthenticated.value = true;
  }

  function clearLocalStorage() {
    localStorage.clear();
    Cookies.remove("token");
    token.value = undefined;
    isAuthenticated.value = false;
  }

  const userInfo = ref(getUserInfo());

  return {
    isAuthenticated,
    header,
    mediaHeader,
    token,
    saveToken,
    saveUserInfo,
    userInfo,
    clearLocalStorage,
  };
});
