import { defineStore } from "pinia";

export const useDialogsStore = defineStore("DialogsStore", () => {
  if (process.server) return;

  const dialogs = reactive({
    linkPreview: {
      show: false,
      link: null,
      callback: null,
    },
    scanLink: {
      show: false,
      link: null,
      callback: null,
    },
    customQrCode: {
      show: false,
      link: null,
      callback: null,
    },
    moveLinks: {
      show: false,
      selectedLinks: null,
      callback: null,
    },
    shareLink: {
      show: false,
      link: null,
      callback: null,
    },
    privacyPolicy: {
      show: false,
    },
    cookiePrivacyPolicy: {
      show: false,
    },
    termsOfService: {
      show: false,
    },
    linkForm: {
      show: false,
      linkType: null,
      collectionId: null,
      linkId: null,
      scheduledData: null,
      callback: null,
    },
    linkCreated: {
      show: false,
      link: null,
      dashboardLink: null,
      collectionId: null,
      didYouKnow: null,
      showMoreOptions: false,
      showConfetti: false,
    },
    collectionForm: {
      show: false,
      selectedCollection: null,
      callback: null,
    },
    pixelForm: {
      show: false,
      callback: null,
    },
    calendar: {
      show: false,
      selectedPeriod: null,
      setSelectedPeriod: null,
      callback: null,
    },
    resetPassword: {
      show: false,
      email: null,
      callback: null,
    },
    talkToUs: {
      show: false,
      callback: null,
    },
    customLinkOptions: {
      show: false,
      destinationUrl: null,
      callback: null,
    },
    clipboard: {
      show: false,
      url: null,
      callback: null,
    },
    domainForm: {
      show: false,
      action: "add",
      data: null,
      callback: null,
    },
    bulkLinkCreationForm: {
      show: false,
      callback: null,
    },
    youtubeAutomationForm: {
      show: false,
      callback: null,
    },
  });

  const handleDialogClose = (dialogType, payload = {}) => {
    console.log("==> handleDialogClose for ", dialogType);
    console.log("==> payload: ", payload);
    if (payload?.refresh && dialogs[dialogType].callback) {
      dialogs[dialogType].callback();
      dialogs[dialogType].callback = null; // Clear the callback
    }
    dialogs[dialogType].show = false;
  };

  return { dialogs, handleDialogClose };
});
