<script setup>
import { required, maxLength, helpers, url } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useTimeAgo } from "@vueuse/core";
import { linksUtilityApiService } from "~/services/linksUtilityApiService.js";
import { removeAllParams } from "~/utils/urls.js";

const { scanLink } = linksUtilityApiService();

const form = reactive({
  url: "",
});

const formRules = {
  url: {
    required: helpers.withMessage("Smartlnk is required", required),
    maxLength: helpers.withMessage(
      "Smartlnk must be 200 characters or less",
      maxLength(200)
    ),
    url: helpers.withMessage("The entered value is not a valid URL address", url),
  },
};
const formV$ = useVuelidate(formRules, form);


const responseError = ref();
const isSubmitted = ref(false);
const showLoader = ref(false);
const responseData = ref();

async function onSubmitted(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    return;
  }

  responseData.value = undefined;
  responseError.value = undefined;
  showLoader.value = true; // show loader

  resetResponseError();

  const response = await scanLink({ url: removeAllParams(form.url) });

  if (response.status === 200) {
    resetResponseError();
    responseData.value = response.data?.data;
  } else {
    responseError.value =
      response.data?.details ||
      "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.";
  }

  showLoader.value = false;
  isSubmitted.value = false;
}

const resetResponseError = () => {
  responseError.value = null;
};
</script>

<template>
  <Dialog
    header="Scan Smartlnks/domains"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    id="scan-link-dialog"
  >
    <h2 class="text-sm text-gray-600">
      Use this tool to check the safety of a Smartlnks/Domain link before clicking on it.
      It only takes a few seconds to scan a link, and it can help protect you from malware
      and other security threats.
    </h2>
    <div class="grid grid-cols-1 gap-1">
      <div class="col-span-1 mt-10">
        <CustomFieldText
          :Field="formV$.url"
          :isSubmitted="isSubmitted"
          :responseError="responseError"
          :label="'Enter Smartlnk/domain'"
          :inputId="'smart-lnk-domain-input-field'"
          :placeholder="'Type or paste a smartlnk/domain'"
          :showResponseError="true"
          :helpText="'Please include \'https://\' or \'http://\' in the link. For example: \'https://smartlnks.com/alias\' or \'http://prefix.smartlnks.com/postfix\'.'"
          @clearResponseError="resetResponseError()"
        />
        <br />

        <Button
          :label="showLoader ? 'Scanning...' : 'Scan'"
          class="w-full"
          :loading="showLoader"
          @click="onSubmitted(formV$.$invalid)"
        />
      </div>
      <div v-if="responseData" class="col-span-1 mt-10">
        <span class="text-sm text-gray-500"
          >last updated:
          {{ useTimeAgo(responseData?.last_scanned_timestamp).value }}
          <i
            class="pi pi-info-circle !text-xs cursor-pointer"
            v-tooltip="{
              value: 'It will be rescanned at least 5 minutes after the last update.',
              class: '!text-xs',
            }"
          ></i
        ></span>
        <div
          class="font-medium rounded-xl p-5"
          :class="
            responseData?.is_safe
              ? 'bg-[#ECFDF5] text-[#059669]'
              : 'bg-[#FEF3C7] text-[#DC2626]'
          "
        >
          {{ responseData?.message }}
        </div>
      </div>
    </div>
  </Dialog>
</template>

<style lang="postcss">
#scan-link-dialog {
  @apply w-[80%] md:!w-[30%];
}
#scan-link-dialog > .p-dialog-header {
  @apply pb-0;
}
</style>
