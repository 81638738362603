<template>
  <div class="grid grid-cols-1 gap-1">
    <div class="col-span-1">
      <p class="mt-2 text-sm text-gray-500">
        Welcome to <a class="underline" href="https://smartlnks.com">SmartLnks</a>, where
        managing links is as easy as pie, and our cookies ensure it stays that way! Here’s
        how we crumble:
      </p>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">Consent</h1>
      <p class="mt-2 text-sm text-gray-500">
        By using Smartlnks, you consent to our use of cookies. Hit 'Got it' to continue
        your journey with us. Happy linking!
      </p>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">What are Cookies?</h1>
      <p class="mt-2 text-sm text-gray-500">
        Just digital snacks! Cookies are small text files stored on your device to enhance
        your experience.
      </p>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">Why We Use Cookies</h1>
      <p class="mt-2 text-sm text-gray-500">
        To make Smartlnks smarter! They help with site functionality, personalizing
        content, and analyzing our traffic to improve our services.
      </p>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">Types of Cookies We Use</h1>
      <ul class="mt-3 ml-3 text-sm text-gray-500 list-disc">
        <li>
          <span class="font-semibold"> Essential Cookies: </span>For basic site
          functionalities.
        </li>
        <li>
          <span class="font-semibold"> Performance Cookies: </span>To understand user
          behavior and improve our platform.
        </li>
        <li>
          <span class="font-semibold"> Functional Cookies: </span>For personalizing your
          Smartlnks experience.
        </li>
        <li>
          <span class="font-semibold"> Advertising Cookies: </span>To show you offers that
          might interest you.
        </li>
      </ul>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">Your Choices</h1>
      <p class="mt-2 text-sm text-gray-500">
        You’re in control! Manage your cookie preferences in your browser settings.
        Remember, turning off cookies might affect your experience on our site.
      </p>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">More Information</h1>
      <p class="mt-2 text-sm text-gray-500">
        For the full breakdown, check out our Privacy Policy. We're committed to
        protecting your digital trail!
      </p>
    </div>
    <div class="col-span-1">
      <h1 class="text-xl font-semibold mt-8">Contact Us</h1>
      <p class="mt-2 text-sm text-gray-500">
        If you have any questions about this Privacy Policy or our privacy practices,
        please contact us at team@smartlnks.com.
        <br />
        Last Updated: Jan 3, 2024
      </p>
    </div>
  </div>
</template>
