import { defineStore } from "pinia";
import { ref } from "vue";

export const useBulkLinkCreationFormStore = defineStore(
  "ImportLinksFormStore",
  () => {
    if (process.server) return;

    const activeTab = ref(0);
    const importedUrlsObj = ref([]);
    const selectedUrls = ref([]);
    const responseError = ref();

    const updateImportedUrls = (urls) => {
      importedUrlsObj.value = urls;
    };

    const updateSelectedUrls = (urls) => {
      console.log("==> urls.length: ", urls.length);
      selectedUrls.value = urls;
    };

    const clearStore = () => {
      activeTab.value = 0;
      importedUrlsObj.value = [];
      selectedUrls.value = [];
    };

    return {
      activeTab,
      importedUrlsObj,
      selectedUrls,
      responseError,
      updateImportedUrls,
      updateSelectedUrls,
      clearStore,
    };
  }
);
