import { defineStore } from "pinia";

export const useCollectionFormStore = defineStore("collectionForm", () => {
  if (process.server) return;

  const responseError = ref();
  const isSubmitted = ref(false);
  const clearResponseError = () => (responseError.value = null);

  const reset = () => {
    clearResponseError();
    isSubmitted.value = false;
  };

  return {
    isSubmitted,
    responseError,
    reset,
    clearResponseError,
  };
});
