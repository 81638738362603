<template>
  <div class="grid grid-cols-1 gap-7">
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">1. Introduction</h2>
      <p class="mt-2 text-sm text-gray-500">
        These Terms and Conditions apply to the use of smartlnks.com (the "Website"). By
        accessing the Website, you agree to be bound by these Terms and Conditions. If you
        do not agree with any part of these Terms and Conditions, you must not use the
        Website.
      </p>
    </div>
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">2. Use of Website</h2>

      <p class="mt-2 text-sm text-gray-500">
        2.1 You may use the Website for lawful purposes only. <br /><br />
        2.2 You must not use the Website in any way that causes or may cause damage to the
        Website or impairment of the availability or accessibility of the Website.
        <br /><br />
        2.3 You must not use the Website in any way that is unlawful, fraudulent, or
        harmful, or in connection with any unlawful, fraudulent, or harmful purpose or
        activity.<br /><br />
        2.4 We reserve the right to restrict access to certain areas of the Website, or
        the whole Website, at our discretion.
      </p>
    </div>
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">Accounts</h2>
      <p class="mt-2 text-sm text-gray-500">
        3.1 In order to use certain features of the Website, you may be required to create
        an account. <br /><br />
        3.2 You are responsible for maintaining the confidentiality of your account
        information, including your password. <br /><br />
        3.3 You are responsible for all activities that occur under your account.
      </p>
    </div>
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">iFrames</h2>

      <p class="mt-2 text-sm text-gray-500">
        Without prior approval and written permission, you may not create frames around
        our Webpages that alter in any way the visual presentation or appearance of our
        Website.
      </p>
    </div>
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">Intellectual Property</h2>

      <p class="mt-2 text-sm text-gray-500">
        4.1 The Website and its original content, features, and functionality are owned by
        smartlnks.com and are protected by international copyright, trademark, patent,
        trade secret, and other intellectual property or proprietary rights laws.
        <br /><br />
        4.2 You may not reproduce, modify, distribute, display, or sell any content on the
        Website without our prior written permission.
      </p>
    </div>

    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">Limitation of Liability</h2>

      <p class="mt-2 text-sm text-gray-500">
        5.1 The Website is provided "as is" and we make no representations or warranties
        of any kind, express or implied, regarding the Website or the content contained on
        the Website. <br /><br />
        5.2 We will not be liable for any damages arising out of the use of the Website,
        including but not limited to any direct, indirect, incidental, consequential or
        punitive damages.<br /><br />
        5.3 We will not be liable for any damages arising out of the inability to access
        the Website or any interruption in the availability of the Website.<br /><br />
      </p>
    </div>
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">
        Modifications to Terms and Conditions
      </h2>

      <p class="mt-2 text-sm text-gray-500">
        We reserve the right to modify these Terms and Conditions at any time. Your
        continued use of the Website following any changes to these Terms and Conditions
        constitutes your acceptance of those changes.
      </p>
    </div>
    <div class="col-span-1">
      <h2 class="text-lg font-medium text-gray-900">Contact Information</h2>

      <p class="mt-2 text-sm text-gray-500">
        If you have any questions or comments about these Terms and Conditions, please
        contact us at team@smartlnks.com.
        <br />
        Last Updated: Feb 28, 2023
      </p>
    </div>
  </div>
</template>
