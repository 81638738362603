import {
  maxLength,
  required,
  url,
  minLength,
  minValue,
  numeric,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// Common Validators
const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));
const validUrl = helpers.withMessage(
  "The entered value is not a valid URL address",
  url
);
const requiredDestinationUrl = {
  required: requiredMsg("Destination URL is required"),
  url: validUrl,
  maxLength: maxLengthMsg(
    "Destination URL must be 200 characters or less",
    200
  ),
};

const pixelIdRegex = (pixelType) => {
  const patterns = {
    fb: /^fb-\d+$/,
    gtm: /^gtm-\w+$/,
    ga: /^ga-\w+$/,
    gads: /^gads-\w+$/,
    li: /^li-\w+$/,
    tw: /^tw-\w+$/,
    ar: /^ar-\w+$/,
    qr: /^qr-\w+$/,
    pin: /^pin-\w+$/,
    sc: /^sc-\w+$/,
    rd: /^rd-\w+$/,
    tt: /^tt-\w+$/,
  };
  return patterns[pixelType] || /.*/;
};

const defaultFormValues = () => ({
  updatedOn: "",

  id: 0,

  domain: "smartlnks.com",

  // basic
  title: "",
  destinationURL: "",
  alias: "",
  description: "",
  tags: [],
  caseSensitive: true,

  prefix: "",
  postfix: "",

  // schedule it
  dateTimeRange: [],
  expirationRedirect: "",
  autoDltOnExpire: false,

  // clicks limit
  clicksLimit: null,
  clicksLimitMode: { label: "Any", value: "ANY" },

  // password
  password: "",

  // add Warnings
  warning: "NONE",

  // permissions
  allowBotClick: true,

  // device target
  deviceTargets: [],

  // browser target
  browserTargets: [],

  // geo target
  geoTargets: [],

  // pixels
  trackingPixels: [],

  // custom meta data
  metaTitle: "",
  metaDescription: "",
  metaImageURL: "",

  // UTM Builder
  campaignSource: "",
  campaignMedium: "",
  campaignName: "",
  campaignTerm: "",
  campaignContent: "",

  // YouTube One Domain
  yt_channel_id: "",
});

export const form = reactive(defaultFormValues());

export const formRules = {
  domain: {
    required: requiredMsg("Domain is required"),
  },

  // basic
  title: {
    minLength: minLengthMsg("Title must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Title must be 30 characters or less", 30),
  },
  destinationURL: {
    required: requiredMsg("Destination URL is required"),
    maxLength: maxLengthMsg(
      "Destination URL must be 400 characters or less",
      400
    ),
    url: validUrl,
  },
  alias: {
    minLength: minLengthMsg("Alias must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Alias must be 74 characters or less", 74),
  },
  description: {
    minLength: minLengthMsg(
      "Description must be at least 7 characters long",
      7
    ),
    maxLength: maxLengthMsg("Description must be 500 characters or less", 500),
  },
  tags: {},
  caseSensitive: {},

  prefix: {
    minLength: minLengthMsg("Prefix must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Prefix must be 30 characters or less", 30),
    // required: helpers.withMessage("Prefix is required", (value) => {
    //   return value.trim().length == 0 ? false : true; // !props.isItLink &&
    // }),
  },
  postfix: {
    minLength: minLengthMsg("Postfix must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Postfix must be 299 characters or less", 299),
  },

  // schedule it
  dateTimeRange: [],
  expirationRedirect: {
    maxLength: maxLengthMsg("URL must be 200 characters or less", 200),
    url: validUrl,
  },
  autoDltOnExpire: {},

  // clicks limit
  clicksLimit: {
    numeric,
    minValue: minValue(1),
  },
  clicksLimitMode: {},

  // password
  password: {
    minLength: minLengthMsg("Password must be at least 3 characters long", 3),
  },

  // warnings
  warning: {},

  // permissions
  allowBotClick: {},

  // device target
  deviceTargets: {
    $each: helpers.forEach({
      target_destination_url: {
        required: requiredMsg("Destination URL is required"),
        url: validUrl,
      },
      deviceName: {
        required: requiredMsg("Choose Device Name from the dropdown menu."),
      },
    }),
  },

  // browser target
  browserTargets: {
    $each: helpers.forEach({
      target_destination_url: {
        required: requiredMsg("Destination URL is required"),
        url: validUrl,
      },
      browserName: {
        required: requiredMsg("Choose Browser from the dropdown menu."),
      },
    }),
  },

  // geo target
  geoTargets: {
    $each: helpers.forEach({
      target_destination_url: {
        required: requiredMsg("Destination URL is required"),
        url: validUrl,
      },
      region: {
        required: requiredMsg("Choose region from the dropdown menu."),
      },
    }),
  },
  // tracking pixels
  trackingPixels: {
    $each: helpers.forEach({
      pixelType: {
        required: helpers.withMessage("Pixel type is required", required),
      },
      pixelId: {
        required: requiredMsg("Pixel ID is required"),
        regex: helpers.withMessage("Invalid Pixel ID", (value, parentVm) => {
          return pixelIdRegex(parentVm.pixelType).test(value);
        }),
      },
    }),
  },

  // custom meta data
  metaTitle: {
    maxLength: maxLengthMsg("Meta title must be 60 characters or less", 60),
  },
  metaDescription: {
    maxLength: maxLengthMsg(
      "Meta description must be 160 characters or less",
      160
    ),
  },
  metaImageURL: {
    url: validUrl,
  },

  // UTM Builder
  campaignSource: {},
  campaignMedium: {},
  campaignName: {},
  campaignTerm: {},
  campaignContent: {},

  // YouTube Channel Id
  yt_channel_id: {},
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
