<script setup></script>

<template>
  <div class="bg-gray-200 p-5  mt-10 rounded-lg justify-center text-center">
    <SvgSparkle :height="45" :width="45" :additionalClass="'mb-5 mt-4 m-auto'" />
    <h3 class="font-bold">Upgrade to unlock more premium features</h3>
    <button
      class="rounded-full px-5 py-1 border border-black my-4 font-semibold hover:bg-black hover:text-white"
    >
      Launching Soon
    </button>
  </div>
</template>

<style></style>
