export default defineNuxtPlugin((nuxtApp) => {
  if (nuxtApp.$config.public.environment !== "development") {
    const router = useRouter();

    router.afterEach((to, from) => {
      const amplitude = nuxtApp.$amplitude;
      if (amplitude) {
        amplitude.logEvent("PAGE_VIEW", { path: to.fullPath });
      }
    });
  }
});
