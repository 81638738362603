<script setup>
const jsonSmartlnks = ref([]);
const storedSmartlnks = localStorage.getItem("unauth-smartlnks");

try {
  jsonSmartlnks.value = JSON.parse(storedSmartlnks) || [];
} catch (error) {
  console.error("Error parsing JSON from localStorage:", error);
}

const reversedSmartlnks = computed(() => {
  return [...jsonSmartlnks.value].reverse();
});

const clearLocalStorage = () => {
  localStorage.removeItem("unauth-smartlnks");
  jsonSmartlnks.value = [];
};
</script>
<template>
  <div class="h-full">
    <div v-if="jsonSmartlnks.length">
      <LazySidebarUnauthHistoryLinkCard
        v-for="(smartlnk, index) in reversedSmartlnks"
        :key="index"
        :link="smartlnk.smartlnk"
        :destinationUrl="smartlnk.destination_url"
        :createdOn="smartlnk.created_on"
        :title="smartlnk.title"
      />
    </div>
    <div v-else class="flex flex-col justify-center items-center h-full">
      <SvgoNoLinkFound class="lg:mx-auto text-[18rem] pointer-events-none" />
      <span>No links have been Smartened yet! </span>
    </div>
  </div>
</template>

<style></style>
