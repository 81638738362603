<script setup>
import { storeToRefs } from "pinia";
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore";

const store = useHomeLinkFormStore();

const { customFieldButtons, isCoolDownOn } = storeToRefs(store);
const { setShowForField } = store;
</script>

<template>
  <div class="flex gap-3 mb-8 overflow-auto remove-scroll whitespace-nowrap">
    <div v-for="field in customFieldButtons" :key="field.key">
      <Button
        v-if="!field.show"
        :label="field.label"
        icon="pi pi-plus !text-xs"
        class="!text-sm !bg-gray-100 !text-black !py-2 !px-3 !border-none overflow-visible"
        @click="setShowForField(field.key, true)"
      />
    </div>
  </div>
</template>

<style></style>
