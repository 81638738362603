<script setup>
import { extractMetaData } from "~/utils/extractMetaData.js";

const props = defineProps({
  destinationUrl: {
    type: String,
    required: true,
  },
  smartlink: {
    type: String,
    required: true,
  },
  displayFetchError: {
    type: Boolean,
    default: false,
  },
  displayHorizontalLine: {
    type: Boolean,
    default: true,
  },
});

const metaData = ref();
const fetchError = ref(false);

const url = `https://api.smartlnks.co/v1/proxy/`;
const options = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    url: props.destinationUrl,
  },
};
const { data, error, pending } = await useLazyAsyncData("response", () =>
  $fetch(url, options)
);

watch([data], () => {
  handleResponse();
});

const handleResponse = () => {
  if (error.value) {
    fetchError.value = true;
  } else if (data.value) {
    metaData.value = extractMetaData(data.value);
    fetchError.value = true;
  }
};

const handlePreviewClick = () => {
  window.open(props.smartlink, "_blank");
};
</script>

<template>
  <hr v-if="!displayFetchError && displayHorizontalLine && !pending" class="my-8" />

  <LoadersRingLoader v-if="pending" />

  <div v-if="metaData && Object.keys(metaData).length > 0">
    <CardStaticTwitter
      :link="props.smartlink"
      :linkTitle="metaData?.title"
      :linkDescription="metaData?.description"
      :linkImage="metaData?.image"
      :openInNewTab="true"
    />
  </div>

  <div v-else-if="!pending">
    <div class="flex justify-center">
      <h3 class="font-semibold mt-3 text-red-600">Failed to fetch preview</h3>
    </div>
  </div>

  <h3 class="font-semibold mt-3">
    <!-- Destination URL: -->
    <span class="text-blue-600 cursor-pointer break-all" @click="handlePreviewClick">{{
      destinationUrl
    }}</span>
  </h3>
</template>

<style></style>
