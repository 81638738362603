<script setup>
import { required, helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useTimeAgo } from "@vueuse/core";
import { useResetPasswordFormApi } from "~/composables/useResetPasswordFormApi";

const props = defineProps({
  email: {
    required: false,
  },
});

const form = reactive({
  email: props.email || "",
});

const formRules = {
  email: {
    required: helpers.withMessage("Email is required", required),
    email: helpers.withMessage("Entered value is not a valid email address", email),
  },
};
const formV$ = useVuelidate(formRules, form);

const {
  isSubmitted,
  responseError,
  loading,
  isEmailSent,
  resetPasswordApi,
} = useResetPasswordFormApi(form);
</script>

<template>
  <Dialog
    header="Forgot Your Password?"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    id="forgot-password-dialog"
  >
    <h2 class="text-sm text-gray-600">
      Enter the email address associated with your account and we'll send you a link to
      reset your password.
    </h2>
    <div class="grid grid-cols-1 gap-1">
      <div class="col-span-1 mt-10">
        <!-- success message -->
        <Message v-if="isEmailSent" severity="success" :sticky="true" :closable="false">
          <ul class="list-disc">
            A password recovery instruction has been sent to your email address. Kindly
            check your email to proceed.
          </ul>
        </Message>

        <CustomFieldText
          :Field="formV$.email"
          :isSubmitted="isSubmitted"
          :label="'Email'"
          :inputId="'forgot-password-email-input-field'"
          :placeholder="'Enter email'"
          :responseError="responseError"
          :showResponseError="true"
          @clearResponseError="responseError = null"
        />
        <br />

        <Button
          :label="loading ? 'Please Wait...' : 'Reset Password'"
          class="w-full"
          :loading="loading"
          @click="resetPasswordApi(formV$.$invalid)"
        />
      </div>
    </div>
  </Dialog>
</template>

<style lang="postcss">
#forgot-password-dialog {
  @apply w-[80%] md:!w-[30%];
}
#forgot-password-dialog > .p-dialog-header {
  @apply pb-0;
}
</style>
