<script setup>
import { regionsDict } from "~/utils/regions";
import { v4 as uuidv4 } from "uuid";
import CountryFlag from "vue-country-flag-next";

const props = defineProps({
  geoTarget: {
    type: Object,
  },
  geoTargetForm: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },

  responseError: {
    type: Object,
  },
});

const addRegionField = () => {
  if (props.geoTargetForm.length < 10) {
    props.geoTargetForm.push({
      target_destination_url: null,
      region: null,
      primary_key: "geo",
      uuid: uuidv4(),
    });
  }
};

const removeRegionField = (index) => {
  if (index >= 0 && index < props.geoTargetForm.length) {
    props.geoTargetForm.splice(index, 1);
  }
};

const getAvailableRegions = (index) => {
  return regionsDict.value.filter((region) => {
    return !props.geoTargetForm.some(
      (item, idx) => idx !== index && item.region === region.code
    );
  });
};

const hasInputErrors = (input, index) => {
  const errors = props.geoTarget.$each.$response.$errors[index];
  return (
    errors.target_destination_url?.length ||
    errors.region?.length ||
    (props.responseError?.details?.form_key === "geo_target_destination_url" &&
      props.responseError?.details?.url === input.target_destination_url)
  );
};

const hasRegionErrors = (index) => {
  const errors = props.geoTarget.$each.$response.$errors[index];
  return errors.region?.length;
};

// Retrieve a human-readable error message
const getInputErrorMessage = (input, index) => {
  const errors = props.geoTarget.$each.$response.$errors[index];
  if (errors.target_destination_url?.length) {
    return `Oh, snap! ${errors.target_destination_url[0].$message.replace(
      "Value",
      "Destination URL"
    )}`;
  } else if (errors.region?.length) {
    return `Oh, snap! ${errors.region[0].$message}`;
  }
  return "";
};
</script>
<template>
  <div class="field px-1" v-for="(input, index) in geoTargetForm" :key="input.uuid">
    <div class="flex items-center justify-between">
      <label
        :for="`region-target-url-input-field-${index}`"
        class="block mb-2 text-sm font-medium"
        :class="{ 'text-red-700 dark:text-red-500': hasInputErrors(input, index) }"
      >
        Target Region {{ index + 1 }}
      </label>

      <Button
        outlined
        rounded
        aria-label="remove"
        class="!border-red-500 !bg-transparent !p-1"
        @click="removeRegionField(index)"
      >
        <i class="pi pi-times text-xs text-red-500 font-semibold"></i>
      </Button>
    </div>

    <!-- url input field -->
    <div class="flex justify-between">
      <span class="p-input-icon-right w-[70%] mr-2">
        <i
          v-if="hasInputErrors(input, index)"
          class="pi pi-exclamation-triangle !text-red-500"
        />
        <input
          v-model.trim="input.target_destination_url"
          type="url"
          :id="'region-target-url-input-field-' + index"
          class="input-field"
          :class="{
            'input-error': hasInputErrors(input, index),
          }"
          placeholder="Enter a destination url here"
          :aria-describedby="'region-target-url-input-field-' + index"
        />
      </span>

      <!-- region dropdown -->
      <Dropdown
        :id="`region-target-region-dropdown-${index}`"
        class="p-inputgroup-addon region-target-region-dropdown !p-0 w-[30%]"
        :class="{
          'input-error': hasRegionErrors(index),
        }"
        :inputClass="{
          'input-error': hasRegionErrors(index),
        }"
        v-model="input.region"
        :options="getAvailableRegions(index)"
        :filter="true"
        optionLabel="name"
        optionValue="code"
        placeholder="Select a region"
      >
        <template #option="slotProps">
          <div class="flex items-center gap-x-3">
            <div>
              <country-flag
                :country="slotProps?.option?.code"
                :rounded="false"
                size="normal"
              />
            </div>
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Dropdown>
    </div>

    <!-- help text -->
    <small :id="`region-target-url-input-field-${index}-help`" class="mb-1 block"
      >Please include 'https://' or 'http://' in the link.</small
    >

    <!-- errors -->
    <p v-if="hasInputErrors(input, index)" class="input-error-message">
      {{ getInputErrorMessage(input, index) }}
    </p>

    <br />
  </div>

  <!-- add region -->
  <Button
    v-if="geoTargetForm?.length < 10"
    @click="addRegionField()"
    label="Add Region"
    icon="pi pi-plus"
    class="p-button-sm !m-2 !mb-10 !p-2"
  />
  <br />
</template>

<style lang="postcss">
.region-target-region-dropdown.p-dropdown:not(.p-disabled).p-focus {
  @apply !border-[#d4d4d8] shadow-none;
}
#remove-region-btn {
  @apply !border-white !bg-white;
}

.input-error {
  @apply border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500;
}

.input-field {
  @apply block p-3 bg-gray-50 border sm:text-base rounded-lg dark:bg-gray-700 w-full;
}

.input-error-message {
  @apply mt-2 text-sm text-red-600 dark:text-red-500;
}
</style>
