<script setup>
import { storeToRefs } from "pinia";
import { useBulkLinkCreationFormStore } from "~/store/useBulkLinkCreationFormStore.js";

const { activeTab } = storeToRefs(useBulkLinkCreationFormStore());

const items = ref([
  { label: "Text", icon: "pi pi-pencil" },
  //   { label: "CSV", icon: "pi pi-file-import" },
  //   { label: "Exel", icon: "pi pi-file-excel" },
]);
</script>

<template>
  <div>
    <TabMenu v-model:activeIndex="activeTab" :model="items" />
  </div>
</template>

<style></style>
