import moment from "moment";

export const formatDateTime = (datetime) => {
  return moment(datetime).format("MMM Do YYYY, h:mm:ss a");
};

export const formatRelativeTime = (start, end) => {
  const duration = moment.duration(end.diff(start));

  const durationMinutes = duration.asMinutes();
  const durationHours = duration.asHours();
  const durationDays = duration.asDays();
  const durationWeeks = duration.asWeeks();
  const durationMonths = duration.asMonths();
  const durationYears = duration.asYears();

  if (durationMinutes < 1) {
    return "Just now";
  } else if (durationMinutes < 60) {
    return `${Math.round(durationMinutes)} minutes ago`;
  } else if (durationHours <= 24) {
    return `${Math.round(durationHours)} hours ago`;
  } else if (durationDays < 7) {
    return `${Math.round(durationDays)} days ago`;
  } else if (durationWeeks < 4) {
    return `${Math.round(durationWeeks)} weeks ago`;
  } else if (durationMonths < 12) {
    return `${Math.round(durationMonths)} months ago`;
  } else {
    return `${Math.round(durationYears)} years ago`;
  }
};

export const calculateReadingTime = (text) => {
  const wordsPerMinute = 200; // Adjust this value as needed
  const wordCount = text?.trim().split(/\s+/).length;
  const readingTimeMinutes = wordCount / wordsPerMinute;
  return Math.ceil(readingTimeMinutes);
};

export const humanReadableRange = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const now = moment();

  // if (end.isSame(now, "day")) {
  //   const duration = moment.duration(now.diff(start));
  //   return `Last ${duration.humanize()}`;
  // }

  if (start.year() === end.year()) {
    return `${start.format("D MMM")} - ${end.format("D MMM")}`;
  } else {
    return `${start.format("D MMM YYYY")} - ${end.format("D MMM YYYY")}`;
  }
};
