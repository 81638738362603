<script setup>
import { useBulkLinkCreationApi } from "~/composables/useBulkLinkCreationApi.js";

const { loading, createBulkLink } = useBulkLinkCreationApi();
</script>

<template>
  <Button
    label="Make 'Em Smart!"
    icon="pi pi-check"
    class="!bg-primary !py-2 w-full"
    @click="createBulkLink()"
    :loading="loading"
    autofocus
  />
</template>

<style></style>
