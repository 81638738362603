<script setup></script>

<template>
  <Dialog
    header="Privacy Policy"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    :maximizable="true"
    id="privacy-policy-dialog"
  >
    <div>
      <ContentPrivacyPolicy />
    </div>
  </Dialog>
</template>

<style lang="postcss">
#privacy-policy-dialog {
  @apply w-[80%] md:!w-[50%];
}
</style>
