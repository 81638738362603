import { maxLength, required, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// Common Validators
const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));

const defaultFormValues = () => ({
  id: 0,
  name: "",
  description: "",
  updatedOn: "",
});

export const form = reactive(defaultFormValues());

export const formRules = {
  name: {
    required: requiredMsg("Collection name is required"),
    minLength: minLengthMsg(
      "Collection name must be at least 3 characters long",
      3
    ),
    maxLength: maxLengthMsg(
      "Collection name must be 30 characters or less",
      30
    ),
  },
  description: {
    minLength: minLengthMsg(
      "Description must be at least 7 characters long",
      7
    ),
    maxLength: maxLengthMsg("Description must be 255 characters or less", 255),
  },
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
