<script setup>
import { formV$ } from "~/validations/linkFormRules";
import { useLinkFormApi } from "~/composables/link-form/useLinkFormApi";
import {
  showPrivacyPolicyDialog,
  showTermsOfServiceDialog,
} from "~/utils/dialogActions.js";

const linkId = inject("link-form-link-id");
const isDuplicate = inject("link-form-is-duplicate");
const { submitForm, loading } = useLinkFormApi();
</script>
<template>
  <div class="h-[10%] lg:flex lg:items-end">
    <div class="w-full">
      <Button
        :label="linkId && !isDuplicate ? 'Update' : 'Create'"
        class="p-button-sm !px-7 w-full"
        :class="linkId && !isDuplicate ? 'p-button-info' : 'p-button-success'"
        :loading="loading"
        icon="pi pi-arrow-right"
        iconPos="right"
        @click="submitForm(formV$.$invalid, linkId, isDuplicate)"
        autofocus
      />
      <CustomAgreementText
        :text="`By clicking '${
          linkId ? 'Update' : 'Create'
        }', you are agreeing to Smartlnks`"
      />
    </div>
  </div>
</template>

<style></style>
