export const linksTableUtilityApiService = () => {
  if (process.server) return;

  const apis = new APIS();
  const { sendGetRequest } = useSendRequest();

  const getConfig = (headerValue, collectionId, linkType) => ({
    headers: headerValue,
    responseType: "json",
    params: {
      collection_id: collectionId,
      link_type: linkType,
    },
  });

  const fetchData = async (endpoint, config) => {
    try {
      const url = `${apis.BASE_URL}${endpoint}`;
      const response = await sendGetRequest(url, config);
      if (response?.status !== 200) {
        throw new Error(response?.data?.message || "API request failed");
      }
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "API request failed");
    }
  };

  const fetchTagsData = async (headers) =>
    fetchData(apis.USER_COLLECTION_TAGS, headers);
  const fetchPrefixData = async (headers) =>
    fetchData(apis.USER_COLLECTION_PREFIXES, headers);
  const fetchDomainsData = async (headers) =>
    fetchData(apis.USER_VERIFIED_DOMAINS, headers);

  return { getConfig, fetchTagsData, fetchDomainsData, fetchPrefixData };
};
