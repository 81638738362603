<script setup>
import { copyTextToClipboard } from "~/utils/copyTextToClipboard";

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  quote: {
    type: String,
    required: true,
  },
  hashtags: {
    type: String,
    required: true,
  },
  showOverflowBar: {
    type: Boolean,
    default: false,
  },
});

const sharing = ref({
  url: props.link,
  title: props.title,
  description: props.description,
  quote: props.quote,
  hashtags: props.hashtags,
});

const networks = [
  {
    network: "facebook",
    name: "Facebook",
    icon: "fab fah fa-lg fa-facebook-f",
    color: "#1877f2",
    label: "Facebook",
  },
  {
    network: "twitter",
    name: "Twitter",
    icon: "fab fah fa-lg fa-x-twitter",
    color: "#000000",
    label: "Twitter",
  },
  {
    network: "linkedin",
    name: "LinkedIn",
    icon: "fab fah fa-lg fa-linkedin",
    color: "#007bb5",
    label: "LinkedIn",
  },
  {
    network: "whatsapp",
    name: "Whatsapp",
    icon: "fab fah fa-lg fa-whatsapp",
    color: "#25d366",
    label: "Whatsapp",
  },
  {
    network: "email",
    name: "Email",
    icon: "far fah fa-lg fa-envelope",
    color: "#333333",
    label: "Email",
  },
  {
    network: "pinterest",
    name: "Pinterest",
    icon: "fab fah fa-lg fa-pinterest",
    color: "#bd081c",
    label: "Pinterest",
  },
  {
    network: "skype",
    name: "Skype",
    icon: "fab fah fa-lg fa-skype",
    color: "#00aff0",
    label: "Skype",
  },
  {
    network: "telegram",
    name: "Telegram",
    icon: "fab fah fa-lg fa-telegram-plane",
    color: "#0088cc",
    label: "Telegram",
  },
];
</script>
<template>
  <div id="share-link-row">
    <h2>Share via:</h2>
    <div
      class="flex overflow-x-auto overflow-y-hidden mt-4 mx-2 pb-1.5 h-min relative"
      :class="{ 'remove-x-scroll': !showOverflowBar }"
    >
      <a
        class="mr-3.5 bg-white"
        v-tooltip.bottom="{
          value: 'Visit',
          class: 'extra-small-3xl-tooltip',
        }"
        :href="link"
        target="_blank"
      >
        <i class="pi pi-external-link p-5 border rounded-full"></i>
      </a>

      <button
        class="mr-3.5 bg-white"
        v-tooltip.bottom="{
          value: 'Copy',
          class: 'extra-small-3xl-tooltip',
        }"
        @click="copyTextToClipboard({ url: link })"
      >
        <i class="pi pi-copy p-[1.1rem] border rounded-full !text-[1.3rem]"></i>
      </button>

      <SocialShare
        v-for="network in networks"
        :key="network.network"
        :network="network.network"
        :url="sharing.url"
        :title="sharing.title"
        :hashtags="sharing.hashtags"
        :styled="true"
        :label="false"
        class="!rounded-full justify-center mr-3.5 !min-w-14 !h-14"
        v-tooltip.bottom="{
          value: network.label,
          class: 'extra-small-3xl-tooltip',
        }"
      />
    </div>
  </div>
</template>

<style lang="postcss"></style>
