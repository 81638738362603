<script setup>
defineProps({
  Field: {
    type: Object,
  },
  label: {
    type: String,
  },
  inputId: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  isSubmitted: {
    type: Boolean,
  },
  responseError: {
    type: Object,
  },
});
const emit = defineEmits(["clearResponseError"]);
function clearResponseError() {
  emit("clearResponseError");
}
</script>

<template>
  <div class="field">
    <label
      :for="inputId"
      class="block mb-2 text-sm font-medium"
      :class="{
        'text-red-700 dark:text-red-500': Field.$invalid && isSubmitted,
      }"
      >{{ label }}</label
    >
    <div class="p-inputgroup">
      <Password
        v-model.trim="Field.$model"
        @keyup="clearResponseError()"
        :id="inputId"
        toggleMask
        :placeholder="placeholder"
        :inputClass="
          Field.$invalid && isSubmitted
            ? '!border-red-500 !text-red-900 !placeholder-red-700 focus:!ring-red-500 focus:!border-red-500 dark:!text-red-500 dark:!placeholder-red-500 dark:!border-red-500'
            : 'block w-full p-3 !bg-gray-50 !border sm:!text-md !rounded-lg dark:!bg-gray-700 focus:!border-[#d4d4d8]'
        "
      >
        <template #footer="sp">
          <Divider />
          <p class="mt-2">Suggestions</p>
          <ul class="pl-2 ml-2 mt-0 list-disc" style="line-height: 1.5">
            <li>At least one lowercase</li>
            <li>At least one uppercase</li>
            <li>At least one numeric</li>
            <li>Minimum 8 characters</li>
          </ul>
        </template>
      </Password>
    </div>
    <div
      v-if="Field.$error && isSubmitted"
      class="input-errors"
      v-for="error of Field.$errors"
      :key="error.$uid"
    >
      <p class="mt-2 text-sm text-red-600 dark:text-red-500">
        <span class="font-medium">Oh, snapp!</span>
        {{ error.$message.replace("This field", "Password") }}.
      </p>
    </div>
    <p
      v-else-if="(Field.$invalid && isSubmitted) || (Field.$pending && isSubmitted)"
      class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">Oh, snapp!</span>
      {{ Field.required.$message }}.
    </p>
    <p
      v-else-if="responseError"
      id="current-password-input-error"
      class="input-errors mt-2 text-sm text-red-600 dark:text-red-500"
    >
      <span class="font-medium">Oh, snapp!</span>
      {{ responseError }}
    </p>
  </div>
  <br />
</template>

<style></style>
