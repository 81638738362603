<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted, responseError } = storeToRefs(useLinkFormStore());
</script>
<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- responseError -->
    <Message
      v-if="responseError?.details?.tab_key === 'device-targets' && responseError?.message"
      severity="error"
      :sticky="true"
      :closable="false"
      >{{ responseError?.message }}</Message
    >

    <!-- Dynamic Device Target Field -->
    <CustomFieldDeviceTargets
      :isSubmitted="isSubmitted"
      :deviceTarget="formV$.deviceTargets"
      :deviceTargetForm="form.deviceTargets"
      :responseError="responseError"
    />
  </div>
</template>

<style></style>
