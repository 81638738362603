import { form } from "~/validations/collectionFormRules.js";

export const useCollectionForm = ({ selectedCollection }) => {
  // Initialize form data if selectedCollection is provided
  console.log("==> Collection Form Data Initialization");
  watch(
    () => selectedCollection,
    (newSelectedCollection) => {
      Object.assign(form, newSelectedCollection);
    },
    { immediate: true }
  );
};
