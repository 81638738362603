import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  analytics: () => import("/opt/buildhome/repo/middleware/analytics.js"),
  auth: () => import("/opt/buildhome/repo/middleware/auth.js"),
  "change-password": () => import("/opt/buildhome/repo/middleware/change-password.js"),
  dashboard: () => import("/opt/buildhome/repo/middleware/dashboard.js"),
  "get-started": () => import("/opt/buildhome/repo/middleware/get-started.js"),
  overview: () => import("/opt/buildhome/repo/middleware/overview.js"),
  "remove-token": () => import("/opt/buildhome/repo/middleware/remove-token.js"),
  settings: () => import("/opt/buildhome/repo/middleware/settings.js"),
  "update-userinfo": () => import("/opt/buildhome/repo/middleware/update-userinfo.js")
}