import { defineStore } from "pinia";

export const useSidebarsStore = defineStore("SidebarsStore", () => {
  if (process.server) return;

  const sidebars = reactive({
    unauthHistory: {
      show: false,
      callback: null,
    },
    analyticsSidebar: {
      show: false,
      linkId: null,
      callback: null,
    },
  });

  const handleSidebarClose = (sidebarType, payload = {}) => {
    console.log("==> handleSidebarClose for ", sidebarType);
    console.log("==> payload: ", payload);
    if (payload?.refresh && sidebars[sidebarType].callback) {
      sidebars[sidebarType].callback();
      sidebars[sidebarType].callback = null; // Clear the callback
    }
    sidebars[sidebarType].show = false;
  };

  return { sidebars, handleSidebarClose };
});
