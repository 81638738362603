import {
  maxLength,
  required,
  minLength,
  email,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));
const validEmail = helpers.withMessage(
  "Entered value is not a valid email address",
  email
);

const defaultFormValues = () => ({
  name: "",
  email: "",
  message: "",
});

export const form = reactive(defaultFormValues());

export const formRules = {
  name: {
    required: requiredMsg("Please enter a name"),
    minLength: minLengthMsg("Name must be at least 3 characters", 3),
  },
  email: {
    required: requiredMsg("Email is required"),
    email: validEmail,
  },
  message: {
    required: requiredMsg("Message is required"),
    minLength: minLengthMsg("Message must be atleast 7 characters", 7),
  },
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
