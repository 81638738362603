<script setup>
defineProps({
  warningField: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
});

const warningOptions = ref([
  { label: "None", value: "NONE" },
  { label: "Age Restricted", value: "AGE_RESTRICTED" },
  { label: "Blacklist", value: "BLACKLIST" },
  { label: "Sensitive Content", value: "SENSITIVE_CONTENT" },
  { label: "Malicious", value: "MALICIOUS" },
  { label: "Not Trusted", value: "NOT_TRUSTED" },
  { label: "Mature Content", value: "MATURE_CONTENT" },
]);
</script>

<template>
  <div class="field">
    <label for="destination-url" class="block mb-4 text-md font-semibold"
      >Warning Options</label
    >
    <div class="ml-1">
      <div
        v-for="option of warningOptions"
        :key="option.value"
        class="field-radiobutton flex items-center mb-2.5"
      >
        <RadioButton
          :inputId="option.value"
          name="option"
          :value="option.value"
          v-model="warningField.$model"
        />
        <label class="ml-2 font-medium text-sm" :for="option.value">{{
          option.label
        }}</label>
      </div>
    </div>
  </div>
  <br />
</template>

<style></style>
