<script setup>
import { form, formV$ } from "~/validations/homeLinkFormRules";
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore";
import { storeToRefs } from "pinia";

const homeLinkFormStore = useHomeLinkFormStore();
const { isSubmitted, responseError, customFieldButtons } = storeToRefs(homeLinkFormStore);
const { clearResponseError } = homeLinkFormStore;

const prefixError = computed(() => {
  return (responseError.value?.details?.tab_key === "basic" &&
    responseError.value?.details?.form_key === "prefix") ||
    responseError.value?.details?.form_key === "postfix"
    ? responseError.value?.message
    : undefined;
});

const showTitleField = computed(() => {
  const titleField = customFieldButtons.value.find((field) => field.key === "title");
  return titleField ? titleField.show : false;
});

const showPasswordField = computed(() => {
  const passwordField = customFieldButtons.value.find(
    (field) => field.key === "set-password"
  );
  return passwordField ? passwordField.show : false;
});

const showScheduleItField = computed(() => {
  const scheduleItField = customFieldButtons.value.find(
    (field) => field.key === "schedule-it"
  );
  return scheduleItField ? scheduleItField.show : false;
});
</script>
<template>
  <div class="mt-8">
    <Message v-if="prefixError" severity="error" :sticky="true" :closable="false">{{
      prefixError
    }}</Message>

    <DialogCustomLinkOptionsCustomFieldsBtns />

    <!-- Title Field -->
    <CustomFieldText
      v-if="showTitleField"
      :Field="formV$.title"
      :label="'Title (Recommended)'"
      :inputId="'title-input-field'"
      :placeholder="'Enter title here'"
      :isSubmitted="isSubmitted"
    />

    <CustomFieldSmartdomain
      :form="form"
      :formV$="formV$"
      :isSubmitted="isSubmitted"
      :responseError="prefixError"
      :clearResponseError="clearResponseError"
    />

    <!-- Date Time Range Field -->
    <CustomFieldDatetimeRange
      v-if="showScheduleItField"
      :DateTimeRangeField="formV$.dateTimeRange"
    />

    <!-- Password Field -->
    <CustomFieldPassword
      v-if="showPasswordField"
      :Field="formV$.password"
      :isSubmitted="isSubmitted"
      :label="'Password'"
      :inputId="'password-input-field'"
      :placeholder="'Enter Password'"
    />
  </div>
</template>
<style></style>
