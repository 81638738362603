<script setup>
import { notifyError } from "~/utils/notifications";
import QRCodeVue3 from "qrcode-vue3";
import { saveAs } from "file-saver";
import { uploadQRLogo } from "~/services/uploadQRLogo.js";

const { providedUrl } = defineProps({
  providedUrl: {
    type: String,
    required: true,
  },
});

const qrComponentKey = ref(0);
const logo = ref("/logo.png");
const loading = ref(false);
const error = ref(null);
const data = ref(null);
const files = ref([]);

const forceRerender = () => qrComponentKey.value++;

const handleOptionChange = (options, type, color) => {
  options.color = color?.startsWith("#") ? color : "#" + color;
  forceRerender();
};

// QR Options Grouping
const qrOptions = reactive({
  // ====================== Dots Options ======================
  dotsOptions: {
    type: "dots",
    color: "#000000",
    availableTypes: [
      { styleName: "Dots", styleCode: "dots" },
      { styleName: "Rounded", styleCode: "rounded" },
      { styleName: "Classy", styleCode: "classy" },
      { styleName: "Classy Rounded", styleCode: "classy-rounded" },
      { styleName: "Square", styleCode: "square" },
      { styleName: "Extra Rounded", styleCode: "extra-rounded" },
    ],
    handleChange: () =>
      handleOptionChange(
        qrOptions.dotsOptions,
        qrOptions.dotsOptions.type,
        qrOptions.dotsOptions.color
      ),
  },
  // ====================== Corners Square Options ======================
  cornersSquareOptions: {
    type: "extra-rounded",
    color: "#fc0100",
    availableTypes: [
      { styleName: "Dots", styleCode: "dots" },
      { styleName: "Square", styleCode: "square" },
      { styleName: "Extra Rounded", styleCode: "extra-rounded" },
    ],
    handleChange: () =>
      handleOptionChange(
        qrOptions.cornersSquareOptions,
        qrOptions.cornersSquareOptions.type,
        qrOptions.cornersSquareOptions.color
      ),
  },
  // ====================== Corners Dot Options ======================
  cornersDotsOptions: {
    type: null,
    color: "#000000",
    availableTypes: [
      { styleName: "Dots", styleCode: "dots" },
      { styleName: "Square", styleCode: "square" },
    ],
    handleChange: () =>
      handleOptionChange(
        qrOptions.cornersDotsOptions,
        qrOptions.cornersDotsOptions.type,
        qrOptions.cornersDotsOptions.color
      ),
  },
  // ====================== Background Color change ======================
  backgroundOptions: {
    color: "#ffffff",
    handleChange: () =>
      handleOptionChange(
        qrOptions.backgroundOptions,
        null,
        qrOptions.backgroundOptions.color
      ),
  },
});

// ====================== Download QRCode ======================
const downloadQR = () => {
  var imgSrc = document.getElementsByClassName("smartlnk-img-qr")[0].getAttribute("src");

  if (imgSrc != null) {
    var fileName = providedUrl.replace(".", "_") + "-QRCode.jpg";
    saveAs(imgSrc, fileName.slice(8));
  }
};

function onQRLogoSelect(event) {
  files.value = event.files;
  files.value.forEach((file) => {
    const name = file.name;
    let reader = new FileReader();
    reader.onload = (e) => {
      handleUpload(e.target?.result, file.name);
    };
    reader.readAsDataURL(file);
  });
}

const handleUpload = async (imageURL, imageName) => {
  loading.value = true;
  error.value = null;
  console.log("==> came here");
  try {
    const response = await uploadQRLogo(imageURL, imageName);
    handleResponse(response);
  } catch (error) {
    notifyResponseError(error);
  } finally {
    loading.value = false;
  }
};

const handleResponse = (response) => {
  if (response?.status === 200) {
    data.value = response.data;
    logo.value = data.value?.url;
    forceRerender();
  } else {
    notifyResponseError(response);
  }
};

const notifyResponseError = (err) => {
  error.value = err;
  notifyError({
    title: "Failed to upload qr logo!!",
    text:
      "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
  });
};
</script>

<template>
  <Dialog
    :header="'QR Code'"
    :visible="true"
    :closable="false"
    :draggable="false"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
  >
    <div class="grid grid-cols-5 gap-10 select-none">
      <div v-if="loading" class="loading-overlay">
        <LoadersRingLoader />
      </div>
      <div class="col-span-5 md:col-span-2 h-[300px] w-[300px] border rounded-3xl">
        <QRCodeVue3
          :key="qrComponentKey"
          :value="providedUrl"
          :qrOptions="{
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'H',
          }"
          :image="logo"
          :imageOptions="{
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 3,
            crossOrigin: 'anonymous',
          }"
          :dotsOptions="{
            type: qrOptions.dotsOptions.type,
            color: qrOptions.dotsOptions.color,
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: qrOptions.dotsOptions.color },
                { offset: 1, color: qrOptions.dotsOptions.color },
              ],
            },
          }"
          :backgroundOptions="{ color: qrOptions.backgroundOptions.color }"
          :cornersSquareOptions="{
            type: qrOptions.cornersSquareOptions.type,
            color: qrOptions.cornersSquareOptions.color,
          }"
          :cornersDotOptions="{
            type: qrOptions.cornersDotsOptions.type,
            color: qrOptions.cornersDotsOptions.color,
          }"
          fileExt="png"
          :download="false"
          myclass="smartlnk-qr p-4"
          imgclass="smartlnk-img-qr"
          downloadButton="smartlnk-qr-download-button"
          :downloadOptions="{ name: 'vqr', extension: 'png' }"
        />
      </div>
      <div class="col-span-5 md:col-span-3">
        <!-- QR Dots Option -->
        <section title="Dots Option">
          <header class="font-semibold mx-4">Dots Options</header>
          <div class="flex justify-start">
            <div class="m-4">
              <p class="font-medium">Dots Style</p>
              <Dropdown
                aria-label="Select Dots Style"
                :selectOnFocus="true"
                v-model="qrOptions.dotsOptions.type"
                :options="qrOptions.dotsOptions.availableTypes"
                optionLabel="styleName"
                optionValue="styleCode"
                placeholder="Select any style"
                @change="qrOptions.dotsOptions.handleChange"
              />
            </div>
            <div class="m-4">
              <p class="font-medium">Dot Color</p>
              <ColorPicker
                defaultColor="#000000"
                @change="qrOptions.dotsOptions.handleChange"
                v-model="qrOptions.dotsOptions.color"
              />
            </div>
          </div>
        </section>

        <!-- Background Color -->
        <hr class="mx-5 mb-3 dark:border-slate-50" />
        <section class="my-4">
          <header class="font-semibold m-4">Background Color</header>
          <div class="flex justify-start mx-4">
            <ColorPicker
              defaultColor="#ffffff"
              @change="qrOptions.backgroundOptions.handleChange"
              v-model="qrOptions.backgroundOptions.color"
            />
          </div>
        </section>

        <!-- logo upload -->
        <hr class="mx-5 mb-3 dark:border-slate-50" />
        <section>
          <header class="font-semibold mx-4 mb-1">Custom Logo</header>
          <FileUpload
            id="qr-logo-upload"
            mode="basic"
            accept=".jpg, .png, .jpeg"
            :maxFileSize="2000000"
            class="!text-sm !py-1.5 !px-3"
            @select="onQRLogoSelect"
            auto
            :disabled="isUpload"
            :customUpload="true"
          />
        </section>
      </div>

      <section class="col-span-5 md:col-span-2">
        <hr class="mx-5 mb-3 dark:border-slate-50" />

        <!-- Corners Square Options -->
        <header class="font-semibold mx-4">Corners Square Options</header>
        <div class="flex justify-start">
          <div class="m-4">
            <p class="font-medium">Corners Square Style</p>

            <Dropdown
              :selectOnFocus="true"
              v-model="qrOptions.cornersSquareOptions.type"
              :options="qrOptions.cornersSquareOptions.availableTypes"
              optionLabel="styleName"
              optionValue="styleCode"
              placeholder="Select any style"
              @change="qrOptions.cornersSquareOptions.handleChange"
            />
          </div>

          <!-- Square Color -->
          <div class="m-4">
            <p class="font-medium">Square Color</p>
            <ColorPicker
              defaultColor="#fc0100"
              @change="qrOptions.cornersSquareOptions.handleChange"
              v-model="qrOptions.cornersSquareOptions.color"
            />
          </div>
        </div>
      </section>

      <div class="col-span-5 md:col-span-3">
        <hr class="mx-5 mb-3 dark:border-slate-50" />

        <!-- Corners Dots Options -->
        <section>
          <header class="font-semibold mx-4">Corners Dots Options</header>
          <div class="flex justify-start">
            <div class="m-4">
              <p class="font-medium">Corners Dot Style</p>
              <Dropdown
                :selectOnFocus="true"
                v-model="qrOptions.cornersDotsOptions.type"
                :options="qrOptions.cornersDotsOptions.availableTypes"
                optionLabel="styleName"
                optionValue="styleCode"
                placeholder="Select any style"
                @change="qrOptions.cornersDotsOptions.handleChange"
              />
            </div>

            <div class="m-4">
              <p class="font-medium">Corners Dot Color</p>
              <ColorPicker
                defaultColor="#000000"
                @change="qrOptions.cornersDotsOptions.handleChange"
                v-model="qrOptions.cornersDotsOptions.color"
              />
            </div>
          </div>
        </section>
      </div>
    </div>

    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        @click="$emit('close')"
        class="p-button-text p-button-sm"
        id="close-qr-dialog-btn"
      />
      <Button
        label="Download"
        icon="pi pi-download"
        class="p-button-success p-button-sm"
        @click="downloadQR"
        autofocus
      />
    </template>
  </Dialog>
</template>

<style lang="postcss">
#qr-logo-upload > .p-button > .p-button-icon {
  @apply !text-sm;
}
#qr-logo-upload {
  @apply mx-4;
}
#close-qr-dialog-btn {
  @apply !bg-transparent !border-transparent !text-primary;
}
</style>
