import { storeToRefs } from "pinia";
import { linksApiService } from "~/services/linksApiService.js";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";
import { showLinkCreatedDialog } from "~/utils/dialogActions";
import { createLinkFormRequestBody, mapResponseToForm } from "~/utils/linkFormUtils";
import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { form } from "~/validations/linkFormRules";
import { useLinkFormPanelMenuStore } from "~/store/useLinkFormPanelMenuStore";

const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NOT_ACCEPTABLE: 406,
  IM_USED: 226,
};

export const useLinkFormApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  // injects
  const collectionId = inject("collection-id");
  const linkType = inject("link-form-link-type");
  const closeDialog = inject("close-link-form-dialog");

  // store
  const linkFormStore = useLinkFormStore();
  const { responseError, isSubmitted } = storeToRefs(linkFormStore);
  const { clearResponseError } = linkFormStore;
  const { setActiveItem } = useLinkFormPanelMenuStore();

  // api calls
  const { fetchLinkData, createLink, updateLink } = linksApiService();

  const handleApiResponse = (response) => {
    switch (response?.status) {
      case STATUS_CODES.OK:
      case STATUS_CODES.CREATED:
        notifySuccess(response.data?.data?.message);
        showLinkCreatedDialog({
          link: response.data?.data?.link,
        });
        closeDialog({ refresh: true });
        break;
      case STATUS_CODES.NOT_ACCEPTABLE:
      case STATUS_CODES.IM_USED:
        responseError.value = response.data;
        console.log(responseError.value);
        break;
      default:
        error.value = response?.data;
        notifyError();
        closeDialog();
        break;
    }
  };

  const initializeForm = async (linkId) => {
    if (!linkId) return;

    loading.value = true;
    try {
      const response = await fetchLinkData({ smartlnk_id: linkId });
      const formDataFromResponse = mapResponseToForm(response.data?.data);
      Object.assign(form, formDataFromResponse);
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const submitForm = async (isFormValid, linkId, isDuplicate) => {
    isSubmitted.value = true;
    console.log("==> Submit Form: ", isFormValid);

    if (isFormValid) {
      if (form.destinationURL.length < 1) {
        setActiveItem("basic");
      }

      notifyError({
        title: "Invalid Form!",
        text:
          "It appears that there may be errors in the form. Please review your entries and ensure that all required information is accurate and properly filled before submitting.",
      });

      return;
    } else if (isPublicDomainUrl(form.destinationURL)) {
      const errorMessage = "This URL has already been smartened. Please provide a new URL to smarten.";
      notifyError({ title: errorMessage, text: "" });
      return;
    }

    loading.value = true;
    clearResponseError();

    const requestBody = createLinkFormRequestBody(form, collectionId, linkType);

    if (linkId && !isDuplicate) updateLinkApi(requestBody, linkId);
    else createLinkApi(requestBody);
  };

  const updateLinkApi = async (requestBody, linkId) => {
    try {
      const response = await updateLink({
        updatedData: requestBody,
        smartlnk_id: linkId,
      });
      handleApiResponse(response);
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const createLinkApi = async (requestBody) => {
    try {
      const response = await createLink(requestBody);
      handleApiResponse(response);
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  return {
    data,
    error,
    loading,
    initializeForm,
    submitForm,
  };
};
