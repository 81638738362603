<script setup>
defineProps({
  clicksLimitMode: {
    type: Object,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
});
const clicksLimitModes = ref([
  { label: "Any", value: "ANY" },
  { label: "Unique", value: "UNIQUE" },
  { label: "Non Unique", value: "NON_UNIQUE" },
]);
</script>

<template>
  <div class="field">
    <label for="clicks-limit-mode-input" class="block mb-2 text-sm font-medium"
      >Clicks Limit Mode</label
    >
    <SelectButton
      v-model="clicksLimitMode.$model"
      :options="clicksLimitModes"
      :unselectable="false"
      optionLabel="label"
      dataKey="value"
      aria-labelledby="clicks-limit-mode-input"
      id="clicks-limit-mode-input"
      class="!shadow-none"
    >
      <template #option="slotProps">
        <span>{{ slotProps.option.label }}</span>
      </template>
    </SelectButton>
  </div>
  <br />
</template>

<style lang="postcss">
#clicks-limit-mode-input > div {
  @apply py-1.5 px-3 font-medium text-sm;
}
#clicks-limit-mode-input > div.p-highlight {
  @apply font-bold !bg-[#0046d5] !border-[#0046d5] !text-white;
}
#clicks-limit-mode-input > .p-button {
  @apply !border-gray-300 !bg-white;
}

#clicks-limit-mode-input > div.p-highlight {
  border-style: inherit !important;
}
</style>
