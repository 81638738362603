<script setup></script>

<template #header>
  <div class="flex">
    <p class="text-sm text-gray-600">
      Automatically tweet your latest YouTube videos. Just use your permanent YouTube
      smartlnk. Need a new one?
      <NuxtLink class="font-semibold text-primary" to="/smartytlink" target="_blank">
        Create it here</NuxtLink>.
    </p>
  </div>
</template>

<style></style>
