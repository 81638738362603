export const mapResponseToForm = (response) => {
  const {
    url_information: urlInfo,
    device_targets: deviceTargets,
    browser_targets: browserTargets,
    geo_targets: geoTargets,
    pixels: trackingPixels,
  } = response;

  const dateTimeRange = [
    ...(urlInfo.start_datetime ? [new Date(urlInfo.start_datetime)] : []),
    ...(urlInfo.end_datetime ? [new Date(urlInfo.end_datetime)] : []),
  ];

  const mapDeviceTargets = (target) => ({
    target_destination_url: target.web_link,
    deviceName: target.device,
    primary_key: "device",
  });

  const mapBrowserTargets = (target) => ({
    target_destination_url: target.web_link,
    browserName: target.browser,
    primary_key: "browser",
  });

  const mapGeoTargets = (target) => ({
    target_destination_url: target.web_link,
    region: target.country_code,
    primary_key: "geo",
  });

  const mapTrackingPixels = (pixel) => ({
    pixelId: pixel.tracking_code,
    pixelType: pixel.name,
  });

  return {
    id: urlInfo.id,
    updatedOn: urlInfo.updated_on,
    domain: urlInfo.domain,
    title: urlInfo.title,
    destinationURL: urlInfo.destination_url,
    alias: urlInfo.smartlnk.split("/").pop(),
    description: urlInfo.description,
    tags: urlInfo.tags || [],
    caseSensitive: true, // This seems to be a default value
    prefix: urlInfo.prefix,
    postfix: urlInfo.postfix,
    dateTimeRange: dateTimeRange,
    expirationRedirect: urlInfo.expiration_redirect,
    autoDltOnExpire: urlInfo.auto_delete_on_expire,
    clicksLimit: urlInfo.clicks_limit,
    clicksLimitMode: { label: "Any", value: urlInfo.clicks_limit_mode },
    password: urlInfo.password,
    warning: urlInfo.warning_type,
    allowBotClick: urlInfo.permissions__allow_bot_clicks,
    deviceTargets: deviceTargets.map(mapDeviceTargets),
    browserTargets: browserTargets.map(mapBrowserTargets),
    geoTargets: geoTargets.map(mapGeoTargets),
    trackingPixels: trackingPixels.map(mapTrackingPixels),
    metaTitle: urlInfo.meta_data__title,
    metaDescription: urlInfo.meta_data__description,
    metaImageURL: urlInfo.meta_data__imageURL,
    campaignSource: urlInfo.utm_data__source,
    campaignMedium: urlInfo.utm_data__medium,
    campaignName: urlInfo.utm_data__name,
    campaignTerm: urlInfo.utm_data__term,
    campaignContent: urlInfo.utm_data__content,
    yt_channel_id: urlInfo.youtube_addon__channel_id,
  };
};

export const createLinkFormRequestBody = (form, collectionId, linkType) => {
  return {
    collection_id: collectionId,
    domain: form.domain,
    // basic
    basic_data: {
      title: form.title,
      description: form.description,
      tags: form.tags,
      caseSensitive: form.caseSensitive,
      alias: form.alias,
      prefix: form.prefix,
      postfix: form.postfix,
      destination_url: form.destinationURL,
      creation_type: linkType,
      domain: form.domain,
    },
    // schedule it
    schedule_it_data: {
      dateTimeRangeField: form.dateTimeRange,
      expirationRedirectURL: form.expirationRedirect,
      autoDltOnExpiration: form.autoDltOnExpire,
    },
    // clicks limit
    clicks_limit_data: {
      clicksLimit: form.clicksLimit,
      clicksLimitMode: form.clicksLimitMode,
    },
    // password
    password_data: {
      password: form.password,
    },
    warnings_and_permissions_data: {
      // warnings
      warning: form.warning,
      // permissions
      allowBotClicks: form.allowBotClick,
    },
    // deviceTarget
    deviceTargets: form.deviceTargets,
    // browserTarget
    browserTargets: form.browserTargets,
    // Geo Taget
    geoTargets: form.geoTargets,
    // pixels
    pixels: form.trackingPixels,
    // custom meta data
    custom_meta_data: {
      metaTitle: form.metaTitle,
      metaDescription: form.metaDescription,
      metaImageURL: form.metaImageURL,
    },
    // UTM Builder
    utm_builder_data: {
      campaignSource: form.campaignSource,
      campaignMedium: form.campaignMedium,
      campaignName: form.campaignName,
      campaignTerm: form.campaignTerm,
      campaignContent: form.campaignContent,
    },
    add_on: {
      // YouTube Channel Id
      youtube: {
        channelId: form.yt_channel_id,
      },
    },
  };
};
