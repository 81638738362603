<script setup>
import { storeToRefs } from "pinia";
import { useLinkFormPanelMenuStore } from "~/store/useLinkFormPanelMenuStore";

const panelStore = useLinkFormPanelMenuStore();
const { activeItem, panelMenuItems, panelMenuExpandedKeys } = storeToRefs(panelStore);
const { setActiveItem } = panelStore;
onUnmounted(() => setActiveItem("basic"));
</script>

<template>
  <PanelMenu
    id="link-form-sidebar-panel-menu"
    v-model:expandedKeys="panelMenuExpandedKeys"
    :model="panelMenuItems"
    class="mt-0 overflow-y-auto w-full h-[32.7rem]"
  >
    <template #item="{ item }">
      <DialogLinkFormSidebarPanelMenuItem :item="item" :activeItem="activeItem" />
    </template>
  </PanelMenu>
</template>

<style lang="postcss">
#link-form-sidebar-panel-menu .p-panelmenu-header-content {
  border: none;
  box-shadow: none;
}
#link-form-sidebar-panel-menu .p-panelmenu-header-content:hover {
  @apply rounded-lg;
}
</style>
