<script setup>
const usefulOverlay = ref();

const onUsefulOverlayHover = (event) => {
  usefulOverlay.value.toggle(event);
};
</script>

<template>
  <span class="text-sm text-gray-600 mb-4 flex items-center"
    >Why Can I Only Add 15 URLs at a Time?
    <Button
      @click="onUsefulOverlayHover"
      class="p-button-icon text-gray-900 w-8 !text-xs"
      icon="pi pi-question-circle"
    />
  </span>
  <OverlayPanel
    ref="usefulOverlay"
    class="text-sm font-light mt-0 text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-lg w-96 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-600"
  >
    <p>
      We limit to 15 URLs at once for a good reason! Each URL is thoroughly scanned
      against our extensive database for safety and compliance. This ensures a secure and
      smooth experience while keeping the internet a safer place. Thanks for helping us
      maintain online safety!
    </p>
  </OverlayPanel>
</template>

<style></style>
