<script setup>
defineProps({
  DateTimeRangeField: {
    type: Object,
  },
});

const minDateValue = ref(new Date());
</script>
<template>
  <div class="field">
    <div>
      <label for="startdatetime-input-field" class="block mb-2 text-sm font-medium"
        >Select Date & Time Range</label
      >
      <div class="w-full">
        <Calendar
          inputId="range"
          v-model="DateTimeRangeField.$model"
          selectionMode="range"
          :manualInput="false"
          :showTime="true"
          hourFormat="12"
          :minDate="minDateValue"
          :showButtonBar="true"
          :showIcon="true"
          :hideOnRangeSelection="false"
          :showWeek="true"
          placeholder="Select Date&Time Range"
          class="select-none w-full"
          inputClass="focus:!border-[#d4d4d8] p-3 !bg-gray-50 !border sm:!text-md !rounded-l-lg dark:!bg-gray-700"
          id="start-end-datetime-range"
        />
      </div>
    </div>
  </div>
  <br />
</template>

<style lang="postcss">
#start-end-datetime-range > .p-datepicker-trigger {
  @apply !border-[#0046d5] !bg-[#0046d5];
}
.p-datepicker-buttonbar > button {
  @apply !bg-transparent !border-transparent !text-[#0046d5];
}
</style>
