import { storeToRefs } from "pinia";
import { linksApiService } from "~/services/linksApiService.js";
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore.js";
import { showLinkCreatedDialog } from "~/utils/dialogActions";
import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { form, resetForm } from "~/validations/homeLinkFormRules";

const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NOT_ACCEPTABLE: 406,
  IM_USED: 226,
};

export const useHomeLinkFormApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  // injects
  const closeDialog = inject("close-custom-link-options-dialog");

  // store
  const homeLinkFormStore = useHomeLinkFormStore();
  const { responseError, isSubmitted } = storeToRefs(homeLinkFormStore);
  const { clearResponseError } = homeLinkFormStore;

  // api calls
  const { createBasicLink } = linksApiService();

  const handleApiResponse = (response) => {
    switch (response?.status) {
      case STATUS_CODES.OK:
      case STATUS_CODES.CREATED:
        notifySuccess(response.data.data?.message);
        if (!response.data.is_auth) updateLocalStorage(response.data.data?.link);
        showLinkCreatedDialog({
          link: response.data?.data?.link,
          dashboardLink: response.data.data?.dashboard_link,
          showMoreOptions: true,
        });
        closeDialog();
        break;

      case STATUS_CODES.NOT_ACCEPTABLE:
        responseError.value = response.data;
        notifyError({
          title: "Malicious URL Detected!",
          text: response.data?.message,
        });
        closeDialog();
        break;

      case STATUS_CODES.IM_USED:
        responseError.value = response.data;
        break;
      default:
        error.value = response?.data;
        notifyError();
        closeDialog();
        break;
    }
  };

  const submitForm = async (isFormValid, isSkipped) => {
    isSubmitted.value = true;

    if (!isSkipped && !validateForm()) return;
    if (isFormValid) return;

    if (isSkipped) {
      // form.alias = "";
      form.tab = "smartlnks";
      form.dateTimeRange = [];
      form.password = "";
    }

    loading.value = true;
    clearResponseError();

    const requestBody = {
      title: form.title,
      destination_url: form.destinationURL,
      alias: form.alias,
      prefix: form.prefix,
      postfix: form.postfix,
      domain: form.domain,
      tab: form.tab,
      password_data: {
        password: form.password,
      },
      schedule_it_data: {
        dateTimeRangeField: form.dateTimeRange,
      },
    };

    console.info("==> Request Body: ", requestBody);
    await createLinkApi(requestBody);
  };

  const createLinkApi = async (requestBody) => {
    try {
      const response = await createBasicLink(requestBody);
      handleApiResponse(response);
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errorMessage = "";

    if (!form.destinationURL) {
      errorMessage = "Destination URL is required";
      isValid = false;
    } else if (!form.alias && form.tab === "smartlnk") {
      errorMessage = "Alias is required for SmartLink";
      isValid = false;
    } else if ((!form.prefix || form.prefix.length === 0) && form.tab === "smartdomain") {
      errorMessage = "Prefix is required for SmartDomain";
      isValid = false;
    }

    // Notify user if validation fails
    if (!isValid) {
      notifyError({ title: errorMessage, text: "" });
    }

    return isValid;
  };

  const updateLocalStorage = (smartlnk) => {
    try {
      const storedData = localStorage.getItem("unauth-smartlnks");
      const existingData = storedData ? JSON.parse(storedData) : [];

      const newData = {
        smartlnk: smartlnk,
        destination_url: form.destinationURL,
        title: form.title,
        created_on: new Date().toISOString(),
      };

      existingData.push(newData);

      localStorage.setItem("unauth-smartlnks", JSON.stringify(existingData));
    } catch (error) {
      console.error("Error updating local storage:", error);
    }
  };
  return {
    data,
    error,
    loading,
    submitForm,
  };
};
