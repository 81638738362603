<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted } = storeToRefs(useLinkFormStore());
</script>

<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- responseError -->
    <Message
      v-if="responseError?.details?.tab_key === 'tracking-pixels' && responseError?.message"
      severity="error"
      :sticky="true"
      :closable="false"
      >{{ responseError?.message }}</Message
    >

    <!-- Dynamic Tracking Pixels Field -->
    <CustomFieldTrackingPixels
      :isSubmitted="isSubmitted"
      :pixels="formV$.trackingPixels"
      :PixelsForm="form.trackingPixels"
      :responseError="responseError"
    />
  </div>
</template>

<style></style>
