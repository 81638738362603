export const capitalizeWords = (str) => {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const generateRandomString = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const minLength = 3;
  const maxLength = 7;
  const randomLength =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let randomString = "";

  for (let i = 0; i < randomLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

export const restrictedAliasChars = [
  "/",
  ".",
  ",",
  "?",
  "&",
  "=",
  "#",
  "%",
  ":",
  "@",
  "[",
  "]",
  ";",
  "(",
  ")",
  "\n",
  "\r",
];

export const restrictedPrefixChars = [
  "/",
  ".",
  ",",
  "?",
  "&",
  "=",
  "#",
  "%",
  ":",
  "@",
  "[",
  "]",
  ";",
  "(",
  ")",
  "\n",
  "\r",
];
