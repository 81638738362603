import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

export const pixelsApiService = () => {
  if (process.server) return;

  const { sendPostRequest } = useSendRequest();
  const { header } = storeToRefs(useAuthStore());

  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const ENDPOINTS = {
    CREATE_PIXEL: apis.CREATE_PIXEL_API,
  };

  const getConfig = (params = {}) => ({
    headers: header.value,
    responseType: "json",
    params: params,
  });

  const createPixel = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CREATE_PIXEL}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to create pixel"
      );
    }
  };

  return {
    createPixel,
  };
};
