import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

export const linksUtilityApiService = () => {
  if (process.server) return;

  const { sendGetRequest } = useSendRequest();

  const { header, isAuthenticated } = storeToRefs(useAuthStore());

  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const ENDPOINTS = {
    CHECK_SMARTLNK_AVAILABILITY: apis.CHECK_SMARTILNK_AVAILABILITY,
    CHECK_SMARTDOMAIN_AVAILABILITY: apis.CHECK_SMARTDOMAIN_AVAILABILITY,
    PREVIEW_SMARTLNK: apis.PREVIEW_SMARTLNK,
    SCAN_SMARTLNK: apis.SCAN_SMARTLNK,
    PUBLIC_LINK_INSIGHTS: apis.PUBLIC_LINK_INSIGHTS,
  };

  const getConfig = (params = {}) => ({
    headers: isAuthenticated.value
      ? header.value
      : { "Content-Type": "application/json" },
    responseType: "json",
    params: params,
  });

  const checkSmartlnkAvailability = async (params) => {
    const url = `${BASE_URL + ENDPOINTS.CHECK_SMARTLNK_AVAILABILITY}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to check smartlnk availability"
      );
    }
  };

  const checkSmartDomainAvailability = async (params) => {
    const url = `${BASE_URL + ENDPOINTS.CHECK_SMARTDOMAIN_AVAILABILITY}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "Failed to check smartdomain availability"
      );
    }
  };

  const previewLink = async (params) => {
    const url = `${BASE_URL + ENDPOINTS.PREVIEW_SMARTLNK}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to preview link"
      );
    }
  };

  const scanLink = async (params) => {
    const url = `${BASE_URL + ENDPOINTS.SCAN_SMARTLNK}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Failed to scan link");
    }
  };

  const fetchPublicLinkInsights = async (params) => {
    const url = `${BASE_URL + ENDPOINTS.PUBLIC_LINK_INSIGHTS}`;
    try {
      const response = await sendGetRequest(url, getConfig(params));
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to fetch public link insights"
      );
    }
  };

  return {
    checkSmartlnkAvailability,
    checkSmartDomainAvailability,
    fetchPublicLinkInsights,
    scanLink,
    previewLink,
  };
};
