<script setup>
import { formV$ } from "~/validations/homeLinkFormRules";
import { useHomeLinkFormStore } from "~/store/useHomeLinkFormStore";
import { storeToRefs } from "pinia";
import { useHomeLinkFormApi } from "~/composables/useHomeLinkFormApi";

const { isCoolDownOn } = storeToRefs(useHomeLinkFormStore());

const { submitForm, loading } = useHomeLinkFormApi();

onMounted(() => (isCoolDownOn.value = true));
</script>
<template>
  <div class="mt-10 flex gap-5 justify-between items-center">
    <!-- SKIP BUTTON -->
    <Button
      @mouseover="isCoolDownOn = false"
      class="w-full"
      id="custom-link-options-skip-btn"
      :disabled="loading"
      @click="submitForm(formV$.$invalid, true)"
    >
      Skip
      <vue-countdown
        v-if="isCoolDownOn"
        @end="submitForm(formV$.$invalid, true)"
        :time="5000"
        v-slot="{ totalSeconds }"
        >({{ totalSeconds }})</vue-countdown
      >
    </Button>
    <!-- GENERATE BUTTON -->
    <Button
      :label="loading ? 'Generating...' : 'Generate'"
      class="w-full"
      @mouseover="isCoolDownOn = false"
      @click="submitForm(formV$.$invalid, false)"
      :loading="loading"
      id="custom-link-options-generate-btn"
    />
  </div>
</template>

<style lang="postcss">
#custom-link-options-skip-btn {
  @apply !bg-gray-200 text-gray-800 !border-gray-200 justify-center font-medium;
}

#custom-link-options-generate-btn {
  @apply !bg-black !border-black;
}
</style>
