<script setup></script>

<template>
  <Dialog
    header="Terms and Conditions"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    :maximizable="true"
    id="terms-of-service-dialog"
  >
    <ContentTermsOfService />
  </Dialog>
</template>

<style lang="postcss">
#terms-of-service-dialog {
  @apply w-[80%] md:!w-[50%];
}
</style>
