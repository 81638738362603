import { defineNuxtPlugin } from "#app";
import amplitude from "amplitude-js";

export default defineNuxtPlugin((nuxtApp) => {
  const amplitudeApiKey = nuxtApp.$config.public.amplitudeApiKey;
  const instance = amplitude.getInstance();
  instance.init(amplitudeApiKey);

  nuxtApp.provide("amplitude", instance);
});
