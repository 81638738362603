import { storeToRefs } from "pinia";
import { useBulkLinkCreationFormStore } from "~/store/useBulkLinkCreationFormStore.js";
import { linksApiService } from "~/services/linksApiService.js";
import { notifyError, notifySuccess } from "~/utils/notifications.js";

const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NOT_ACCEPTABLE: 406,
  IM_USED: 226,
};

export const useBulkLinkCreationApi = () => {
  const error = ref(null);
  const loading = ref(false);

  const collectionId = inject("collection-id");
  const closeDialog = inject("close-bulk-link-creation-form-dialog");

  const store = useBulkLinkCreationFormStore();
  const { selectedUrls, responseError } = storeToRefs(store);

  const { createBulkLinks } = linksApiService();

  const handleSuccessResponse = (response) => {
    const blockedUrls = response.data?.data?.blocked_urls;
    notifySuccess(response.data?.data?.message);
    if (blockedUrls.length > 0) {
      notifyError({
        title: "",
        text: `Oops! Some links couldn’t join the SmartLnks party: ${blockedUrls.join(
          " "
        )}. They're either blocked or not playing nice for safety reasons. Check them out and try again, or reach out if you need a hand!`,
        timer: 15000,
      });
    }
    closeDialog({ refresh: true });
  };

  const handleApiResponse = (response) => {
    switch (response?.status) {
      case STATUS_CODES.CREATED:
        handleSuccessResponse(response);
        break;
      case STATUS_CODES.NOT_ACCEPTABLE:
        responseError.value = response.data;
        console.log(responseError.value);
        break;
      default:
        const message =
          response.data?.message || "An unknown error occurred.";
        notifyError({ title: message });
        closeDialog();
        break;
    }
  };

  const createBulkLink = async () => {
    loading.value = true;
    responseError.value = null;

    try {
      const data = {
        urls: selectedUrls.value.map((url) => url.name),
        collection_id: collectionId,
      };
      const response = await createBulkLinks(data);
      handleApiResponse(response);
    } catch (err) {
      console.log("==> err: ", err);
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  return {
    error,
    loading,
    createBulkLink,
  };
};
