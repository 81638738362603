import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";

export const accountApiService = () => {
  if (process.server) return;

  const { sendGetRequest, sendPostRequest, sendPatchRequest } =
    useSendRequest();
  const { header, mediaHeader } = storeToRefs(useAuthStore());

  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const ROOT_URL = apis.ROOT_URL;
  const ENDPOINTS = {
    EMAIL_LOGIN: apis.LOGIN_ACC_API,
    GOOGLE_LOGIN: apis.GOOGLE_LOGIN_API,
    CREATE_ACCOUNT: apis.CREATE_ACC_API,
    SIGN_OUT: apis.SIGNOUT_ACC_API,
    RESEND_VERITIFATION_EMAIL: apis.RESEND_VERIFICATION_EMAIL_API,
    RESET_PASSWORD: apis.PASSWORD_RESET_API,
    USER_PROFILE: apis.USER_PROFILE_API,
  };

  const getConfig = (params = {}) => ({
    headers: header.value,
    responseType: "json",
    params: params,
  });

  const resendVerificationEmail = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.RESEND_VERITIFATION_EMAIL}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to resend verification email"
      );
    }
  };

  const resetPassword = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.RESET_PASSWORD}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to login with email"
      );
    }
  };

  const googleLogin = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.GOOGLE_LOGIN}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to login with google"
      );
    }
  };

  const emailLogin = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.EMAIL_LOGIN}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to login with email"
      );
    }
  };

  const createAccont = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.CREATE_ACCOUNT}`;
    try {
      const response = await sendPostRequest(url, getConfig(), data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to create account"
      );
    }
  };

  const fetchUserInfo = async () => {
    const url = `${BASE_URL + ENDPOINTS.USER_PROFILE}`;
    try {
      const response = await sendGetRequest(url, getConfig());
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to get user info"
      );
    }
  };

  const updateUserProfile = async (data) => {
    const url = `${BASE_URL + ENDPOINTS.USER_PROFILE}`;
    const requestHeaders = {
      headers: mediaHeader.value,
      responseType: "json",
    };
    try {
      const response = await sendPatchRequest(url, requestHeaders, data);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to update user profile"
      );
    }
  };

  const signOut = async () => {
    const url = `${BASE_URL + ENDPOINTS.SIGN_OUT}`;
    try {
      const response = await sendGetRequest(url, getConfig());
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to signout user"
      );
    }
  };

  return {
    resendVerificationEmail,
    fetchUserInfo,
    updateUserProfile,
    googleLogin,
    emailLogin,
    createAccont,
    resetPassword,
    signOut,
  };
};
