import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { accountApiService } from "~/services/accountApiService";

export const useResetPasswordFormApi = (form) => {
  const data = ref(null);
  const error = ref(null);
  const isSubmitted = ref(false);
  const loading = ref(false);
  const responseError = ref(null);
  const isEmailSent = ref(false);

  const { resetPassword } = accountApiService();

  const resetPasswordApi = async (isFormValid) => {
    isSubmitted.value = true;
    if (isFormValid) return;
    loading.value = true;

    try {
      const response = await resetPassword({
        email: form.email,
      });

      if (response?.status === 200) handleSuccessResponse(response.data);
      else handleErrorResponse(response);
    } catch (err) {
      console.error(err);
      error.value = err?.message || "Reset Password error";
      notifyDefaultError();
    } finally {
      loading.value = false;
      isSubmitted.value = false;
    }
  };

  const handleErrorResponse = (errorResponse) => {
    if (errorResponse.status === 400 && "email" in errorResponse.data) {
      responseError.value = errorResponse.data["email"][0];
    } else {
      notifyDefaultError();
    }
  };

  const handleSuccessResponse = (data) => {
    isEmailSent.value = true;
    form.email = "";
  };

  const notifyDefaultError = () => {
    notifyError({
      title: "Failed to reset your password!",
      text: "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
    });
  };

  return {
    isSubmitted,
    loading,
    responseError,
    resetPasswordApi,
    isEmailSent,
  };
};
