<script setup>
import { form, formV$, resetForm } from "~/validations/pixelFormRules";
import { storeToRefs } from "pinia";
import { usePixelFormStore } from "~/store/usePixelFormStore";
import { usePixelFormApi } from "~/composables/pixel-form/usePixelFormApi";

const props = defineProps({
  linkId: {
    type: Number,
    required: false,
  },
});

const emit = defineEmits(["close"]);

// Provide values to child components
const closeDialog = (payload = {}) => emit("close", payload);

const pixelFormStore = usePixelFormStore();
const { responseError, isSubmitted } = storeToRefs(pixelFormStore);
const { reset } = pixelFormStore;

const { submitForm, loading } = usePixelFormApi({ closeDialog });

onUnmounted(async () => resetForm(), reset());
</script>

<template>
  <Dialog
    :header="linkId ? 'Edit Pixel' : 'Create Pixel'"
    :modal="true"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    class="select-none"
    id="pixel-form-dialog"
  >
    <form @submit.prevent="" class="p-fluid">
      <!-- responseError -->
      <Message
        v-if="responseError?.errorMessage"
        severity="error"
        :sticky="true"
        :closable="false"
        >{{ responseError?.errorMessage }}</Message
      >

      <!-- Title Field -->
      <CustomFieldText
        :Field="formV$.title"
        :label="'Title (Recommended)'"
        :inputId="'title-input-field'"
        :placeholder="'Enter title here'"
        :isSubmitted="isSubmitted"
      />

      <!-- Description Field -->
      <CustomFieldTextarea
        :Field="formV$.description"
        :isSubmitted="isSubmitted"
        :label="'Description (Optional)'"
        :inputId="'description-input-field'"
        :placeholder="'Enter a brief description of the smart pixel.'"
      />

      <!-- Tags Field -->
      <CustomFieldTags :tagsField="formV$.tags" :isSubmitted="isSubmitted" />

      <CustomFieldTeam
        v-if="false"
        :isSubmitted="isSubmitted"
        :teamEmails="selectedCollection?.team_emails"
        :action="collectionAction"
        :isPremium="selectedCollection?.is_premium"
      />
    </form>
    <template #footer>
      <Button
        class="p-button-sm"
        :label="linkId ? 'Update Pixel' : 'Create Pixel'"
        :icon="linkId ? 'pi pi-check' : 'pi pi-plus'"
        :loading="showLoader"
        id="pixel-form-submit-btn"
        @click="submitForm(formV$.$invalid, linkId)"
      />
      <CustomAgreementText
      class="mt-2"
        :text="`By clicking '${
          linkId ? 'Update' : 'Create'
        } Pixel', you are agreeing to Smartlnks`"
      />
    </template>
  </Dialog>
</template>

<style lang="postcss">
#pixel-form-dialog {
  @apply w-4/12 md:!w-6/12 lg:!w-4/12;
}
#pixel-form-submit-btn {
  @apply !bg-primary !border-primary dark:!bg-red-500;
}
</style>
