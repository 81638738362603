<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted } = storeToRefs(useLinkFormStore());
</script>
<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- Add Warning Field -->
    <CustomFieldWarningOptions
      :warningField="formV$.warning"
      :isSubmitted="isSubmitted"
    />

    <!-- Add Permission Field -->
    <CustomFieldPermissions
      :allowBotClicks="formV$.allowBotClick"
      :isSubmitted="isSubmitted"
    />
  </div>
</template>

<style></style>
