import { default as confirmneACjw5FXNMeta } from "/opt/buildhome/repo/pages/account/password_reset/confirm.vue?macro=true";
import { default as _19864rCLL0MiQ24Meta } from "/opt/buildhome/repo/pages/ads/19864.vue?macro=true";
import { default as _91id_93qpJwnJTAF8Meta } from "/opt/buildhome/repo/pages/blog/[id].vue?macro=true";
import { default as indexnCitm8tluDMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as _91id_93xsqeWfut0fMeta } from "/opt/buildhome/repo/pages/collection/[id].vue?macro=true";
import { default as indexhNArLQ28xLMeta } from "/opt/buildhome/repo/pages/dashboard/activities/index.vue?macro=true";
import { default as indexHMPTW2KXWYMeta } from "/opt/buildhome/repo/pages/dashboard/automation/index.vue?macro=true";
import { default as indexXEYsKLXcvSMeta } from "/opt/buildhome/repo/pages/dashboard/calendar/index.vue?macro=true";
import { default as overviewxjr44b8QXaMeta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/overview.vue?macro=true";
import { default as smartdomainsVO2W5XNZL8Meta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/smartdomains.vue?macro=true";
import { default as smartlnksZuOvhu1Q5iMeta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/smartlnks.vue?macro=true";
import { default as smartpixelsEYpMyNrmVLMeta } from "/opt/buildhome/repo/pages/dashboard/collection/[id]/smartpixels.vue?macro=true";
import { default as indexfsTvpCeUHwMeta } from "/opt/buildhome/repo/pages/dashboard/collection/index.vue?macro=true";
import { default as indexeNhtgTKAMMMeta } from "/opt/buildhome/repo/pages/dashboard/globe/index.vue?macro=true";
import { default as clicksDTg4QmaucDMeta } from "/opt/buildhome/repo/pages/dashboard/overview/clicks.vue?macro=true";
import { default as locationsmdlQfP8jkTMeta } from "/opt/buildhome/repo/pages/dashboard/overview/locations.vue?macro=true";
import { default as traffic_45sourcesc59QKVG2haMeta } from "/opt/buildhome/repo/pages/dashboard/overview/traffic-sources.vue?macro=true";
import { default as apixO9bWl0hMJMeta } from "/opt/buildhome/repo/pages/dashboard/settings/api.vue?macro=true";
import { default as connected_45appsq6oCmWd3iOMeta } from "/opt/buildhome/repo/pages/dashboard/settings/connected-apps.vue?macro=true";
import { default as domainYj9Wwc4SDlMeta } from "/opt/buildhome/repo/pages/dashboard/settings/domain.vue?macro=true";
import { default as my_45detailszf7bZ6AeRTMeta } from "/opt/buildhome/repo/pages/dashboard/settings/my-details.vue?macro=true";
import { default as notification6PDSxbMNKuMeta } from "/opt/buildhome/repo/pages/dashboard/settings/notification.vue?macro=true";
import { default as passwordVEBy2wlWKYMeta } from "/opt/buildhome/repo/pages/dashboard/settings/password.vue?macro=true";
import { default as globekaOoPZAPagMeta } from "/opt/buildhome/repo/pages/globe.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as signinjIE1JUeO4jMeta } from "/opt/buildhome/repo/pages/signin.vue?macro=true";
import { default as signup71EVNEoLpMMeta } from "/opt/buildhome/repo/pages/signup.vue?macro=true";
import { default as smartytlink7oWKpfXEkxMeta } from "/opt/buildhome/repo/pages/smartytlink.vue?macro=true";
import { default as _91_46_46_46slug_93tTsflEOjmBMeta } from "/opt/buildhome/repo/pages/stats/[...slug].vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as testnxGb4eh78qMeta } from "/opt/buildhome/repo/pages/test.vue?macro=true";
import { default as component_45stubqUvqUM3fNKMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: confirmneACjw5FXNMeta?.name ?? "account-password_reset-confirm",
    path: confirmneACjw5FXNMeta?.path ?? "/account/password_reset/confirm",
    meta: confirmneACjw5FXNMeta || {},
    alias: confirmneACjw5FXNMeta?.alias || [],
    redirect: confirmneACjw5FXNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/account/password_reset/confirm.vue").then(m => m.default || m)
  },
  {
    name: _19864rCLL0MiQ24Meta?.name ?? "ads-19864",
    path: _19864rCLL0MiQ24Meta?.path ?? "/ads/19864",
    meta: _19864rCLL0MiQ24Meta || {},
    alias: _19864rCLL0MiQ24Meta?.alias || [],
    redirect: _19864rCLL0MiQ24Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/ads/19864.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qpJwnJTAF8Meta?.name ?? "blog-id",
    path: _91id_93qpJwnJTAF8Meta?.path ?? "/blog/:id()",
    meta: _91id_93qpJwnJTAF8Meta || {},
    alias: _91id_93qpJwnJTAF8Meta?.alias || [],
    redirect: _91id_93qpJwnJTAF8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnCitm8tluDMeta?.name ?? "blog",
    path: indexnCitm8tluDMeta?.path ?? "/blog",
    meta: indexnCitm8tluDMeta || {},
    alias: indexnCitm8tluDMeta?.alias || [],
    redirect: indexnCitm8tluDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xsqeWfut0fMeta?.name ?? "collection-id",
    path: _91id_93xsqeWfut0fMeta?.path ?? "/collection/:id()",
    meta: _91id_93xsqeWfut0fMeta || {},
    alias: _91id_93xsqeWfut0fMeta?.alias || [],
    redirect: _91id_93xsqeWfut0fMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/collection/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhNArLQ28xLMeta?.name ?? "dashboard-activities",
    path: indexhNArLQ28xLMeta?.path ?? "/dashboard/activities",
    meta: indexhNArLQ28xLMeta || {},
    alias: indexhNArLQ28xLMeta?.alias || [],
    redirect: indexhNArLQ28xLMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/activities/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMPTW2KXWYMeta?.name ?? "dashboard-automation",
    path: indexHMPTW2KXWYMeta?.path ?? "/dashboard/automation",
    meta: indexHMPTW2KXWYMeta || {},
    alias: indexHMPTW2KXWYMeta?.alias || [],
    redirect: indexHMPTW2KXWYMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/automation/index.vue").then(m => m.default || m)
  },
  {
    name: indexXEYsKLXcvSMeta?.name ?? "dashboard-calendar",
    path: indexXEYsKLXcvSMeta?.path ?? "/dashboard/calendar",
    meta: indexXEYsKLXcvSMeta || {},
    alias: indexXEYsKLXcvSMeta?.alias || [],
    redirect: indexXEYsKLXcvSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: overviewxjr44b8QXaMeta?.name ?? "dashboard-collection-id-overview",
    path: overviewxjr44b8QXaMeta?.path ?? "/dashboard/collection/:id()/overview",
    meta: overviewxjr44b8QXaMeta || {},
    alias: overviewxjr44b8QXaMeta?.alias || [],
    redirect: overviewxjr44b8QXaMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/overview.vue").then(m => m.default || m)
  },
  {
    name: smartdomainsVO2W5XNZL8Meta?.name ?? "dashboard-collection-id-smartdomains",
    path: smartdomainsVO2W5XNZL8Meta?.path ?? "/dashboard/collection/:id()/smartdomains",
    meta: smartdomainsVO2W5XNZL8Meta || {},
    alias: smartdomainsVO2W5XNZL8Meta?.alias || [],
    redirect: smartdomainsVO2W5XNZL8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/smartdomains.vue").then(m => m.default || m)
  },
  {
    name: smartlnksZuOvhu1Q5iMeta?.name ?? "dashboard-collection-id-smartlnks",
    path: smartlnksZuOvhu1Q5iMeta?.path ?? "/dashboard/collection/:id()/smartlnks",
    meta: smartlnksZuOvhu1Q5iMeta || {},
    alias: smartlnksZuOvhu1Q5iMeta?.alias || [],
    redirect: smartlnksZuOvhu1Q5iMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/smartlnks.vue").then(m => m.default || m)
  },
  {
    name: smartpixelsEYpMyNrmVLMeta?.name ?? "dashboard-collection-id-smartpixels",
    path: smartpixelsEYpMyNrmVLMeta?.path ?? "/dashboard/collection/:id()/smartpixels",
    meta: smartpixelsEYpMyNrmVLMeta || {},
    alias: smartpixelsEYpMyNrmVLMeta?.alias || [],
    redirect: smartpixelsEYpMyNrmVLMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/[id]/smartpixels.vue").then(m => m.default || m)
  },
  {
    name: indexfsTvpCeUHwMeta?.name ?? "dashboard-collection",
    path: indexfsTvpCeUHwMeta?.path ?? "/dashboard/collection",
    meta: indexfsTvpCeUHwMeta || {},
    alias: indexfsTvpCeUHwMeta?.alias || [],
    redirect: indexfsTvpCeUHwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/collection/index.vue").then(m => m.default || m)
  },
  {
    name: indexeNhtgTKAMMMeta?.name ?? "dashboard-globe",
    path: indexeNhtgTKAMMMeta?.path ?? "/dashboard/globe",
    meta: indexeNhtgTKAMMMeta || {},
    alias: indexeNhtgTKAMMMeta?.alias || [],
    redirect: indexeNhtgTKAMMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/globe/index.vue").then(m => m.default || m)
  },
  {
    name: clicksDTg4QmaucDMeta?.name ?? "dashboard-overview-clicks",
    path: clicksDTg4QmaucDMeta?.path ?? "/dashboard/overview/clicks",
    meta: clicksDTg4QmaucDMeta || {},
    alias: clicksDTg4QmaucDMeta?.alias || [],
    redirect: clicksDTg4QmaucDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/overview/clicks.vue").then(m => m.default || m)
  },
  {
    name: locationsmdlQfP8jkTMeta?.name ?? "dashboard-overview-locations",
    path: locationsmdlQfP8jkTMeta?.path ?? "/dashboard/overview/locations",
    meta: locationsmdlQfP8jkTMeta || {},
    alias: locationsmdlQfP8jkTMeta?.alias || [],
    redirect: locationsmdlQfP8jkTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/overview/locations.vue").then(m => m.default || m)
  },
  {
    name: traffic_45sourcesc59QKVG2haMeta?.name ?? "dashboard-overview-traffic-sources",
    path: traffic_45sourcesc59QKVG2haMeta?.path ?? "/dashboard/overview/traffic-sources",
    meta: traffic_45sourcesc59QKVG2haMeta || {},
    alias: traffic_45sourcesc59QKVG2haMeta?.alias || [],
    redirect: traffic_45sourcesc59QKVG2haMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/overview/traffic-sources.vue").then(m => m.default || m)
  },
  {
    name: apixO9bWl0hMJMeta?.name ?? "dashboard-settings-api",
    path: apixO9bWl0hMJMeta?.path ?? "/dashboard/settings/api",
    meta: apixO9bWl0hMJMeta || {},
    alias: apixO9bWl0hMJMeta?.alias || [],
    redirect: apixO9bWl0hMJMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/api.vue").then(m => m.default || m)
  },
  {
    name: connected_45appsq6oCmWd3iOMeta?.name ?? "dashboard-settings-connected-apps",
    path: connected_45appsq6oCmWd3iOMeta?.path ?? "/dashboard/settings/connected-apps",
    meta: connected_45appsq6oCmWd3iOMeta || {},
    alias: connected_45appsq6oCmWd3iOMeta?.alias || [],
    redirect: connected_45appsq6oCmWd3iOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/connected-apps.vue").then(m => m.default || m)
  },
  {
    name: domainYj9Wwc4SDlMeta?.name ?? "dashboard-settings-domain",
    path: domainYj9Wwc4SDlMeta?.path ?? "/dashboard/settings/domain",
    meta: domainYj9Wwc4SDlMeta || {},
    alias: domainYj9Wwc4SDlMeta?.alias || [],
    redirect: domainYj9Wwc4SDlMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/domain.vue").then(m => m.default || m)
  },
  {
    name: my_45detailszf7bZ6AeRTMeta?.name ?? "dashboard-settings-my-details",
    path: my_45detailszf7bZ6AeRTMeta?.path ?? "/dashboard/settings/my-details",
    meta: my_45detailszf7bZ6AeRTMeta || {},
    alias: my_45detailszf7bZ6AeRTMeta?.alias || [],
    redirect: my_45detailszf7bZ6AeRTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/my-details.vue").then(m => m.default || m)
  },
  {
    name: notification6PDSxbMNKuMeta?.name ?? "dashboard-settings-notification",
    path: notification6PDSxbMNKuMeta?.path ?? "/dashboard/settings/notification",
    meta: notification6PDSxbMNKuMeta || {},
    alias: notification6PDSxbMNKuMeta?.alias || [],
    redirect: notification6PDSxbMNKuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/notification.vue").then(m => m.default || m)
  },
  {
    name: passwordVEBy2wlWKYMeta?.name ?? "dashboard-settings-password",
    path: passwordVEBy2wlWKYMeta?.path ?? "/dashboard/settings/password",
    meta: passwordVEBy2wlWKYMeta || {},
    alias: passwordVEBy2wlWKYMeta?.alias || [],
    redirect: passwordVEBy2wlWKYMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/password.vue").then(m => m.default || m)
  },
  {
    name: globekaOoPZAPagMeta?.name ?? "globe",
    path: globekaOoPZAPagMeta?.path ?? "/globe",
    meta: globekaOoPZAPagMeta || {},
    alias: globekaOoPZAPagMeta?.alias || [],
    redirect: globekaOoPZAPagMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/globe.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy69l1Gm1VSzMeta?.name ?? "privacy",
    path: privacy69l1Gm1VSzMeta?.path ?? "/privacy",
    meta: privacy69l1Gm1VSzMeta || {},
    alias: privacy69l1Gm1VSzMeta?.alias || [],
    redirect: privacy69l1Gm1VSzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: signinjIE1JUeO4jMeta?.name ?? "signin",
    path: signinjIE1JUeO4jMeta?.path ?? "/signin",
    meta: signinjIE1JUeO4jMeta || {},
    alias: signinjIE1JUeO4jMeta?.alias || [],
    redirect: signinjIE1JUeO4jMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signup71EVNEoLpMMeta?.name ?? "signup",
    path: signup71EVNEoLpMMeta?.path ?? "/signup",
    meta: signup71EVNEoLpMMeta || {},
    alias: signup71EVNEoLpMMeta?.alias || [],
    redirect: signup71EVNEoLpMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: smartytlink7oWKpfXEkxMeta?.name ?? "smartytlink",
    path: smartytlink7oWKpfXEkxMeta?.path ?? "/smartytlink",
    meta: smartytlink7oWKpfXEkxMeta || {},
    alias: smartytlink7oWKpfXEkxMeta?.alias || [],
    redirect: smartytlink7oWKpfXEkxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/smartytlink.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tTsflEOjmBMeta?.name ?? "stats-slug",
    path: _91_46_46_46slug_93tTsflEOjmBMeta?.path ?? "/stats/:slug(.*)*",
    meta: _91_46_46_46slug_93tTsflEOjmBMeta || {},
    alias: _91_46_46_46slug_93tTsflEOjmBMeta?.alias || [],
    redirect: _91_46_46_46slug_93tTsflEOjmBMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/stats/[...slug].vue").then(m => m.default || m)
  },
  {
    name: termsjw1825O2ZdMeta?.name ?? "terms",
    path: termsjw1825O2ZdMeta?.path ?? "/terms",
    meta: termsjw1825O2ZdMeta || {},
    alias: termsjw1825O2ZdMeta?.alias || [],
    redirect: termsjw1825O2ZdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: testnxGb4eh78qMeta?.name ?? "test",
    path: testnxGb4eh78qMeta?.path ?? "/test",
    meta: testnxGb4eh78qMeta || {},
    alias: testnxGb4eh78qMeta?.alias || [],
    redirect: testnxGb4eh78qMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/test.vue").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/dashboard",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/dashboard/",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/sitemap.xml",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]