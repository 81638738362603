<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted } = storeToRefs(useLinkFormStore());
</script>
<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- Campaign Source Field -->
    <CustomFieldText
      :Field="formV$.campaignSource"
      :label="'Campaign Source'"
      :inputId="'campaign-source-input-field'"
      :placeholder="'referrer: google, facebook, newsletter etc.'"
      :isSubmitted="isSubmitted"
    />

    <!-- Campaign Medium Field -->
    <CustomFieldText
      :Field="formV$.campaignMedium"
      :label="'Campaign Medium'"
      :inputId="'campaign-medium-input-field'"
      :placeholder="'marking medium: cpc, banner, email, social, etc.'"
      :isSubmitted="isSubmitted"
    />

    <!-- Campaign Name Field -->
    <CustomFieldText
      :Field="formV$.campaignName"
      :label="'Campaign Name'"
      :inputId="'campaign-name-input-field'"
      :placeholder="'eg. product, promo code, slogan, etc.'"
      :isSubmitted="isSubmitted"
    />

    <!-- Campaign Term Field -->
    <CustomFieldText
      :Field="formV$.campaignTerm"
      :label="'Campaign Term'"
      :inputId="'campaign-term-input-field'"
      :placeholder="'Keywords for your paid search campaigns'"
      :isSubmitted="isSubmitted"
    />

    <!-- Campaign Content Field -->
    <CustomFieldText
      :Field="formV$.campaignContent"
      :label="'Campaign Content'"
      :inputId="'campaign-content-input-field'"
      :placeholder="'(optional) any call-to-action or headline, e.g. buy-now.'"
      :isSubmitted="isSubmitted"
    />
  </div>
</template>

<style></style>
