import {
  maxLength,
  required,
  url,
  minLength,
  minValue,
  numeric,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// Common Validators
const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));
const validUrl = helpers.withMessage("The entered value is not a valid URL address", url);
const requiredDestinationUrl = {
  required: requiredMsg("Destination URL is required"),
  url: validUrl,
  maxLength: maxLengthMsg("Destination URL must be 200 characters or less", 200),
};

const defaultFormValues = () => ({
  domain: "smartlnks.com",
  title: "",
  destinationURL: "",
  alias: "",
  prefix: "",
  postfix: "",
  tab: "smartlnk",
  dateTimeRange: [],
  password: "",
});

export const form = reactive(defaultFormValues());

export const formRules = {
  domain: {
    required: requiredMsg("Domain is required"),
  },
  title: {
    minLength: minLengthMsg("Title must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Title must be 30 characters or less", 30),
  },
  destinationURL: {
    required: requiredMsg("Destination URL is required"),
    maxLength: maxLengthMsg(
      "Destination URL must be 400 characters or less",
      400
    ),
    url: validUrl,
  },
  alias: {
    // required: requiredMsg("Alias is required"),
    minLength: minLengthMsg("Alias must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Alias must be 74 characters or less", 74),
  },
  prefix: {
    // required: requiredMsg("Prefix is required"),
    minLength: minLengthMsg("Prefix must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Prefix must be 30 characters or less", 30),
    // required: helpers.withMessage("Prefix is required", (value) => {
    //   return value.trim().length == 0 ? false : true; // !props.isItLink &&
    // }),
  },
  postfix: {
    // required: requiredMsg("Postfix is required"),
    minLength: minLengthMsg("Postfix must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("Postfix must be 299 characters or less", 299),
  },

  // schedule it
  dateTimeRange: [],

  // password
  password: {
    minLength: minLengthMsg("Password must be at least 3 characters long", 3),
  },
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
