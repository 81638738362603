import { useLinkFormApi } from "~/composables/link-form/useLinkFormApi";

export const useLinkForm = (props) => {
  const { loading, error, initializeForm } = useLinkFormApi();

  // Initialize form data if linkId is provided
  watch(
    () => props.linkId,
    (newLinkId) => {
      if (newLinkId) {
        initializeForm(newLinkId);
      }
    },
    { immediate: true }
  );

  return {
    loading,
    error,
  };
};
