<script setup>
defineProps(["error"]);
setTimeout(() => {
  navigateTo("/");
}, 10000);
</script>
<template>
  <div class="p-3 h-screen bg-[#f1f4fa]">
    <div
      class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse md:flex-row error-page-div md:gap-28 gap-16 rounded-3xl h-full p-4 bg-white"
    >
      <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div class="relative">
          <div class="absolute">
            <div class="text-left">
              <h1 class="my-2 text-gray-800 font-bold lg:text-2xl md:text-xl sm:text-sm">
                Looks like you've stumbled upon a page that doesn't exist. But don't
                worry, you're not alone - nobody knows what they're doing either.
              </h1>
              <p class="my-2 text-gray-800">
                Sorry about that! Please visit our hompage to get where you need to go.
              </p>
              <br />
              <NuxtLink
                to="/"
                class="sm:w-full mt-2 lg:w-auto my-2 border rounded md py-4 px-8 text-center text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 select-none !bg-primary"
              >
                Take me there!
              </NuxtLink>
            </div>
          </div>
          <div>
            <NuxtImg provider="awsS3" class="lg:h-auto sm:h-60" src="/404-2.png" />
          </div>
        </div>
      </div>
      <div>
        <NuxtImg provider="awsS3" src="/404-1.png" />
      </div>
      <!-- Footer -->
      <div class="bottom-9 absolute lg:left-[45%] md:left-[45%] sm:left-[50%]">
        <div class="footer text-center items-center justify-center">
          <p>
            <NuxtLink to="/" class="flex">
              <NuxtImg
                provider="awsS3"
                src="/logo.png"
                alt="smartlnks logo"
                class="mr-2"
                style="height: 20px"
              />
              Smartlnks.com
            </NuxtLink>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
