import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/opt/buildhome/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/opt/buildhome/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_Fdvg3o8568 from "/opt/buildhome/repo/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import motion_fmZWau4t0U from "/opt/buildhome/repo/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import titles_m9SjywKgWm from "/opt/buildhome/repo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/opt/buildhome/repo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/opt/buildhome/repo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/opt/buildhome/repo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_HQNyLQlVwt from "/opt/buildhome/repo/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/opt/buildhome/repo/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import script_YrMPz5ICtR from "/opt/buildhome/repo/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_client_LcKgStRyi6 from "/opt/buildhome/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_6oy0ulSF66 from "/opt/buildhome/repo/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import plugin_eskviOYyLt from "/opt/buildhome/repo/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_hwlJ5PmHMh from "/opt/buildhome/repo/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/opt/buildhome/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/buildhome/repo/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import crisp_MbzSJlSpRf from "/opt/buildhome/repo/plugins/crisp.js";
import disableLogs_OpAymi3H1Y from "/opt/buildhome/repo/plugins/disableLogs.js";
import vue_tailwind_datepicker_Witns8p0r2 from "/opt/buildhome/repo/plugins/vue-tailwind-datepicker.js";
import addToHomeScreen_client_J4S7aD3UPi from "/opt/buildhome/repo/plugins/addToHomeScreen.client.js";
import amplitude_tracking_0UBIpH0S3i from "/opt/buildhome/repo/plugins/amplitude-tracking.js";
import amplitude_EZuIopuxgB from "/opt/buildhome/repo/plugins/amplitude.js";
import autoAnimate_PxVmN2q858 from "/opt/buildhome/repo/plugins/autoAnimate.js";
import notiwind_yOq5nKgwUp from "/opt/buildhome/repo/plugins/notiwind.js";
import vue_masonry_wall_6SfpdZr8v7 from "/opt/buildhome/repo/plugins/vue-masonry-wall.js";
import vuecountdown_client_qnjASVIfPH from "/opt/buildhome/repo/plugins/vuecountdown.client.js";
import wordCloud_client_p1YeCuBlOz from "/opt/buildhome/repo/plugins/wordCloud.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  plugin_client_Fdvg3o8568,
  motion_fmZWau4t0U,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  plugin_HQNyLQlVwt,
  nuxt_plugin_03YjkxYbK5,
  script_YrMPz5ICtR,
  plugin_client_LcKgStRyi6,
  plugin_6oy0ulSF66,
  plugin_eskviOYyLt,
  plugin_hwlJ5PmHMh,
  pwa_client_Eorgdr12jA,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  chunk_reload_client_UciE0i6zes,
  crisp_MbzSJlSpRf,
  disableLogs_OpAymi3H1Y,
  vue_tailwind_datepicker_Witns8p0r2,
  addToHomeScreen_client_J4S7aD3UPi,
  amplitude_tracking_0UBIpH0S3i,
  amplitude_EZuIopuxgB,
  autoAnimate_PxVmN2q858,
  notiwind_yOq5nKgwUp,
  vue_masonry_wall_6SfpdZr8v7,
  vuecountdown_client_qnjASVIfPH,
  wordCloud_client_p1YeCuBlOz
]