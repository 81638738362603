<script setup>
import { storeToRefs } from "pinia";
import { useBulkLinkCreationFormStore } from "~/store/useBulkLinkCreationFormStore.js";

const props = defineProps({
  collectionId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const closeDialog = (payload = {}) => emit("close", payload);

const store = useBulkLinkCreationFormStore();
const { activeTab } = storeToRefs(store);
const { clearStore } = store;

provide("close-bulk-link-creation-form-dialog", closeDialog);
provide("collection-id", props.collectionId);

onUnmounted(() => {
  clearStore();
});
</script>

<template>
  <div>
    <Dialog
      header="Batch Link Creation Bonanza"
      :visible="true"
      @update:visible="$emit('close')"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :dismissableMask="true"
      id="import-links-dialog"
    >
      <h2 class="text-sm text-gray-600 mb-4">
        Got a bunch of links? Bring 'em over! Paste your links below and let the SmartLnks
        magic begin and watch them transform into SmartLnks.
      </h2>

      <DialogBulkLinkCreationFormLimitationMessage />

      <DialogBulkLinkCreationFormTabs :activeTab="activeTab" />

      <DialogBulkLinkCreationFormTextTab v-if="activeTab === 0" />

      <DialogBulkLinkCreationFormUrlsTable />

      <template #footer>
        <DialogBulkLinkCreationFormFooter />
      </template>
    </Dialog>
  </div>
</template>

<style lang="postcss">
#import-links-dialog {
  @apply w-[80%] md:!w-[50%];
}
#import-links-dialog > .p-dialog-header {
  @apply pb-0;
}
</style>
