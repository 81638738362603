<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormApi } from "~/composables/link-form/useLinkFormApi";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const linkFormStore = useLinkFormStore();
const { responseError, isSubmitted } = storeToRefs(linkFormStore);
const { clearResponseError } = linkFormStore;

const linkType = inject("link-form-link-type");

const destinationUrlError = computed(() => {
  return responseError.value?.details?.form_key === "destination_url"
    ? responseError.value?.message
    : null;
});

const aliasError = computed(() => {
  return responseError.value?.details.tab_key === "basic" &&
    responseError.value?.details.form_key === "alias"
    ? responseError.value?.message
    : undefined;
});

const smartDomainError = computed(() => {
  return responseError.value?.details.tab_key === "basic" &&
    responseError.value?.details.form_key === "prefix"
    ? responseError.value?.message
    : undefined;
});
</script>

<template>
  <div class="select-none">
    <div class="my-4"></div>
    <!-- responseError -->
    <Message
      v-if="responseError?.details?.tab_key === 'basic' && responseError?.message"
      severity="error"
      :sticky="true"
      :closable="false"
      >{{ responseError?.message }}</Message
    >

    <!-- Title Field -->
    <CustomFieldText
      :Field="formV$.title"
      :label="'Title (Recommended)'"
      :inputId="'title-input-field'"
      :placeholder="'Enter title here'"
      :isSubmitted="isSubmitted"
    />

    <!-- Destination URL Field -->
    <CustomFieldText
      v-if="!form.yt_channel_id?.length"
      :Field="formV$.destinationURL"
      :isSubmitted="isSubmitted"
      :responseError="destinationUrlError"
      :label="'Destination URL'"
      :inputId="'desination-url-input-field'"
      :placeholder="'Type or paste a link'"
      :helpText="'Please include \'https://\' or \'http://\' in the link.'"
      :showResponseError="false"
      @clearResponseError="clearResponseError"
    />

    <!-- Alias Field -->
    <CustomFieldAlias
      v-if="linkType === 'smartlnks'"
      :form="form"
      :formV$="formV$"
      :isSubmitted="isSubmitted"
      :responseError="aliasError"
      :clearResponseError="clearResponseError"
    />

    <!-- SmartDomain Field -->
    <CustomFieldSmartdomain
      v-else-if="linkType === 'smartdomains'"
      :form="form"
      :formV$="formV$"
      :isSubmitted="isSubmitted"
      :responseError="smartDomainError"
      :clearResponseError="clearResponseError"
    />

    <!-- Description Field -->
    <CustomFieldTextarea
      :Field="formV$.description"
      :isSubmitted="isSubmitted"
      :label="'Description (Optional)'"
      :inputId="'description-input-field'"
      placeholder="Enter a brief description"
    />

    <!-- Tags Field -->
    <CustomFieldTags :tagsField="formV$.tags" :isSubmitted="isSubmitted" />
  </div>
</template>

<style></style>
