import { storeToRefs } from "pinia";
import { pixelsApiService } from "~/services/pixelsApiService.js";
import { usePixelFormStore } from "~/store/usePixelFormStore.js";
import { showShareDialog } from "~/utils/dialogActions";
import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { form } from "~/validations/pixelFormRules";

const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  NOT_ACCEPTABLE: 406,
  IM_USED: 226,
};

export const usePixelFormApi = ({ closeDialog }) => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const route = useRoute();

  const { createPixel } = pixelsApiService();

  const pixelFormStore = usePixelFormStore();
  const { responseError, isSubmitted } = storeToRefs(pixelFormStore); // not using responseError anywhere
  const { clearResponseError } = pixelFormStore;

  const handleApiResponse = (response) => {
    switch (response?.status) {
      case STATUS_CODES.OK:
      case STATUS_CODES.CREATED:
        notifySuccess(response.data?.data.message);
        showShareDialog({
          link: response.data?.data.smartpixel,
        });
        closeDialog({ refresh: true });
        break;
      case STATUS_CODES.NOT_ACCEPTABLE:
      case STATUS_CODES.IM_USED:
        responseError.value = response.data;
        console.log(responseError.value);
        break;
      default:
        const message = response.data?.message || "An unknown error occurred.";
        error.value = response?.data;
        notifyError({ title: message });
        closeDialog();
        break;
    }
  };

  const submitForm = async (isFormValid, linkId) => {
    isSubmitted.value = true;
    console.log("==> Submit Form: ", isFormValid);

    if (isFormValid) {
      notifyError({
        title: "Invalid Form!",
        text: "It appears that there may be errors in the form. Please review your entries and ensure that all required information is accurate and properly filled before submitting.",
      });

      return;
    }

    loading.value = true;
    clearResponseError();

    const requestBody = {
      collection_id: route.params?.id,
      domain: form.domain,
      name: form.title,
      description: form.description,
      tags: form.tags,
    };

    if (linkId) {
    } else createPixelApi(requestBody);
  };

  const createPixelApi = async (requestBody) => {
    try {
      const response = await createPixel(requestBody);
      handleApiResponse(response);
    } catch (err) {
      console.error(err);
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  return {
    data,
    error,
    loading,
    submitForm,
  };
};
