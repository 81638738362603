import { defineStore } from "pinia";

export const useLinkFormPanelMenuStore = defineStore(
  "linkFormPanelMenu",
  () => {
    if (process.server) return;

    const panelMenuExpandedKeys = ref({});
    const activeItem = ref("basic");

    const setActiveItem = (key) => (activeItem.value = key);

    const panelMenuItems = ref(
      [
        {
          key: "basic",
          label: "Basic",
        },
        {
          key: "schedule-link",
          label: "Schedule It",
        },
        {
          key: "clicks-limit",
          label: "Set Clicks Limit ",
        },
        {
          key: "set-password",
          label: "Secure with Password",
        },
        {
          key: "add-warnings-permissions",
          label: "Add Warnings & Permissions",
        },
        {
          key: "device-targets",
          label: "Device Target",
        },
        {
          key: "browser-targets",
          label: "Browser Target",
        },
        {
          key: "geo-targets",
          label: "Geo Target",
        },
        {
          key: "tracking-pixels",
          label: "Tracking Pixels",
        },
        // {
        //   key: "target-lnks",
        //   label: "Set Target",
        //   items: [
        //     {
        //       key: "device-target",
        //       label: "Device Target",
        //     },
        //     {
        //       key: "geo-target",
        //       label: "Geo Target",
        //     },
        //   ],
        // },
        {
          key: "utm-builder",
          label: "UTM Builder",
        },
        {
          key: "custom-meta-data",
          label: "Custom Meta Data",
        },
      ].map((item) => ({
        ...item,
        command: () => setActiveItem(item.key),
      }))
    );

    return {
      activeItem,
      setActiveItem,
      panelMenuItems,
      panelMenuExpandedKeys,
    };
  }
);
