import { load } from "cheerio";
import { storeToRefs } from "pinia";
import { youtubeAutomationApiService } from "~/services/youtubeAutomationApiService";
import { useYoutubeAutomationFormStore } from "~/store/useYoutubeAutomationFormStore";
import { form } from "~/validations/youtubeAutomationFormRules";

const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  IM_USED: 226,
  BAD_REQUEST: 400,
};

export const useYoutubeAutomationFormApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const { createYouTubeAutomation } = youtubeAutomationApiService();

  const closeDialog = inject("close-youtube-automation-form-dialog");

  const store = useYoutubeAutomationFormStore();
  const { responseError, isSubmitted } = storeToRefs(store);
  const { clearResponseError } = store;

  const handleApiResponse = (response) => {
    if (!response) {
      notifyError();
      return;
    }

    const message = response.data.data?.message || "An unknown error occurred.";

    switch (response?.status) {
      case STATUS_CODES.OK:
        notifySuccess(message);
        closeDialog({ refresh: true });
        break;
      case STATUS_CODES.BAD_REQUEST:
        responseError.value =
          response.data?.message || "An unknown error occurred.";
        break;
      default:
        notifyError({ title: message });
        closeDialog();
        break;
    }
  };

  const submitForm = async (isFormValid) => {
    isSubmitted.value = true;

    if (isFormValid) {
      return;
    }

    loading.value = true;
    clearResponseError();

    const requestBody = {
      smartlnk: form.smartlnk,
    };

    try {
      const response = await createYouTubeAutomation(requestBody);
      handleApiResponse(response);
    } catch (err) {
      error.value = err;
      notifyError(err);
    } finally {
      loading.value = false;
    }
  };

  return {
    data,
    error,
    loading,
    submitForm,
  };
};
