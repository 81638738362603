<script setup>
import ConfettiExplosion from "vue-confetti-explosion";
import QRCodeVue3 from "qrcode-vue3";
import { copyTextToClipboard } from "~/utils/copyTextToClipboard.js";

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  dashboardLink: {
    type: String,
  },
  collectionId: {
    type: Number,
    default: null,
    required: false,
  },
  didYouKnow: {
    type: String,
    default: null,
    required: false,
  },
  showMoreOptions: {
    type: Boolean,
    default: false,
  },
  showConfetti: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["close"]);

const logo = ref("/logo.png");

provide("close-link-created-dialog", () => emit("close"));

onMounted(() => {
  copyTextToClipboard({ url: props.link });
});
</script>

<template>
  <Dialog
    header="Share It! 😎"
    :visible="true"
    @update:visible="$emit('close')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :draggable="false"
    id="link-created-dialog"
    class="remove-scroll"
  >
    <div v-if="showConfetti" class="flex justify-center remove-scroll">
      <ConfettiExplosion :particleCount="100" :force="0.5" />
    </div>
    <div class="grid grid-cols-1 gap-10 select-none">
      <!-- qrcode -->
      <div class="col-span-1 justify-center flex mt-4">
        <div class="h-[250px] w-[250px] border rounded-3xl">
          <QRCodeVue3
            :value="link"
            :qrOptions="{
              typeNumber: 0,
              mode: 'Byte',
              errorCorrectionLevel: 'H',
            }"
            :image="logo"
            :imageOptions="{
              hideBackgroundDots: true,
              imageSize: 0.3,
              margin: 3,
              crossOrigin: 'Anonymous',
            }"
            :dotsOptions="{
              type: 'dots',
              color: '#000000',
              gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                  { offset: 0, color: '#000000' },
                  { offset: 1, color: '#000000' },
                ],
              },
            }"
            :backgroundOptions="{ color: '#ffffff' }"
            :cornersSquareOptions="{
              type: 'dots',
              color: '#fc0100',
            }"
            :cornersDotOptions="{ type: null, color: '#000000' }"
            fileExt="png"
            :download="false"
            myclass="smartlnk-qr p-4"
            imgclass="smartlnk-img-qrcode"
            downloadButton="smartlnk-qr-download-button"
            :downloadOptions="{ name: 'vqr', extension: 'png' }"
          />
        </div>
      </div>

      <div class="col-span-1">
        <CustomInputCopy :link="link" />
      </div>
      <div class="col-span-1">
        <CustomShareLinkRow
          :link="link"
          :title="'Check out this Smartlink - Make Your Links Stand Out with Smartlnks.com'"
          :description="'Want to make your links stand out? Check out Smartlnks.com - Create short, memorable and trackable links that will help you drive more traffic and measure the success of your campaigns. Try it now!'"
          :quote="'Revolutionize the way you share links.'"
          :hashtags="'#smartlnks #safelinks #shortlinks #brandedlinks #growth'"
          :showOverflowBar="true"
        />
      </div>

      <div v-if="didYouKnow != null" class="col-span-1">
        <p class="text-base font-bold text-gray-500">
          Did You Know: <span class="text-sm font-medium">{{ didYouKnow }}</span>
        </p>
      </div>

      <div class="col-span-1">
        <DialogLinkCreatedMoreOptions
          :link="link"
          :dashboardLink="dashboardLink"
          :collection_id="collectionId"
          :showMoreOptions="showMoreOptions"
        />
      </div>
    </div>
  </Dialog>
</template>

<style lang="postcss">
#link-created-dialog {
  -ms-overflow-style: none !important;
  /* IE and Edge, */
  scrollbar-width: none !important;
  /* Firefox, */
  @apply w-10/12 md:!w-6/12 lg:!w-3/12;
}

#link-created-dialog::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  -ms-overflow-style: none !important;
  /* IE and Edge, */
  scrollbar-width: none !important;
  /* Firefox, */
}

#generated-smartlnk > div {
  @apply !font-semibold text-black;
}

#generated-link-copy-btn > .p-button-icon {
  @apply text-2xl;
}
</style>
