<script setup>
import { useYoutubeAutomationFormApi } from "~/composables/useYoutubeAutomationFormApi";
import { formV$ } from "~/validations/youtubeAutomationFormRules.js";

const { submitForm, loading } = useYoutubeAutomationFormApi();
</script>
<template #footer>
  <Button
    class="p-button-sm w-full !bg-primary !border-primary"
    label="Create Automation"
    :loading="loading"
    icon="pi pi-check"
    @click="submitForm(formV$.$invalid)"
  />
</template>

<style></style>
