import { notifyError } from "~/utils/notifications";
import { storeToRefs } from "pinia";
import { linksTableUtilityApiService } from "~/services/linksTableUtilityApiService";
import { useAuthStore } from "~/store/auth.js";

export const useLinksTableUtilityApi = ({ collectionId }) => {
  const tagsData = ref(null);
  const domainsData = ref(null);
  const prefixData = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const route = useRoute();

  const { header } = storeToRefs(useAuthStore());
  const { getConfig, fetchTagsData, fetchDomainsData, fetchPrefixData } =
    linksTableUtilityApiService();

  const currentPath = computed(() => {
    const pathSegments = route.path.split("/");
    return pathSegments[pathSegments.length - 1];
  });

  const dataMapping = {
    fetchTagsData: tagsData,
    fetchDomainsData: domainsData,
    fetchPrefixData: prefixData,
  };

  const fetchDataGeneric = async (fetchFunction, addtionalParams = {}) => {
    loading.value = true;

    const linkType = addtionalParams?.linkType || currentPath.value;

    const config = getConfig(header.value, collectionId, linkType);

    try {
      const response = await fetchFunction(config);
      handleResponse(response, dataMapping[fetchFunction.name]);
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const handleResponse = (response, dataRef) => {
    if (response?.status === 200) {
      dataRef.value = response.data?.data;
    } else {
      const message =
        response.data?.message || "An unknown error occurred.";
      notifyError({ title: message });
    }
  };

  const fetchTags = () => fetchDataGeneric(fetchTagsData);
  const fetchDomains = ({ linkType }) =>
    fetchDataGeneric(fetchDomainsData, {
      linkType,
    });
  const fetchPrefix = () => fetchDataGeneric(fetchPrefixData);

  return {
    tagsData,
    domainsData,
    prefixData,
    error,
    loading,
    fetchDomains,
    fetchTags,
    fetchPrefix,
  };
};
