<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~~/store/auth";
import { showCustomQRDialog } from "~/utils/dialogActions";

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  dashboardLink: {
    type: String,
  },
  collection_id: {
    type: Number,
    required: false,
  },
  showMoreOptions: {
    type: Boolean,
    default: false,
  },
});

const useAuth = useAuthStore();
const { isAuthenticated } = storeToRefs(useAuth);

const closeDialog = inject("close-link-created-dialog");
</script>

<template>
  <div>
    <div v-if="!isAuthenticated">
      <NuxtLink
        v-if="dashboardLink != null"
        :to="`https://smartlnks.com/stats/${link.replace('https://', '')}`"
        target="_blank"
        class="p-button p-component w-full !mb-3 justify-center"
        id="check-detailed-analytics-btn"
        >Check Detailed Analytics</NuxtLink
      >
      <Button
        @click="showCustomQRDialog({ link })"
        label="Customize QR code"
        class="w-full mb-4"
      />
      <hr />
      <p class="mt-4">
        Want to smarten your links even more? –
        <NuxtLink to="/signup" class="font-semibold text-primary">Sign up</NuxtLink>
        now and transform them into powerful marketing tools. With advanced features to
        optimize your links and track your audience, you'll wonder how you ever managed
        without us.
        <NuxtLink to="/signup" class="font-semibold text-primary">Join today</NuxtLink>
        and start maximizing your results!
      </p>
    </div>
    <div v-else-if="isAuthenticated && showMoreOptions">
      <hr />
      <p class="mt-4">
        Looking for more ways to optimize your links?
        <NuxtLink
          :to="dashboardLink"
          @click="closeDialog()"
          class="text-primary cursor-pointer font-semibold"
        >
          Go to our powerful dashboard</NuxtLink
        >
      </p>
      <Divider class="flex" align="center" type="dotted">
        <b>OR</b>
      </Divider>
      <p class="mt-4">
        Want to create a smart QR code?
        <span
          @click="showCustomQRDialog({ link })"
          class="text-primary cursor-pointer font-semibold"
        >
          Generate one now</span
        >
      </p>
    </div>
  </div>
</template>

<style lang="postcss">
#check-detailed-analytics-btn {
  @apply !font-medium !bg-black !border-black text-white;
}
</style>
