<script setup>
import { resetForm } from "~/validations/youtubeAutomationFormRules";
import { useYoutubeAutomationFormStore } from "~/store/useYoutubeAutomationFormStore.js";

const emit = defineEmits(["close"]);

// Use store
const { reset } = useYoutubeAutomationFormStore();

// Provide values to child components
const closeYoutubeAutomationFormDialog = (payload = {}) => emit("close", payload);

provide("close-youtube-automation-form-dialog", closeYoutubeAutomationFormDialog);

onUnmounted(async () => resetForm(), reset());
</script>

<template>
  <Dialog
    :visible="true"
    header="Automate to Twitter Auto-Posting"
    :draggable="false"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    @update:visible="$emit('close')"
    id="youtube-automation-form-dialog"
    class="select-none"
  >
    <div class="">
      <DialogYoutubeAutomationFormHeader />
      <DialogYoutubeAutomationFormMainContent />
    </div>
    <template #footer>
      <DialogYoutubeAutomationFormFooter />
    </template>
  </Dialog>
</template>

<style lang="postcss">
#youtube-automation-form-dialog {
  @apply w-4/12 md:!w-6/12 lg:!w-4/12;
}
</style>
