import {
  maxLength,
  required,
  minLength,
  helpers,
  url,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// Common Validators
const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));
const validUrl = helpers.withMessage(
  "The entered value is not a valid URL address",
  url
);

const defaultFormValues = () => ({
  smartlnk: "",
});

export const form = reactive(defaultFormValues());

export const formRules = {
  smartlnk: {
    required: requiredMsg("SmartLnk is required"),
    minLength: minLengthMsg("SmartLnk must be at least 3 characters long", 3),
    maxLength: maxLengthMsg("SmartLnk must be 74 characters or less", 74),
    url: validUrl,
  },
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
