<script setup>
import { useCollectionFormApi } from "~/composables/collection-form/useCollectionFormApi";
import { formV$ } from "~/validations/collectionFormRules.js";

const { selectedCollection } = defineProps({
  selectedCollection: {
    type: Object,
    required: false,
  },
});

const { submitForm, loading } = useCollectionFormApi();
</script>
<template #footer>
  <Button
    class="p-button-sm w-full !bg-primary !border-primary"
    :label="selectedCollection ? 'Save Changes' : 'Create'"
    :loading="loading"
    icon="pi pi-check"
    @click="submitForm(formV$.$invalid, selectedCollection?.id)"
  />
</template>

<style></style>
